import { IconButton, useBreakpointValue } from "@alch/ui";
import { AlchemyMarkOnLight, AlchemyOnLight } from "@alch/ui/assets/logos";

import { ViewAsPreservingLink } from "../common/ViewAsPreservingLink";

const HeaderLogo = () => {
  const logo = useBreakpointValue({
    base: (
      <IconButton as={ViewAsPreservingLink} to="/" padding="lg">
        <img
          src={AlchemyMarkOnLight}
          alt="Alchemy Logo"
          style={{ width: 32, height: 32 }}
        />
      </IconButton>
    ),
    sm: (
      <IconButton
        as={ViewAsPreservingLink}
        to="/"
        rounded="slight"
        className="max-sm:hidden"
      >
        <img
          src={AlchemyOnLight}
          alt="Alchemy Logo"
          style={{ width: 130, height: 28 }}
        />
      </IconButton>
    ),
  });

  return <>{logo}</>;
};

export default HeaderLogo;

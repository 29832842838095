import { ErrorView } from "@alch/ui";
import { UseQueryResult } from "@tanstack/react-query";
import { UseTRPCQueryResult } from "@trpc/react-query/shared";
import { isString } from "lodash";
import { z } from "zod";

const errorLikeSchema = z.object({
  message: z.string(),
});

/**
 * Displays the error view if any of the provided queries are in an error state.
 */
const ReactQueryErrorView = (props: {
  results:
    | (UseQueryResult | UseTRPCQueryResult<unknown, unknown>)[]
    | UseQueryResult
    | UseTRPCQueryResult<unknown, unknown>;
}) => {
  let { results } = props;
  results = Array.isArray(results) ? results : [results];

  const errorResults = results.filter((res) => res.isError);
  const errorMessages = errorResults
    .map((res) => res.error)
    .map((error): string | undefined => {
      const errorLike = errorLikeSchema.safeParse(error);
      return errorLike.success ? errorLike.data.message : undefined;
    })
    .filter(isString);
  const errorMessage = errorMessages.join("") || "Sorry, something went wrong.";
  const refetchAllErrors = () => {
    void Promise.all(errorResults.map(async (res) => res.refetch()));
  };

  return <ErrorView message={errorMessage} onRetry={refetchAllErrors} />;
};

export default ReactQueryErrorView;

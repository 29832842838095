import { Link } from "@alch/ui";
import styled from "@emotion/styled";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventPrefix, analyticsEvent } from "@util/analytics";
import { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import PricingCompareView from "../../../profile/PricingCompareView.tsx";

const StyledModal = styled(Modal)`
  height: 90%;
  width: 100%;
  max-width: 1110px;
  margin: 0 20px;
  padding: 20px;
  overflow: scroll;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  border: 1px solid #cfd9f0;
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0c0c0e;
  font-size: 20px;
  background-color: #fff;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: #f3f3f3;
  }

  &:active {
    background-color: #d6d6d6;
  }
`;

const SignupPlanCompare = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // Disables scrolling on the body when the modal is open
  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [isOpen]);

  const handleClick = useCallback(() => {
    analyticsEvent(`${EventPrefix.SignupFlowRedesign}: Clicked compare plans`);
    setIsOpen(true);
  }, []);
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const parentSelector = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- FIXME
    () => document.getElementById("root")!,
    [],
  );

  return (
    <div>
      <StyledModal
        isOpen={isOpen}
        onRequestClose={handleClose}
        style={{
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        preventScroll
        parentSelector={parentSelector}
      >
        {/*Disabling this until we upgrade webflow to enterprise because they */}
        {/*added a CSP which prevents us from showing the marketing website in our dashboard*/}
        {/*<StyledFrame src="https://alchemy.com/pricing-comparison" />*/}
        <PricingCompareView />
        <StyledCloseButton onClick={handleClose}>
          <FontAwesomeIcon icon={faXmark} />
        </StyledCloseButton>
      </StyledModal>

      <Link
        className="text-paragraph-200-medium signup-sm:text-paragraph-300-medium"
        onClick={handleClick}
      >
        Compare plans
      </Link>
    </div>
  );
};

export default SignupPlanCompare;

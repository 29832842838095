import { Button, Modal } from "@alch/ui";
import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { ApiError } from "../../../http/apiError";
import { submitFeedback } from "../../../http/endpoints";

const PROMPT = "What can we do to improve?";

type FeedbackModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

function FeedbackModal({ isOpen, onClose }: FeedbackModalProps) {
  const [feedback, setFeedback] = useState("");
  const [hasSubmittedFeedback, setHasSubmittedFeedback] = useState(false);
  const { mutate: submitFeedback } = useSubmitFeedback();

  const handleSubmit = () => {
    const trimmedInput = feedback.trim();
    if (!trimmedInput) {
      return;
    }

    submitFeedback({ feedback: trimmedInput });
    setFeedback("");
    setHasSubmittedFeedback(true);
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(e.target.value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const onCloseModal = () => {
    onClose();
    setTimeout(() => {
      setFeedback("");
      setHasSubmittedFeedback(false);
    }, 500);
  };

  return (
    <Modal
      title={hasSubmittedFeedback ? "Thank you!" : PROMPT}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      {hasSubmittedFeedback ? (
        <div className="text-heading-h3">
          Our customer and product team will review this and may follow up
          directly with you.
        </div>
      ) : (
        <>
          <textarea
            value={feedback}
            onChange={onChange}
            onKeyPress={onKeyPress}
            rows={4}
            className="mb-3 w-full resize-none rounded-lg border border-grayscale-200 px-3 py-2 outline-none transition-colors focus:border-blue-500"
            placeholder="Whether it's good, bad, or you're just saying hey - we'd love to hear your thoughts!"
          />
          <div className="flex justify-end">
            <Button intent="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}

function useSubmitFeedback(): UseMutationResult<
  void,
  ApiError,
  { feedback: string }
> {
  return useMutation({
    mutationFn: (args) => submitFeedback({ prompt: PROMPT, ...args }),
  });
}

export default FeedbackModal;

import { createSelector } from "reselect";
import { chainFrom } from "transducist";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { Loadable, makeMappedLoadableSelector } from "./loadable";
export interface OrderedIdMap<S extends string | number, T> {
  allIds: S[];
  byId: { [id in S]: T };
}
export function orderedMapFromArray<S extends string | number, T>(
  items: T[],
  getId: (item: T) => S,
): OrderedIdMap<S, T> {
  return {
    allIds: items.map(getId),
    byId: chainFrom(items).toObject(
      (item) => getId(item),
      (item) => item,
    ),
  };
}
export function arrayFromOrderedIdMap<S extends string | number, T>(
  map: OrderedIdMap<S, T>,
): T[] {
  return map.allIds.map((id) => map.byId[id]);
}
export function makeOrderedMapSelector<S extends string | number, T, P>(
  f: (props: P) => OrderedIdMap<S, T>,
): (props: P) => T[] {
  return createSelector(f, arrayFromOrderedIdMap);
}
export function makeLoadableOrderedMapSelector<S extends string | number, T, P>(
  f: (props: P) => Loadable<OrderedIdMap<S, T>>,
): (props: P) => Loadable<T[]> {
  return makeMappedLoadableSelector(f, arrayFromOrderedIdMap);
}
export function setInOrderedMap<S extends string | number, T>(
  map: OrderedIdMap<S, T>,
  id: S,
  value: T,
): OrderedIdMap<S, T> {
  const { allIds, byId } = map;
  const newAllIds = id in byId ? allIds : [...allIds, id];
  const newById = { ...byId, [id]: value };
  return { ...map, allIds: newAllIds, byId: newById };
}

export function deleteFromOrderedIdMap<S extends string | number, T>(
  map: OrderedIdMap<S, T>,
  id: S,
): OrderedIdMap<S, T> {
  const { allIds, byId } = map;
  if (!(id in byId)) {
    return map;
  }
  const newAllIds = allIds.filter((mId) => mId !== id);
  const newById = { ...byId };
  delete newById[id];
  return { allIds: newAllIds, byId: newById };
}

export function updateInOrderedIdMap<S extends string | number, T>(
  map: OrderedIdMap<S, T>,
  id: S,
  updateValue: (value: T) => T,
): OrderedIdMap<S, T> {
  const value = map.byId[id];
  return value ? setInOrderedMap(map, id, updateValue(value)) : map;
}

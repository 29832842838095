import { memo, ReactElement } from "react";
import "./PricingCompareView.css";

const PricingCompareView = memo(function PaymentMethodsView(): ReactElement {
  return (
    <div className="pricingnew_plan-item is-relative">
      <div className="pricingnew_plan-row is-heading test">
        <div className="text-align-left text-color-blue">
          Pricing (per month)
        </div>
        <div>Free</div>
        <div>Growth</div>
        <div>Scale Monthly</div>
        <div>Scale Yearly</div>
        <div>Enterprise</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">Base CUs</div>
        <div>300 Million</div>
        <div>400 Million</div>
        <div>1.5 Billion</div>
        <div>1.5 Billion</div>
        <div>Custom</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">Base Rate</div>
        <div>$0</div>
        <div>$49</div>
        <div>$289</div>
        <div>$199</div>
        <div>Custom</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">On-Demand Rate</div>
        <div className="pricingnew-dash-wrapper">
          <div className="pricingnew_dash-icon w-embed">
            <svg
              width="19"
              height="3"
              viewBox="0 0 19 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 1.5H17.5"
                stroke="#475569"
                strokeWidth="2"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
        </div>
        <div>$1.20/1M CU</div>
        <div>$1.00/1M CU</div>
        <div>$1.00/1M CU</div>
        <div>Custom</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">Pre-Paid Rate</div>
        <div className="pricingnew-dash-wrapper">
          <div className="pricingnew_dash-icon w-embed">
            <svg
              width="19"
              height="3"
              viewBox="0 0 19 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 1.5H17.5"
                stroke="#475569"
                strokeWidth="2"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
        </div>
        <div className="pricingnew-dash-wrapper">
          <div className="pricingnew_dash-icon w-embed">
            <svg
              width="19"
              height="3"
              viewBox="0 0 19 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 1.5H17.5"
                stroke="#475569"
                strokeWidth="2"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
        </div>
        <div className="pricingnew-dash-wrapper">
          <div className="pricingnew_dash-icon w-embed">
            <svg
              width="19"
              height="3"
              viewBox="0 0 19 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 1.5H17.5"
                stroke="#475569"
                strokeWidth="2"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
        </div>
        <div>$0.70/1M CU</div>
        <div>Custom</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">
          Throughput (CU/sec)
        </div>
        <div>330</div>
        <div>660</div>
        <div>3000</div>
        <div>3000+</div>
        <div>5000+</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">Apps</div>
        <div>5 apps</div>
        <div>15 apps</div>
        <div> 30 apps</div>
        <div>30 apps</div>
        <div>Unlimited</div>
      </div>
      <div className="pricingnew_plan-row is-item is-header test">
        <div className="text-align-left secondary-pricing-heading test">
          <a href="/account-kit">Account Abstraction Pricing</a>
        </div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">User Ops</div>
        <div>300K </div>
        <div>400K </div>
        <div>1.5M </div>
        <div>1.5M </div>
        <div>Custom</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">Gas Manager</div>
        <div>Free on testnets</div>
        <div>8% admin fee</div>
        <div>7% admin fee</div>
        <div>7% admin fee</div>
        <div>Custom admin fee</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">
          Gas Sponsorship Limit
        </div>
        <div>N/A</div>
        <div>$100/mo</div>
        <div>$500/mo</div>
        <div>$500/mo</div>
        <div>Custom</div>
      </div>
      <div className="pricingnew_plan-row is-item is-header test">
        <div className="text-align-left secondary-pricing-heading test">
          <a href="https://www.alchemy.com/subgraphs">Subgraphs Pricing</a>
        </div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">
          Subgraph Deployments
        </div>
        <div>2</div>
        <div>10</div>
        <div>30</div>
        <div>30</div>
        <div>Unlimited</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">Queries </div>
        <div>500K</div>
        <div>1M</div>
        <div>10M</div>
        <div>10M</div>
        <div>Custom</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left-copy pricing-heading-copy">
          <span className="text-span-14">
            <em>Extra Queries</em>
          </span>
        </div>
        <div>-</div>
        <div>
          <span className="text-span-15">$50/1M</span>
        </div>
        <div className="text-block-92">$25/1M</div>
        <div>
          <span className="text-span-16">$25/1M</span>
        </div>
        <div>
          <span className="text-span-17">Custom</span>
        </div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">Rate Limit</div>
        <div>5 queries/sec</div>
        <div>10 queries/sec</div>
        <div>30 queries/sec</div>
        <div>30 queries/sec</div>
        <div>Custom</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">Stored Entities</div>
        <div>250K</div>
        <div>500K</div>
        <div>1M</div>
        <div>1M</div>
        <div>Custom</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left-copy pricing-heading-copy">
          <span className="text-span-14">
            <em>Extra Stored Queries</em>
          </span>
        </div>
        <div>-</div>
        <div>
          <span className="text-span-15">$100/1M</span>
        </div>
        <div className="text-block-92">$50/1M</div>
        <div>
          <span className="text-span-16">$50/1M</span>
        </div>
        <div>
          <span className="text-span-18">Custom</span>
        </div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left pricing-heading">Updated Entities </div>
        <div>100K</div>
        <div>250K</div>
        <div>500K</div>
        <div>500K</div>
        <div>Custom</div>
      </div>
      <div className="pricingnew_plan-row is-item test">
        <div className="text-align-left-copy pricing-heading-copy">
          <span className="text-span-14">
            <em>Extra Updated Entities</em>
          </span>
        </div>
        <div>-</div>
        <div>
          <span className="text-span-15">$50/100K</span>
        </div>
        <div className="text-block-92">$40/100K</div>
        <div>
          <span className="text-span-16">$40/100K</span>
        </div>
        <div>
          <span className="text-span-18">Custom</span>
        </div>
      </div>
    </div>
  );
});

export default PricingCompareView;

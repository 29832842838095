import { Product, WaffleFlag } from "@alch/dx-entities";
import { useWaffleFlag } from "@queries/useWaffleFlags";
import { Path, pathBuilder } from "@util/paths.ts";
import { AlchemyDocsUrl, Url } from "@util/urls";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/root";

interface BaseNavItem {
  label: string;
  highlight?: boolean;
  onClick?: () => void;
}

export interface InternalNavLink extends BaseNavItem {
  to: string;
  exact?: boolean;
}

export interface ExternalNavLink extends BaseNavItem {
  href?: string;
}

export interface NestedNavItem extends BaseNavItem {
  items: NavLink[];
}

export type NavLink = InternalNavLink | ExternalNavLink;

export interface NavItemGroup {
  title: string;
  items: Array<NavLink | NestedNavItem>;
}

export type NavItem = NavLink | NestedNavItem | NavItemGroup;

export const isExternalLink = (item: NavItem): item is ExternalNavLink => {
  return (item as ExternalNavLink).href != null;
};

export const isInternalLink = (item: NavItem): item is InternalNavLink => {
  return (item as InternalNavLink).to != null;
};

export const isNestedItem = (item: NavItem): item is NestedNavItem => {
  return (item as NestedNavItem).items != null;
};

export const isGroupItem = (item: NavItem): item is NavItemGroup => {
  return (item as NavItemGroup).items != null;
};

const useNavItems = (): NavItem[] => {
  const user = useSelector((state: RootState) => state.users.currentUser);
  const isBillingAdmin = !!user.value?.is_billing_admin;

  const isSignerServiceEnabled = useWaffleFlag(WaffleFlag.SignerService);

  const allowDataPlatformFlag = useSelector(
    (state: RootState) => state.team.team?.value?.flags?.allow_data_platform,
  );

  return useMemo(() => {
    return [
      {
        label: "Overview",
        to: Path.Home,
        exact: true,
      },
      {
        label: "Apps",
        to: Path.Apps,
      },
      {
        label: "Services",
        to: Path.Services,
        exact: true,
      },
      {
        title: "Chain Services",
        items: [
          {
            label: "Chains",
            to: Path.Chains,
          },
          {
            label: "Rollups",
            to: Path.Rollups,
          },
        ],
      },
      {
        title: "APIs",
        items: [
          {
            label: "Node API",
            to: pathBuilder.services.landingPage(Product.NodeApi),
          },
          {
            label: "NFT API",
            to: pathBuilder.services.landingPage(Product.NftApi),
          },
          {
            label: "Token API",
            to: pathBuilder.services.landingPage(Product.TokenApi),
          },
          {
            label: "Transfers API",
            to: pathBuilder.services.landingPage(Product.TransfersApi),
          },
          {
            label: "Prices API",
            to: pathBuilder.services.landingPage(Product.PricesApi),
          },
          {
            label: "Smart Websockets",
            to: pathBuilder.services.landingPage(Product.Websockets),
          },
        ],
      },
      {
        title: "Data Services",
        items: [
          {
            label: "Webhooks",
            to: Path.Webhooks,
          },
          {
            label: "Subgraphs",
            href: Path.SubgraphsRedirect,
          },
          ...(allowDataPlatformFlag
            ? [
                {
                  label: "Pipelines",
                  to: Path.DataPlatform,
                },
              ]
            : []),
        ],
      },
      {
        title: "Wallet Services",
        items: [
          ...(isSignerServiceEnabled
            ? [
                {
                  label: "Account Kit",
                  to: Path.Accounts,
                },
              ]
            : []),
          {
            label: "Gas Manager",
            to: Path.GasManager,
          },
        ],
      },
      {
        title: "Developer Tools",
        items: [
          {
            label: "Request Logs",
            to: Path.Logs,
          },
          {
            label: "Mempool",
            to: Path.Mempool,
          },
          {
            label: "Sandbox",
            to: Path.Sandbox,
          },
          {
            label: "SDKs",
            href: AlchemyDocsUrl.AlchemySDK,
          },
          {
            label: "Faucets",
            href: Url.Faucets,
          },
          {
            label: "Alchemy University",
            href: Url.University,
          },
        ],
      },
      {
        title: "Admin",
        items: [
          {
            label: "Usage",
            to: Path.Usage,
          },
          {
            label: "Settings",
            items: [
              ...(isBillingAdmin
                ? [
                    {
                      label: "Billing",
                      to: Path.Billing,
                    },
                  ]
                : []),
              {
                label: "Alerts",
                to: Path.ConfigureAlerts,
              },
              {
                label: "Profile",
                to: Path.Profile,
              },
              {
                label: "Team",
                to: Path.Team,
              },
              ...(isBillingAdmin
                ? [
                    {
                      label: "Access Keys",
                      to: Path.AccessKeys,
                    },
                  ]
                : []),
              {
                label: "Sign Out",
                to: Path.Logout,
              },
            ],
          },
        ],
      },
    ];
  }, [isSignerServiceEnabled, isBillingAdmin, allowDataPlatformFlag]);
};

export default useNavItems;

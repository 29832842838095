import { Modal } from "@alch/ui";
import { Search01 } from "@alch/ui/icons/16";
import classNames from "classnames";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useBoolean } from "usehooks-ts";

import { analyticsEvent, EventPrefix } from "@util/analytics";

import Search from "../search/Search";

const buttonClassName = classNames(
  "group flex w-full items-center gap-2 rounded-lg px-2 py-0.5 text-left text-paragraph-200-regular text-invert outline-none transition",
  "hover:cursor-pointer hover:bg-alpha-white-10",
  "focus-visible:shadow-focus-dark dark:focus-visible:shadow-focus",
);

interface NavSearchProps {
  onHideOverlay?: () => void;
}

const NavSearch = ({ onHideOverlay }: NavSearchProps) => {
  const modalBool = useBoolean();

  useHotkeys("mod+k", () => modalBool.setTrue());

  useEffect(() => {
    if (modalBool.value) {
      analyticsEvent(`${EventPrefix.Navbar}: Opened search`);
    }
  }, [modalBool.value]);

  return (
    <div className="px-2.5 py-3">
      <button className={buttonClassName} onClick={modalBool.setTrue}>
        <Search01 />
        Search
      </button>

      <Modal
        isOpen={modalBool.value}
        onClose={modalBool.setFalse}
        title="Search"
      >
        <div className="mb-80">
          <Search
            autoFocus
            setShowOverlay={(show) => {
              modalBool.setValue(show);

              if (!show) {
                onHideOverlay?.();
              }
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default NavSearch;

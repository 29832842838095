import { TeamType } from "@alch/dx-entities";
import { emitDuplicatePaymentProfileErrorIfNeeded } from "@features/profile/paymentProfileUtils.ts";
import { USER_KEY } from "@queries/useCurrentUser";
import { useQueryClient } from "@tanstack/react-query";
import {
  EventPrefix,
  analyticsConversionEvent,
  analyticsEvent,
} from "@util/analytics";
import { getFlattenedEventProperties } from "@util/analytics/heap";
import { PlanTerm, SubscriptionTier } from "@util/constants";
import { logException } from "@util/errors";
import getTrackingValues from "@util/getTrackingValues";
import { useCallback, useEffect } from "react";
import useCompleteSignup from "../../../react-query/mutations/useCompleteSignup";
import { useSignupFlowFormData } from "../SignupFlowProvider";
import { SignupFormData } from "../schemas";

const useSubmitSignupData = () => {
  const {
    mutate: completeSignupMutation,
    error,
    isPending,
    isError,
  } = useCompleteSignup();

  const { formData } = useSignupFlowFormData();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (error) {
      emitDuplicatePaymentProfileErrorIfNeeded(error);
    }
  }, [error]);

  const completeSignup = useCallback(() => {
    const { chain, enterpriseDetails, payment, plan, project, referrer, team } =
      formData as SignupFormData;

    const { promoCode, referredBy, referrerOrigin } = getTrackingValues();

    // If user selects enterprise plan, set plan to free since enterprise users cannot self-serve sign up
    if (plan.planType === SubscriptionTier.ENTERPRISE) {
      plan.planType = SubscriptionTier.FREE;
    }

    const requestData = {
      team_type: team.teamType,
      team_name: team.teamType === TeamType.Team ? team.teamName : undefined,
      team_size: team.teamType === TeamType.Team ? team.teamSize : undefined,
      // Convert comma separated email string to array of emails
      team_member_emails_to_invite:
        team.teamType === TeamType.Team
          ? team.teamEmailsToInvite
              ?.split(",")
              .map((email: string) => email.trim())
          : undefined,
      // Project name is currently not used in the backend since they are dynamically generated
      project_name:
        team.teamType === TeamType.Personal ? team.projectName : undefined,
      project_type:
        "projectType" in project
          ? project.projectType
          : project.otherProjectType,
      chains: chain.chains,
      other_chains: chain.otherChains,
      tier: plan.planType,
      plan_term: plan.planTerm,
      enterprise_project_details: enterpriseDetails?.enterpriseDetails,
      chargify_token: payment?.chargifyToken,
      referrer_response: referrer?.referrer,
      promo_code: promoCode,
      referred_by: referredBy,
      referrer_origin: referrerOrigin,
    };

    const eventProperties = getFlattenedEventProperties({
      teamType: team.teamType,
      teamName: team.teamType === TeamType.Team ? team.teamName : undefined,
      teamSize: team.teamType === TeamType.Team ? team.teamSize : undefined,
      projectName:
        team.teamType === TeamType.Personal ? team.projectName : undefined,
      projectType:
        "projectType" in project
          ? project.projectType
          : project.otherProjectType,
      chains: chain.chains,
      otherChains: chain.otherChains,
      tier: SubscriptionTier[plan.planType],
      planTerm: PlanTerm[plan.planTerm],
      enterpriseProjectDetails: enterpriseDetails?.enterpriseDetails,
      enteredCreditCard: !!payment?.chargifyToken,
      referrerResponse: referrer?.referrer,
      promoCode: promoCode,
      referredBy: referredBy,
      referrerOrigin: referrerOrigin,
    });

    completeSignupMutation(requestData, {
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: USER_KEY });
        analyticsEvent(
          `${EventPrefix.SignupFlowRedesign}: Completed signup`,
          eventProperties,
        );
        analyticsConversionEvent();
      },
      onError: (error) => {
        logException(error);
        analyticsEvent(
          `${EventPrefix.SignupFlowRedesign}: Failed to complete signup`,
        );
      },
    });
  }, [completeSignupMutation, formData, queryClient]);

  return { completeSignup, error, isPending, isError };
};

export default useSubmitSignupData;

import { SearchParam } from "@hooks/useSearchParam";
import { AUTH_ORIGIN } from "./deployEnv";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { logoutAnalyticsUser } from "./analytics";

const ORIGIN = globalThis.location?.origin;
const AUTH_TOKEN_KEY = "authToken";

/**
 * Gets a stored Auth Token for local storage.  We do this instead of cookies.
 */
export function getStoredAuthToken(): string | undefined {
  return localStorage.getItem(AUTH_TOKEN_KEY) || undefined;
}

/**
 * Sets an auth token in local storage.
 */
export function setStoredAuthToken(authToken: string) {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
}

/**
 * Removes the auth token from local storage.
 */
export function removeStoredAuthToken() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
}

/**
 * URL for sending the user to authchemy to get authenticated, then come back.
 */
export function authchemyRedirectUrl(redirectUrl: string) {
  return `${AUTH_ORIGIN}/?redirectUrl=${encodeURIComponent(redirectUrl)}`;
}

/**
 * Redirects to authchemy for authentication, returning to the same URL
 */
export function redirectToAuthchemy(attempt?: number) {
  const url = new URL(window.location.href);

  if (attempt) {
    url.searchParams.set(SearchParam.RedirectAttempt, `${attempt}`);
  }

  window.location.href = authchemyRedirectUrl(url.toString());
}

/**
 * Logs out the user, and redirects back to this app's homepage if they log in again.
 */
export function logOutFromAuthchemy() {
  removeStoredAuthToken();
  logoutAnalyticsUser();

  const url = `${AUTH_ORIGIN}/logout?redirectUrl=${encodeURIComponent(ORIGIN)}`;
  globalThis.location.href = url;
}

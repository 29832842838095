import { initAnalytics, setAnalyticsUser } from "@util/analytics";
import { trackPageVisit } from "@util/analytics/brevo.ts";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../redux/root";

initAnalytics();

/**
 * Use this hook at a high level component.
 */
export default function useAnalyticsInDash() {
  const user = useSelector((state: RootState) => state.users.currentUser.value);
  const tier = useSelector(
    (state: RootState) => state.team.activePlan.value?.tier,
  );
  const team = useSelector((state: RootState) => state.team.team.value);

  const history = useHistory();

  const teamName = team?.name;
  const referredBy = team?.referred_by;
  const setupStage = team?.setup_stage;
  const referredByAffiliate = team?.referred_by_affiliate;

  useEffect(() => {
    if (user) {
      setAnalyticsUser({
        referredBy,
        referredByAffiliate,
        tier,
        teamName,
        setupStage,
        extId: user.ext_id,
        internalId: user.id,
        teamId: user.team_id,
        email: user.email,
        isStaff: user.is_staff,
        createdAt: user.date_joined,
        isBillingAdmin: user.is_billing_admin,
        firstName: user.first_name,
        lastName: user.last_name,
        role: user.role,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME
  }, [user, tier, teamName, referredBy, referredByAffiliate]);

  useEffect(() => {
    // Track the initial page visit
    trackPageVisit(location.pathname);

    history.listen((location, action) => {
      if (action === "PUSH") {
        trackPageVisit(location.pathname);
      }
    });
  }, [history]);
}

import { LowOpacityButton } from "@alch/ui";
import { Star02 } from "@alch/ui/icons/16";

import { useState } from "react";
import FeedbackModal from "./FeedbackModal";

interface NavFooterProps {
  onHideOverlay?: () => void;
}

const NavFooter = ({ onHideOverlay }: NavFooterProps) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  return (
    <footer className="relative z-20 flex flex-col gap-2 px-4 pb-5 pt-1 transition">
      <LowOpacityButton
        icon={<Star02 />}
        className="flex w-full justify-center overflow-hidden whitespace-nowrap"
        onClick={() => setIsFeedbackModalOpen(true)}
      >
        Share feedback
      </LowOpacityButton>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => {
          setIsFeedbackModalOpen(false);
          onHideOverlay?.();
        }}
      />
    </footer>
  );
};

export default NavFooter;

/**
 * Theme: Alchemy Dashboard
 * Adheres to the theme spec at https://theme-ui.com/theme-spec
 * Inspired by @theme-ui/preset-base https://github.com/system-ui/theme-ui/blob/2e296a07b7e47b45d058a237b46e17f5f1688528/packages/preset-base/src/index.ts
 */

const baseColors = {
  primary: {
    dark: "#1034B8",
    core: "#254CDD",
    light: "#4C84FF",
    lighter: "#E8F4FD",
  },
  success: {
    dark: "#25B47E",
    core: "#46CD92",
    light: "#70E7B3",
    lighter: "#EBF4EC",
  },
  warning: {
    dark: "#DA972E",
    core: "#F0AD44",
    light: "#F1BE6E",
    lighter: "#FDF0E3",
  },
  danger: {
    dark: "#CD4F38",
    core: "#EB5D44",
    light: "#F38E7C",
    lighter: "#FAEAEA",
  },
  gray: [
    "#EAECF0",
    "#CACED4",
    "#A0A4AC",
    "#767B81",
    "#52555B",
    "#414348",
    "#212122",
  ],
  black: "#000000",
  white: "#FFFFFF",
};

/**
 * The `as const` needs to be applied to every object that doesn't have an
 * array below it.
 * `as const` prevents Typescript from widening string literals, e.g. prevent
 * { textAlign: 'left' } from being exported as { textAlign: string } which would be incompatible
 * with component props expecting { textAlign: "left" | "right" }.
 * HOWEVER, `as const` will also convert an array to a `readonly` array, which is incompatible
 * with the `Theme` type that `ThemeProvider` expects to receive.
 * This is unfortunately the only way to prevent widening without making the arrays `readonly`.
 */
const theme = {
  colors: {
    ...baseColors,
    background: "#f5f6f7",
    text: "#4b4b4b",
  } as const,
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],
  fonts: {
    body: "'Rubik', Helvetica, Arial, sans-serif",
    heading: "'Rubik', Helvetica, Arial, sans-serif",
    monospace: "'Roboto Mono', monospace",
  } as const,
  fontSizes: [10, 12, 14, 16, 18, 24, 32],
  fontWeights: {
    light: 300,
    regular: 400,
    semibold: 500,
    body: 400,
    heading: 400,
  } as const,
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  } as const,
  text: {
    body: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
  } as const,
  breakpoints: ["600px", "960px", "1280px", "1920px"],
};

export default theme;

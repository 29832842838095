// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { Policy } from "../gasPolicyTypes";

export function displayedHex(hex: string) {
  return `${hex.substring(0, 11)}...${hex.substring(
    hex.length - 8,
    hex.length,
  )}`;
}

export const getCurrTimeZone = () => {
  return new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];
};

export const formatGrantedAtDate = (date: string) => {
  const dateObj = GasPolicyDateConversion.toDate(date);
  return dateObj.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const transpolationForDate = (date?: Date) => {
  return date?.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const asDollarAmount = (amount: number) => {
  return `$${amount.toFixed(2)}`;
};

export class GasPolicyDateConversion {
  public static fromDate(date: Date): string {
    return Math.floor(date.getTime() / 1000).toString();
  }

  public static toDate(timestamp: number | string): Date {
    const ts =
      typeof timestamp === "string" ? parseInt(timestamp, 10) : timestamp;
    return new Date(ts * 1000);
  }
}

export const formatPolicyUndefinedsForNulls = (policy: Policy) => {
  return {
    ...policy,
    type: policy.policyType ?? "sponsorship",
    rules: {
      ...policy.rules,
      maxSpendPerSenderUsd: policy.rules.maxSpendPerSenderUsd ?? null,
      maxCountPerSender: policy.rules.maxCountPerSender ?? null,
      maxSpendUsd: policy.rules.maxSpendUsd ?? null,
      maxCount: policy.rules.maxCount ?? null,
      maxSpendPerUoUsd: policy.rules.maxSpendPerUoUsd ?? null,
      senderAllowlist: policy.rules.senderAllowlist ?? null,
      senderBlocklist: policy.rules.senderBlocklist ?? null,
    },
  };
};

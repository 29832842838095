export enum SignupFormFieldName {
  TeamType = "teamType",
  TeamName = "teamName",
  TeamSize = "teamSize",
  TeamEmailsToInvite = "teamEmailsToInvite",
  ProjectName = "projectName",
  ProjectType = "projectType",
  OtherProjectType = "otherProjectType",
  Chains = "chains",
  OtherChains = "otherChains",
  PlanType = "planType",
  PlanTerm = "planTerm",
  EnterpriseDetails = "enterpriseDetails",
  Address = "address",
  City = "city",
  State = "state",
  Country = "country",
  ZipCode = "zipCode",
  ChargifyToken = "chargifyToken",
  Referrer = "referrer",
}

export enum SignupSchemaField {
  Team = "team",
  Project = "project",
  Chain = "chain",
  Plan = "plan",
  EnterpriseDetails = "enterpriseDetails",
  Payment = "payment",
  Billing = "billing",
  Referrer = "referrer",
}

export enum SignupStep {
  Team = "team",
  Project = "project",
  Chain = "chain",
  Plan = "plan",
  EnterpriseDetails = "enterpriseDetails",
  Payment = "payment",
  Referrer = "referrer",
}

export enum UnsupportedChain {
  ZkSyncEra = "zkSyncEra",
  Scroll = "scroll",
  Aurora = "aurora",
  ImmutableZkEvm = "immutableZkEvm",
  CoreBlockchain = "coreBlockchain",
  AlephZero = "alephZero",
  Rsk = "rsk",
  Gnosis = "gnosis",
  BobaNetwork = "bobaNetwork",
  Other = "other",
}

export const SignupStepsList = Object.values(SignupStep);

export const SignupStepPathMap = SignupStepsList.reduce(
  (obj, step) => {
    obj[step] = `/signup/${step}`;
    return obj;
  },
  {} as { [key in SignupStep]: string },
);

export enum ChargifyInputField {
  FirstName = "first-name",
  LastName = "last-name",
  CardNumber = "card-number",
  ExpirationMonth = "expiration-month",
  ExpirationYear = "expiration-year",
  CVV = "cvv",
}

import getCookie from "./getCookie";

export const REFERRAL_KEY = "referral";
export const REF_KEY = "ref";
export const AFFILIATE_KEY = "a";
export const AFFILIATE_PREFIX = "affiliate";
export const REFERRER_ORIGIN_KEY = "referrer_origin";
export const PROMO_CODE_KEY = "promo_code";

/**
 * Tracking & promo cookies set by the marketing page that the dashboard needs to know about
 * for user signup and billing changes
 */
export default function getTrackingValues() {
  return {
    referredBy: getCookie(REFERRAL_KEY) || getCookie(REF_KEY) || undefined,
    referrerOrigin: getCookie(REFERRER_ORIGIN_KEY) || undefined,
    promoCode: getCookie(PROMO_CODE_KEY) || undefined,
  };
}

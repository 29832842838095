import {
  Redirect as ReactRouterRedirect,
  RedirectProps,
  useLocation,
} from "react-router-dom";

/**
 * Redirects to a new location while preserving the current query string.
 */
export const Redirect = ({ to, ...props }: RedirectProps) => {
  const location = useLocation();
  const toObject = typeof to === "string" ? { pathname: to } : to;

  return <ReactRouterRedirect to={{ ...location, ...toObject }} {...props} />;
};

import moment from "moment";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ABBREV_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const SELF_SERVE_LAUNCH_DATE = "2020-07-29";
export const ONE_DAY_MS = 1000 * 60 * 60 * 24;

interface MonthsItem {
  monthInt: number;
  monthName: string;
  monthAbbrev: string;
}

export const getPrettifiedShortDate = (timestamp: number) => {
  const now = new Date();
  const date = new Date(timestamp);
  const hourDiff = (now.valueOf() - date.valueOf()) / (1000 * 60 * 60);

  // Displays if it is the same day. Ex: 8:38 AM.
  if (now.getDate() === date.getDate()) {
    return moment(date).format("h:mm A");
  }

  // Displays if it is within 6 days. Ex: Thu, 8:38 AM.
  if (hourDiff < 24 * 6) {
    return moment(date).format("ddd h:mma");
  }

  // Displays if it is within the same year. Ex: Feb 16, 8:38 AM.
  if (now.getFullYear() === date.getFullYear()) {
    return moment(date).format("MMM D h:mma");
  }

  // Displays otherwise. Ex: 3/16/2020 8:38 AM.
  return `${moment(date).format("l h:mma")}`;
};

export const getPrettifiedDate = (timestamp: number) => {
  const now = new Date();
  const date = new Date(timestamp);
  const hourDiff = (now.valueOf() - date.valueOf()) / (1000 * 60 * 60);

  // Displays if it is the same day. Ex: 8:38:05 AM.
  if (now.getDate() === date.getDate()) {
    return moment(date).format("h:mm:ss A");
  }

  // Displays if it is within 6 days. Ex: Thursday, 8:38:05 AM.
  if (hourDiff < 24 * 6) {
    return moment(date).format("dddd, h:mm:ss A");
  }

  // Displays if it is within the same year. Ex: February 16, 8:38:05 AM.
  if (now.getFullYear() === date.getFullYear()) {
    return moment(date).format("MMMM Do, h:mm:ss A");
  }

  // Displays otherwise. Ex: 3/16/2020 8:38:05 AM.
  return `${moment(date).format("l, h:mm:ss A")}`;
};

export const getPrettifiedTimeDiff = (earlierTS: number, laterTS?: number) => {
  const earlier = new Date(earlierTS);
  const later = laterTS ? new Date(laterTS) : new Date();
  const milliTimeDiff = later.valueOf() - earlier.valueOf();
  return getPendingTimeHandler(milliTimeDiff);
};

const getPendingTimeHandler = (millis: number) => {
  const seconds = Math.floor((millis / 1000) % 60);
  const minutes = Math.floor((millis / 1000 / 60) % 60);
  const hours = Math.floor(millis / (1000 * 60 * 60));
  return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;
};

const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, "0");

export function getMonthsAgoItem(monthsAgo: number): MonthsItem {
  const curMonthInt = new Date().getMonth();
  const monthInt = (curMonthInt - monthsAgo + MONTHS.length) % MONTHS.length;
  return {
    monthInt,
    monthName: MONTHS[monthInt],
    monthAbbrev: ABBREV_MONTHS[monthInt],
  };
}

export function getFirstDayOfNextMonth(now = new Date()) {
  return new Date(now.getFullYear(), now.getMonth() + 1);
}

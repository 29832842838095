import SignupTemplatePage from "@features/signup/Pages/SignupTemplatePage";
import {
  SignupFormFieldName,
  SignupSchemaField,
  SignupStep,
} from "@features/signup/constants";
import { useSignupForm } from "@features/signup/hooks/useSignupForm";

const CURRENT_STEP = SignupStep.EnterpriseDetails;

const EnterpriseDetailsPage = () => {
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useSignupForm({
    schemaField: SignupSchemaField.EnterpriseDetails,
    defaultValues: {
      [SignupFormFieldName.EnterpriseDetails]: "",
    },
  });

  return (
    <SignupTemplatePage
      title="Tell us about your project"
      subtitle="We'll use this information to tailor the most optimal plan for your needs."
      currentStep={CURRENT_STEP}
      onNextButtonClick={handleSubmit}
      disableNextButton={!isValid}
    >
      <textarea
        {...register(SignupFormFieldName.EnterpriseDetails)}
        className="h-[120px] w-full resize-none rounded-lg border border-grayscale-300 p-3 text-paragraph-200-regular focus:border-blue-400"
        placeholder="My company is building..."
      />
    </SignupTemplatePage>
  );
};

export default EnterpriseDetailsPage;

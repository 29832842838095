import Axios from "axios";
import { flatten } from "lodash";

interface AlgoliaResponse {
  results: AlgoliaResult[];
}
interface AlgoliaResult {
  hits: AlgoliaHit[];
}

interface AlgoliaHit {
  title: string;
  excerpt?: string;
  internalLink: string;
}

export interface DocLink {
  label: string;
  description?: string;
  to: string;
}

// Reverse engineered from docs.alchemy.com
const url =
  "https://52cqu0z4x8-dsn.algolia.net/1/indexes/*/queries?x-algolia-agent=Algolia%20for%20JavaScript%20(3.35.1)%3B%20Browser%20(lite)%3B%20react%20(16.14.0)%3B%20react-instantsearch%20(6.38.1)%3B%20react-instantsearch-hooks%20(6.38.1)%3B%20JS%20Helper%20(3.11.3)&x-algolia-application-id=52CQU0Z4X8&x-algolia-api-key=Y2Y4N2NiNDcxZmNkOTBmMjIyZjgyODVhNDNhMzU1YWJmN2I3YmUxYmM2ODFiYWM0MjlmMDQwMjY4M2RjMDEyZHRhZ0ZpbHRlcnM9KHByb2plY3Q6NjI5ZTM4YzRmNGE5YWYwMDEzY2RlOWY2KSwodmVyc2lvbjpub25lLHZlcnNpb246NjI5ZTM4YzVmNGE5YWYwMDEzY2RlOWZiKSwoaGlkZGVuOm5vbmUsaGlkZGVuOmZhbHNlKSwoaW5kZXg6Q3VzdG9tUGFnZSxpbmRleDpQYWdlLGluZGV4OkJsb2csaW5kZXg6RGlzY3VzcyxpbmRleDpUdXRvcmlhbCk%3D";

/**
 * Uses the same query that docs.alchemy.com uses via gitbooks.  Not an official API so
 * this could stop working in the future, e.g. if they change their API key.
 */
export default async function searchDocs(query: string): Promise<DocLink[]> {
  const algoliaParams = `query=${encodeURIComponent(query)}&hitsPerPage=4`;
  const data = {
    requests: [
      {
        indexName: "readme_search_v2",
        params: algoliaParams,
      },
    ],
  };

  const response = await Axios.post<AlgoliaResponse>(url, data);

  const allHits = flatten(response.data.results.map((result) => result.hits));
  return allHits.map((hit) => ({
    label: hit.title,
    description: hit.excerpt,
    to: `https://docs.alchemy.com/${hit.internalLink}`,
  }));
}

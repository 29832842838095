import { useBreakpointValue } from "@alch/ui";
import classNames from "classnames";
import { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ScheduleCall from "@features/schedule-call/ScheduleCall";
import { fetchGlobalSettings as fetchGlobalSettingsAction } from "../../redux/globalSettings";
import { fetchOpenInvoices as fetchOpenInvoicesAction } from "../../redux/payments";
import {
  fetchActivePlan as fetchActivePlanAction,
  fetchFcuUsageStats as fetchFcuUsageStatsAction,
  fetchPlanPreference as fetchPlanPreferenceAction,
  fetchTeam as fetchTeamAction,
} from "../../redux/team";
import { fetchCurrentUser as fetchCurrentUserAction } from "../../redux/users";

import Header from "./Header";
import { useLayoutContext } from "./LayoutContext";
import SupportFAB from "./SupportFAB";
import Navigation from "./nav";

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const dispatch = useDispatch();
  const [showNavOverlay, setShowNavOverlay] = useState(false);
  const withOverlays = useBreakpointValue({ base: true, md: false }) || false;
  const layoutContext = useLayoutContext();

  useEffect(() => {
    const anyOverlayShown = showNavOverlay;
    const root = document.getElementById("root");

    if (!root) return;

    // Disable root container scroll when an overlay is shown
    root.style.overflow = anyOverlayShown ? "hidden" : "";
  }, [showNavOverlay]);

  useEffect(() => {
    dispatch(fetchCurrentUserAction());
    dispatch(fetchTeamAction());
    dispatch(fetchPlanPreferenceAction());
    dispatch(fetchActivePlanAction());
    dispatch(fetchGlobalSettingsAction());
    dispatch(fetchFcuUsageStatsAction());
    dispatch(fetchOpenInvoicesAction());
  }, [dispatch]);

  return (
    <div
      className={classNames(
        "mx-auto flex min-h-full w-full max-w-screen-3xl gap-9 md:p-4 md:pr-5",
        layoutContext.isFullScreenPage ? "max-h-full" : null,
      )}
    >
      <Navigation
        withOverlays={withOverlays}
        showOverlay={showNavOverlay}
        setShowOverlay={setShowNavOverlay}
      />

      <div className="flex min-w-0 flex-1 flex-col">
        <Header
          withOverlays={withOverlays}
          setShowNavOverlay={setShowNavOverlay}
        />

        <div
          className={classNames(
            "max-md:px-4 max-md:pb-4",
            layoutContext.isFullScreenPage ? "min-h-0 flex-1" : null,
          )}
        >
          {children}
        </div>

        <SupportFAB hidden={layoutContext.isFullScreenPage} />
        <Suspense>
          <ScheduleCall hidden={layoutContext.isFullScreenPage} />
        </Suspense>
      </div>
    </div>
  );
};

export default AppLayout;

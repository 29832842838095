import { CheckCreditCardParams } from "@features/signup/types";
import useSearchParam, { SearchParam } from "@hooks/useSearchParam";
import { useMutation } from "@tanstack/react-query";
import { checkCreditCard } from "../../http/endpoints";

function useCheckCreditCard() {
  const failFingerprint = useSearchParam(SearchParam.TestFailFingerprint);

  return useMutation<Record<string, never>, Error, CheckCreditCardParams>({
    mutationFn: (params: CheckCreditCardParams) =>
      checkCreditCard({
        ...params,
        test_fail_fingerprint: !!failFingerprint || undefined,
      }),
  });
}

export default useCheckCreditCard;

import type { QueryClient } from "@tanstack/react-query";
import { createTRPCReact, TRPCUntypedClient } from "@trpc/react-query";
import { AnyTRPCRouter } from "@trpc/server";
import { getViewAsId } from "@util/viewAs";
import { createContext } from "react";
import { getStoredAuthToken } from "../auth";

export interface TrpcProviderProps<TRouter extends AnyTRPCRouter> {
  trpc: ReturnType<typeof createTRPCReact<TRouter>>;
  trpcClient: TRPCUntypedClient<TRouter>;
  reactQueryContext?: ReturnType<typeof createContext<QueryClient | undefined>>;
}

export function createHttpLinksOpt(apiOrigin: string) {
  return {
    url: `${apiOrigin}/trpc`,
    headers() {
      const authToken = getStoredAuthToken();
      if (!authToken) return {};

      return {
        Authorization: `Bearer ${authToken}`,
      };
    },
    fetch: (
      url: string | URL | RequestInfo,
      options: RequestInit | undefined,
    ) => {
      let modifiedUrl = url;

      // This is always true
      if (typeof url === "string") {
        const { view_as, view_as_team } = getViewAsId() ?? {};
        const urlObj = new URL(url);
        if (view_as) urlObj.searchParams.set("view_as", view_as);
        if (view_as_team) urlObj.searchParams.set("view_as_team", view_as_team);
        modifiedUrl = urlObj.toString();
      }

      return fetch(modifiedUrl, {
        ...options,
        // This is required for deviceId cookie used for flags
        credentials: "include",
        // Send the page URL the fetch call was made from so backend sentry can see it
        referrerPolicy: "no-referrer-when-downgrade",
      });
    },
  };
}

import * as RadioGroup from "@radix-ui/react-radio-group";
import clsx from "clsx";
import { useCallback } from "react";
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";

export type PillRadioItem<TValue> = {
  label: string;
  value: TValue;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export enum PillRadioGroupVariant {
  Large = "Large",
  Small = "Small",
}
interface PillRadioGroupProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  defaultValue?: TFieldValues[TName];
  onChange?: (newVal: TFieldValues[TName]) => void;
  items: PillRadioItem<TFieldValues[TName]>[];
  variant?: PillRadioGroupVariant;
  label?: string;
}

function PillRadioGroup<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  defaultValue,
  items,
  onChange,
  control,
  name,
  variant = PillRadioGroupVariant.Large,
  label,
}: PillRadioGroupProps<TFieldValues, TName>) {
  const {
    field: { onChange: onFieldChange, value: fieldValue },
  } = useController({
    control,
    name,
  });

  const handleValueChange = useCallback(
    (newVal: TFieldValues[TName]) => {
      onFieldChange(newVal);
      if (onChange) {
        onChange(newVal);
      }
    },
    [onChange, onFieldChange],
  );

  const selectedClassName = clsx("border-2 border-blue-400");
  const unselectedClassName = clsx(
    "flex items-center rounded-2xl border border-grayscale-300 bg-white",
    variant === PillRadioGroupVariant.Large && "h-[76px] p-4",
    variant === PillRadioGroupVariant.Small &&
      "h-[52px] justify-center px-5 py-3",
  );

  return (
    <div>
      {label && (
        <div>
          <label className="text-label-200">{label}</label>
          <div className="h-2" />
        </div>
      )}
      <RadioGroup.Root
        className="grid grid-cols-2 gap-x-6 gap-y-3"
        defaultValue={String(defaultValue)}
        onValueChange={handleValueChange}
        value={fieldValue}
      >
        {items.map(({ label, value, Icon }) => (
          <RadioGroup.Item
            className={clsx(
              unselectedClassName,
              value === fieldValue && selectedClassName,
            )}
            key={String(value)}
            id={String(value)}
            value={String(value)}
          >
            <div
              className={clsx(
                value === fieldValue
                  ? "text-paragraph-300-medium text-blue-600"
                  : "text-black",
                "flex items-center ",
              )}
            >
              {Icon && <Icon className="ml-2 mr-5 size-6" />}
              <label>{label}</label>
            </div>
          </RadioGroup.Item>
        ))}
      </RadioGroup.Root>
    </div>
  );
}

export default PillRadioGroup;

import { NetworkInfo } from "@alch/dx-entities";
import { Button, IconButton } from "@alch/ui";
import { X01 } from "@alch/ui/icons/24";
import NetworkEmblem from "@components/ui/icons/NetworkEmblem";
import { ViewAsPreservingLink } from "@features/common/ViewAsPreservingLink";
import isExternalUrl from "@util/isExternalUrl";
import clsx from "clsx";

const DEFAULT_BACKGROUND_COLOR = "#3943FA";

interface ProductBannerButtonConfig {
  url: string;
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

interface ProductBannerImageProps {
  imageUrl?: string;
  networkInfo?: NetworkInfo;
}

const ProductBannerImage = ({
  imageUrl,
  networkInfo,
}: ProductBannerImageProps) => {
  if (imageUrl) {
    return (
      <div className="flex h-[200px] w-1/3 justify-center">
        <img src={imageUrl} />
      </div>
    );
  } else if (networkInfo) {
    return (
      <div className="relative flex min-w-52 items-center rounded-l-full border-2 border-r-0 border-white/70 bg-white/60 p-3 backdrop-blur-lg">
        <div className="rounded-full bg-white p-5">
          <NetworkEmblem networkInfo={networkInfo} className="size-[88px]" />
        </div>
      </div>
    );
  }

  return null;
};

interface ProductBannerProps {
  title: React.ReactNode;
  description: React.ReactNode;
  primaryButtonConfig?: ProductBannerButtonConfig;
  secondaryButtonConfig?: ProductBannerButtonConfig;
  imageUrl?: string;
  backgroundColor?: string;
  networkInfo?: NetworkInfo;
  onHide: () => void;
}

const ProductBanner = ({
  title,
  description,
  primaryButtonConfig,
  secondaryButtonConfig,
  imageUrl,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  networkInfo,
  onHide,
}: ProductBannerProps) => {
  return (
    <div
      className={clsx(
        "relative isolate flex min-h-56 items-center justify-between overflow-hidden rounded-2xl shadow-lg",
      )}
      style={{ backgroundColor }}
    >
      <div className="absolute top-3/4 size-full -rotate-12 bg-gradient-to-r from-white/40 via-white/40 to-white/5 lg:top-full lg:-mt-6" />

      <div className="relative flex max-w-lg flex-col gap-6 p-8">
        <div className="flex flex-col gap-1">
          <h2 className="text-heading-h2 text-invert">{title}</h2>

          <p className="text-paragraph-200-regular text-invert">
            {description}
          </p>
        </div>

        {(primaryButtonConfig || secondaryButtonConfig) && (
          <div className="flex gap-3 pt-2">
            {primaryButtonConfig && (
              <Button
                size="md"
                intent="secondary"
                as={ViewAsPreservingLink}
                to={primaryButtonConfig.url}
                target={
                  isExternalUrl(primaryButtonConfig.url) ? "_blank" : undefined
                }
                icon={primaryButtonConfig.icon}
                onClick={primaryButtonConfig.onClick}
              >
                {primaryButtonConfig.label}
              </Button>
            )}

            {secondaryButtonConfig && (
              <Button
                size="md"
                intent="tertiary"
                as={ViewAsPreservingLink}
                to={secondaryButtonConfig.url}
                target={
                  isExternalUrl(secondaryButtonConfig.url)
                    ? "_blank"
                    : undefined
                }
                icon={secondaryButtonConfig.icon}
                onClick={secondaryButtonConfig.onClick}
              >
                {secondaryButtonConfig.label}
              </Button>
            )}
          </div>
        )}
      </div>

      <ProductBannerImage imageUrl={imageUrl} networkInfo={networkInfo} />

      <IconButton
        className="absolute right-1.5 top-1.5 text-icon-invert"
        background="dark"
        onClick={onHide}
      >
        <X01 />
      </IconButton>
    </div>
  );
};

export default ProductBanner;

import { Flag, FlagTypes, flagSchemaMap } from "@alch/dx-entities";
import { UseTRPCQueryResult } from "@trpc/react-query/shared";
import { trpc } from "@util/trpc/trpcClient";
import qs from "qs";
import { useLocation } from "react-router-dom";

function useFlags<T = FlagTypes>(
  flagIds: Flag[],
  options?: {
    select?: (flags: FlagTypes) => T;
    enabled?: boolean;
  },
) {
  const { select, enabled } = options || {};
  const userFields = {
    userAgent: navigator.userAgent,
    locale: navigator.languages ? navigator.languages[0] : navigator.language,
  };

  return trpc.flags.getFlags.useQuery(
    { flagIds, userFields },
    {
      // Cache response for 10 minutes
      staleTime: 10 * 60 * 1000,
      select: (flags) => (select ? select(flags) : flags),
      enabled,
    },
  ) as UseTRPCQueryResult<
    typeof select extends undefined ? FlagTypes : T,
    Error
  >;
}

export function useFlag<T extends Flag>(
  flag: T,
  options?: { enabled?: boolean },
) {
  const overrides = useFlagOverrides();

  return useFlags([flag], {
    select: (flags) => overrides?.[flag] ?? flags[flag],
    ...options,
  });
}

// Allow overriding flags via query string
// e.g. ?flag_alert_channel=true or ?flag_product_landing_page.quickstart_variant=accordion
function useFlagOverrides(): Partial<FlagTypes> {
  const location = useLocation();

  const parsedQS = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
    decoder: booleanParser,
  });

  return Object.fromEntries(
    Object.entries(flagSchemaMap.shape).map(([key, schema]) => {
      const value = parsedQS[`flag_${key}`];
      const parsedValue = schema.safeParse(value);

      return [key, parsedValue.success ? parsedValue.data : undefined];
    }),
  );
}

const booleanParser = (str: string, defaultDecoder: (str: string) => string) =>
  str === "true" ? true : str === "false" ? false : defaultDecoder(str);

import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getTeam } from "../../http/endpoints";
import { AppDispatch } from "../../redux/root";
import { requestTeam } from "../../redux/team";

export const TEAM_QUERY_KEY = ["team"];

function useQueryOptions() {
  const dispatch = useDispatch<AppDispatch>();

  return queryOptions({
    queryKey: TEAM_QUERY_KEY,
    queryFn: async () => {
      const team = await getTeam();

      // Keep redux state in sync
      dispatch(
        requestTeam.done({
          params: { params: undefined, loadingKey: -1 },
          result: { value: team, updateTime: Date.now() },
        }),
      );

      return team;
    },
  });
}

function useTeam() {
  return useQuery(useQueryOptions());
}

export function useSuspenseTeam() {
  return useSuspenseQuery(useQueryOptions());
}

export default useTeam;

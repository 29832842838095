import assertNever from "assert-never";
import axios, { AxiosError, AxiosResponse } from "axios";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { getStoredAuthToken } from "../util/auth";
import {
  AuthError,
  PermissionError,
  ValidationError,
  logException,
} from "../util/errors";
import { DJANGO_API_ORIGIN } from "../util/settings";
import { getViewAsId } from "../util/viewAs";

export interface DjangoSuccessResponse<T> {
  readonly data: T;
}

export interface DjangoErrorResponse {
  readonly message: string;
  readonly name?: string;
}

export enum Method {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  PUT = "PUT",
  DELETE = "DELETE",
}

export const VIEWING_AS_WRITE_ERROR_MESSAGE =
  "Cannot write while viewing as someone else.";

export function callEndpoint<T>(
  method: Method,
  endpoint: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
  params?: any,
): Promise<T> {
  const viewAsId = getViewAsId();
  if (viewAsId && method !== Method.GET) {
    return Promise.reject(new PermissionError(VIEWING_AS_WRITE_ERROR_MESSAGE));
  }
  const url = `${DJANGO_API_ORIGIN}${endpoint}`;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
  const fixedParams = viewAsId ? insertViewAs(params, viewAsId) : params;
  return rawCallEndpoint<T>(method, url, fixedParams).then(
    getAxiosResponseData,
    (error: AxiosError<DjangoErrorResponse>) => {
      // Tries to categorize the error as a class of django-api error,
      // but this erases all context, so we need to make sure to log
      // the original context-rich error to Sentry (below)
      const transformedError = getAxiosInternalError(error);

      // If this is an expected error type, don't log to Sentry.
      if (
        !(
          transformedError instanceof ValidationError ||
          transformedError instanceof PermissionError ||
          transformedError instanceof AuthError
        )
      ) {
        // Log the original error with all its context to Sentry
        logException(error);
      }

      throw transformedError;
    },
  );
}

function rawCallEndpoint<T>(
  method: Method,
  endpoint: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
  params?: any,
): Promise<AxiosResponse<DjangoSuccessResponse<T>>> {
  const token = getStoredAuthToken();
  const headers = { authorization: token ? `Bearer ${token}` : "" };

  switch (method) {
    case Method.GET:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
      return axios.get(endpoint, { params, headers });
    case Method.POST:
      return axios.post(endpoint, params, { headers });
    case Method.PUT:
      return axios.put(endpoint, params, { headers });
    case Method.DELETE:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
      return axios.delete(endpoint, { params, headers });
    case Method.PATCH:
      return axios.patch(endpoint, params, { headers });
    default:
      return assertNever(method);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
function insertViewAs(params: any, viewAs: Record<string, string>): any {
  return { ...(params || {}), ...viewAs };
}

function getAxiosResponseData<T>(
  response: AxiosResponse<DjangoSuccessResponse<T>>,
): T {
  return response.data.data;
}

export function getAxiosInternalError(
  wrappedError: AxiosError<DjangoErrorResponse>,
): Error {
  const errorResponse:
    | AxiosResponse<DjangoErrorResponse | undefined>
    | undefined = wrappedError.response;

  // If the HTTP code is 0 then we likely never even connected to the server
  if (!wrappedError.response?.status) {
    // Error type is like "ETIMEDOUT".  It's not very user friendly but it
    // gives us an idea of what kind of network error occurred
    const axiosErrorType = wrappedError.code ? ` (${wrappedError.code})` : "";
    throw new Error(
      `There was a problem connecting to the server.  Please try again.${axiosErrorType}`,
    );
  }

  // If we didn't get a response, we don't know what went wrong so just return a
  // generic error
  if (!errorResponse || !errorResponse.data) {
    return new Error();
  }

  const { name, message } = errorResponse.data;
  switch (name) {
    case ValidationError.displayName:
      return new ValidationError(message);
    case AuthError.displayName:
      return new AuthError(message);
    case PermissionError.displayName:
      return new PermissionError(message);
    default: {
      const error = new Error(message);
      if (name) {
        error.name = name;
      }
      return error;
    }
  }
}

export function configureAxios(): void {
  axios.defaults.headers.common.Accept = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
}

import { WebhookType } from "@alch/dx-entities";
import {
  GetAccountsProjectConfigResponse,
  GetAccountsTosInfoResponse,
  GetTeamSignatureStatsResponse,
  PostAccountsProjectConfigRequest,
  PostAccountsProjectConfigResponse,
  PostAccountsTosResponse,
  PostImageUrlRequest,
  PostImageUrlResponse,
  PutAccountsProjectConfigRequest,
  PutAccountsProjectConfigResponse,
  SetupEmbeddedAccountsResponse,
} from "@features/accounts/types";
import {
  GasPoliciesTeamLimits,
  GetPoliciesResponse,
  GetPolicySponsorshipResponse,
  Policy,
  PolicyCreationParams,
  PolicyStats,
  PolicyStatusUpdateParams,
  PolicyUpdateParams,
} from "@features/gas-policies/gasPolicyTypes";
import { formatPolicyUndefinedsForNulls } from "@features/gas-policies/shared/formats";
import {
  CreateAccessKeyRequestParams,
  CreateAccessKeyResponse,
  DeleteAccessKeyResponse,
  GetAccessKeysResponse,
  GetPermissionsResponse,
} from "@features/profile/AccessKeys/AccessKeysTypes";
import {
  CreatePublicKeyRequestParams,
  CreatePublicKeyResponse,
  DebugJwtRequestParams,
  DebugJwtResponse,
  DeletePublicKeyResponse,
  GetPublicKeysResponse,
} from "@features/profile/PublicKeys/PublicKeysTypes";
import { AppRequest } from "@features/query-search/appRequest";
import {
  CheckCreditCardParams,
  CompleteSignupRequestParams,
} from "@features/signup/types";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { TimeSeriesPoint } from "../redux/appStats";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  App,
  AppIdParams,
  CreateAppParams,
  UpdateAppParams,
} from "../redux/apps";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { GlobalSettings } from "../redux/globalSettings";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  FilteredTransactionParams,
  MempoolTransaction,
  MempoolTransactionsBlob,
  TransactionParams,
} from "../redux/mempool";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  BillingDetails,
  CreditCardInfo,
  Invoice,
  OpenInvoice,
  UpdateBillingDetailsParams,
} from "../redux/payments";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  CreatePaymentProfileParams,
  EarnSocialBonusParams,
  FcuUsageStats,
  ReferralMonthlyBonus,
  ReferralSocialBonuses,
  RequestUpgradeParams,
  Team,
  TeamAndUserSettings,
  TeamMonthlyPlan,
  TeamPlan,
  UpdatePlanPreferenceParams,
  UpdateRateLimitSettingsParams,
  UpdateSetupStageParams,
} from "../redux/team";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  EthMethodComputeStat,
  UsageParams,
  UsageTimeSeriesPoint,
} from "../redux/usage";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  CreateAndInviteTeamMembersParams,
  TeamMemberIdParams,
  UpdateUserParams,
  UpdateUserSettingsParams,
  User,
  UserSettings,
} from "../redux/users";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  WaffleFlagNames,
  WaffleFlagObj,
  WaffleSwitch,
  WaffleSwitchNames,
} from "../redux/waffle";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  CreateWebhookParams,
  DeleteWebhookParams,
  GetWebhookGraphQLQueryParams,
  TestWebhookParams,
  UpdateWebhookActiveParams,
  UpdateWebhookAddressParams,
  UpdateWebhookNftFiltersParams,
  UpdateWebhookNftMetadataFiltersParams,
  Webhook,
  WebhookExternal,
  WebhookGraphQLQuery,
} from "../redux/webhooks";
import { SubscriptionTier } from "../util/constants";
import { Method, callEndpoint } from "./axiosConfig";
import {
  GetOnboardingStatusResponse,
  GetPlanCostPreviewRequest,
  GetPlanCostPreviewResponse,
  GetPlanUpdateCostPreviewRequest,
  GetPlanUpdateCostPreviewResponse,
} from "./types";

export function getUser(): Promise<User> {
  return callEndpoint(Method.GET, "/api/user");
}

export function getUserIdForApp(
  params: AppIdParams,
): Promise<string | undefined> {
  return callEndpoint(Method.GET, "/api/staff/user-id-for-app", params);
}

export function updateUser(params: UpdateUserParams): Promise<User> {
  return callEndpoint(Method.PUT, "/api/update-user", params);
}

export function getUserSettings(): Promise<UserSettings> {
  return callEndpoint(Method.GET, "/api/user-settings");
}

export function updateUserSettings(
  params: UpdateUserSettingsParams,
): Promise<UserSettings> {
  return callEndpoint(Method.PUT, "/api/update-user-settings", params);
}

export function getGlobalSettings(): Promise<GlobalSettings> {
  return callEndpoint(Method.GET, "/api/global-settings");
}

export function getTeam(): Promise<Team> {
  return callEndpoint(Method.GET, "/api/team");
}

export function getTeamFcuUsageStats(): Promise<FcuUsageStats> {
  return callEndpoint(Method.GET, "/api/team-fcu-usage");
}

export function updateSetupStage(
  params: UpdateSetupStageParams,
): Promise<Team> {
  return callEndpoint(Method.PUT, "/api/update-setup-stage", params);
}

export function updateRateLimitSettings(
  params: UpdateRateLimitSettingsParams,
): Promise<TeamAndUserSettings> {
  return callEndpoint(Method.PUT, "/api/update-rate-limit-settings", params);
}

export function getTeamMembers(): Promise<User[]> {
  return callEndpoint(Method.GET, "/api/team-members");
}

export function getReferralMonthlyBonus(): Promise<ReferralMonthlyBonus> {
  return callEndpoint(Method.GET, "/api/referral-monthly-bonus");
}

export function getReferralSocialBonuses(): Promise<ReferralSocialBonuses> {
  return callEndpoint(Method.GET, "/api/referral-social-bonuses");
}

export function earnReferralSocialBonus(
  params: EarnSocialBonusParams,
): Promise<void> {
  return callEndpoint(Method.POST, "/api/referral-earn-social-bonus", params);
}

export function createAndInviteTeamMembers(
  params: CreateAndInviteTeamMembersParams,
): Promise<void> {
  return callEndpoint(
    Method.POST,
    "/api/create-and-invite-team-members",
    params,
  );
}

export function getPaymentProfile(): Promise<CreditCardInfo | undefined> {
  return callEndpoint<CreditCardInfo | null>(
    Method.GET,
    "/api/payment-profile",
  ).then(nullToUndefined);
}

export function setPaymentProfile(
  params: CreatePaymentProfileParams,
): Promise<boolean> {
  return callEndpoint(Method.POST, "/api/set-payment-profile", params);
}

export function getBillingDetails(): Promise<BillingDetails | undefined> {
  return callEndpoint<BillingDetails | null>(
    Method.POST,
    "/api/billing-details",
  ).then(nullToUndefined);
}

export function updateBillingDetails(
  params: UpdateBillingDetailsParams,
): Promise<void> {
  return callEndpoint(Method.POST, "/api/update-billing-details", params);
}

export function getOpenInvoices(): Promise<OpenInvoice[]> {
  return callEndpoint(Method.GET, "/api/open-invoices");
}

export function getInvoices(): Promise<Invoice[]> {
  return callEndpoint(Method.GET, "/api/invoices");
}

export async function getTeamApps(): Promise<App[]> {
  return callEndpoint(Method.GET, "/api/team-apps");
}

export function createApp(params: CreateAppParams): Promise<App> {
  return callEndpoint(Method.POST, "/api/create-app", params);
}

export function updateApp(params: UpdateAppParams): Promise<App> {
  return callEndpoint(Method.PUT, "/api/update-app", params);
}

export function requestUpgrade(params: RequestUpgradeParams): Promise<void> {
  return callEndpoint(Method.POST, "/api/request-upgrade", params);
}

interface GetAppMedianResponsesResponse {
  id: string;
  median_response_5m: number;
}

export function getAppMedianResponses(
  params?: Partial<AppIdParams>,
): Promise<GetAppMedianResponsesResponse[]> {
  return callEndpoint(Method.GET, "/api/app-median-response", params);
}

interface GetTeamQueryCountResponse {
  num_queries: number;
}

export interface FreshneshParams {
  fresh?: boolean;
}

export function getTeamQueryCount({
  fresh,
}: FreshneshParams): Promise<GetTeamQueryCountResponse> {
  return callEndpoint(
    Method.GET,
    "/api/team-query-count",
    fresh ? { fresh } : {},
  );
}

export interface GetTeamQueryTimeSeriesParams {
  request_type?: RequestType;
  start_date: number;
  end_date: number;
  granularity: string;
}

export type GetTeamQueryTimeSeriesResponse = Record<
  string | number,
  TimeSeriesPoint[]
>;

export function getTeamQueryTimeSeries(
  params: GetTeamQueryTimeSeriesParams,
): Promise<GetTeamQueryTimeSeriesResponse> {
  return callEndpoint(Method.GET, "/api/team-query-ts", params);
}

export enum RequestType {
  Success = "SUCCESS",
  Error = "ERROR",
  RateLimited = "RATE_LIMITED",
  Failed = "FAILED", // Error + RateLimited
  All = "ALL",
}

type GetRequestTimeseriesByTeamParams = {
  start_date: number; // unix timestamp millis
  end_date: number; // unix timestamp millis
  granularity: "day" | "hour" | "minute";
  request_type: RequestType;
};

export type GetRequestTimeseriesByTeamResponse = TimeSeriesPoint[];

export function getRequestTimeseriesByTeam(
  params: GetRequestTimeseriesByTeamParams,
): Promise<GetRequestTimeseriesByTeamResponse> {
  return callEndpoint(Method.GET, "/api/request-timeseries-by-team", params);
}

export interface GetTeamRateLimitedTimeSeriesParams {
  start_date: number;
  end_date: number;
  granularity: string;
}

export type GetTeamRateLimitedTimeSeriesResponse = Record<
  number | string,
  TimeSeriesPoint[]
>;

export function getTeamRateLimitTimeSeries(
  params: GetTeamRateLimitedTimeSeriesParams,
): Promise<GetTeamRateLimitedTimeSeriesResponse> {
  return callEndpoint(Method.GET, "/api/team-rate-limit-ts", params);
}

export function getAppRequests(params: AppIdParams): Promise<AppRequest[]> {
  return callEndpoint(Method.GET, "/api/app-requests", params);
}

export function getAppErrorRequests(
  params: AppIdParams,
): Promise<AppRequest[]> {
  return callEndpoint(Method.GET, "/api/app-error-requests", params);
}

export function getAppRateLimitedRequests(
  params: AppIdParams,
): Promise<AppRequest[]> {
  return callEndpoint(Method.GET, "/api/app-rate-limited-requests", params);
}

export function getRequestEthMethods(): Promise<string[]> {
  return callEndpoint(Method.GET, "/api/request-eth-methods");
}

export function getTeamPlan(): Promise<TeamPlan> {
  return callEndpoint(Method.GET, "/api/team-plan");
}

export function putPlanPreference(
  params: UpdatePlanPreferenceParams,
): Promise<TeamPlan> {
  return callEndpoint(Method.PUT, "/api/update-team-plan", params);
}

export function getTeamMonthlyPlan(
  params: UsageParams,
): Promise<TeamMonthlyPlan> {
  return callEndpoint(Method.GET, "/api/team-monthly-plan", params);
}

export function getComputeUnitTimeSeriesByApp(
  params: UsageParams,
): Promise<Record<number, UsageTimeSeriesPoint[]>> {
  return callEndpoint(Method.GET, "/api/compute-unit-ts-by-app", params);
}

export function getRecentComputeUnitTimeSeriesByApp(): Promise<
  Record<number, UsageTimeSeriesPoint[]>
> {
  return callEndpoint(Method.GET, "/api/recent-compute-unit-ts-by-app");
}

export function getComputeUnitsByAppByMethod(
  params: UsageParams,
): Promise<Record<string, Record<number, EthMethodComputeStat>>> {
  return callEndpoint(
    Method.GET,
    "/api/compute-units-by-app-by-method",
    params,
  );
}

export function getFilteredTransactions(
  params: FilteredTransactionParams,
): Promise<MempoolTransactionsBlob> {
  return callEndpoint(Method.GET, "/api/filtered-transactions", params);
}

export function getNumFilteredTransactions(
  params: FilteredTransactionParams,
): Promise<number> {
  return callEndpoint(Method.GET, "/api/num-filtered-transactions", params);
}

export function getMempoolTransaction(
  params: TransactionParams,
): Promise<MempoolTransaction> {
  return callEndpoint(Method.GET, "/api/transaction", params);
}

export function getSatsumaJWTRedirect(): Promise<{ redirectTo: string }> {
  return callEndpoint(Method.GET, "/api/satsuma-jwt");
}

export async function getTeamWebhooks(): Promise<Webhook[]> {
  const webhooks = await callEndpoint<WebhookExternal[]>(
    Method.GET,
    "/api/dashboard-team-webhooks",
  );
  return webhooks.map((webhook) => {
    return {
      ...webhook,
      webhook_type: WebhookType[webhook.webhook_type],
    };
  });
}

export function createWebhook(params: CreateWebhookParams): Promise<Webhook> {
  return callEndpoint(Method.POST, "/api/create-webhook", params);
}

export function deleteWebhook(params: DeleteWebhookParams): Promise<void> {
  return callEndpoint(Method.DELETE, "/api/delete-webhook", params);
}

export function getWebhookGraphQLQuery(
  params: GetWebhookGraphQLQueryParams,
): Promise<WebhookGraphQLQuery> {
  return callEndpoint(
    Method.GET,
    "/api/dashboard-webhook-graphql-query",
    params,
  );
}

export function updateWebhookActive(
  params: UpdateWebhookActiveParams,
): Promise<void> {
  return callEndpoint(Method.PUT, "/api/update-webhook", params);
}

export function updateWebhookAddress(
  params: UpdateWebhookAddressParams,
): Promise<void> {
  return callEndpoint(Method.PATCH, "/api/update-webhook-addresses", params);
}

export function updateWebhookNftFilters(
  params: UpdateWebhookNftFiltersParams,
): Promise<void> {
  return callEndpoint(Method.PATCH, "/api/update-webhook-nft-filters", params);
}

export function updateWebhookNftMetadataFilters(
  params: UpdateWebhookNftMetadataFiltersParams,
): Promise<void> {
  return callEndpoint(
    Method.PATCH,
    "/api/update-webhook-nft-metadata-filters",
    params,
  );
}

export function testWebhook(params: TestWebhookParams): Promise<void> {
  return callEndpoint(Method.POST, "/api/test-webhook", params);
}

interface SubmitFeedbackParams {
  prompt: string;
  feedback: string;
}

export function submitFeedback(params: SubmitFeedbackParams): Promise<void> {
  return callEndpoint(Method.POST, "/api/feedback", params);
}

export function submitTierChangeReason(params: {
  reason: string;
  new_tier: SubscriptionTier;
  prev_tier: SubscriptionTier;
}): Promise<void> {
  return callEndpoint(Method.POST, "/api/submit-tier-change-reason", params);
}

function nullToUndefined<T>(x: T | null): T | undefined {
  return x === null ? undefined : x;
}

export function deleteUser(params: TeamMemberIdParams): Promise<void> {
  return callEndpoint(Method.POST, `/api/delete-user`, params);
}

export function makeUserAdmin(params: TeamMemberIdParams): Promise<void> {
  return callEndpoint(Method.POST, `/api/make-user-admin`, params);
}

export function removeUserAdmin(params: TeamMemberIdParams): Promise<void> {
  return callEndpoint(Method.POST, `/api/remove-user-admin`, params);
}

export function getWaffleFlags(
  params: WaffleFlagNames,
): Promise<WaffleFlagObj[]> {
  return callEndpoint(Method.GET, "/api/waffle/flags", params);
}

export function getWaffleSwitches(
  params: WaffleSwitchNames,
): Promise<WaffleSwitch[]> {
  return callEndpoint(Method.GET, "/api/waffle/switches", params);
}

export function getSupportLink(): Promise<{ support_link: string }> {
  return callEndpoint(Method.GET, "/api/support_link");
}

export function getGasPolicy(id: string): Promise<Policy> {
  return callEndpoint<{ policy: Policy }>(
    Method.GET,
    `/api/gasManager/policy/${id}`,
  ).then((res) => {
    return formatPolicyUndefinedsForNulls(res.policy);
  });
}

export function getGasPolicies(
  limit: number,
  before: string | null,
  after: string | null,
): Promise<GetPoliciesResponse> {
  return callEndpoint<GetPoliciesResponse>(
    Method.GET,
    `/api/gasManager/policies?limit=${limit}` +
      (before != null ? `&before=${before}` : "") +
      (after != null ? `&after=${after}` : ""),
  ).then((res) => {
    return {
      ...res,
      policies: res.policies.map(formatPolicyUndefinedsForNulls),
    };
  });
}

export function getGasPoliciesTeamLimits(): Promise<GasPoliciesTeamLimits> {
  return callEndpoint<GasPoliciesTeamLimits>(
    Method.GET,
    "/api/gasManager/team/limits",
  );
}

export function createPredeposit(amount: number): Promise<boolean> {
  return callEndpoint<boolean>(Method.POST, "/api/gasManager/predeposit", {
    amount: amount,
  });
}

export function createGasPolicy(params: PolicyCreationParams): Promise<Policy> {
  return callEndpoint<{ policy: Policy }>(
    Method.POST,
    "/api/gasManager/policy/create",
    params,
  ).then((res) => formatPolicyUndefinedsForNulls(res.policy));
}

export function updateGasPolicyStatus(
  params: PolicyStatusUpdateParams,
): Promise<Policy> {
  return callEndpoint<{ policy: Policy }>(
    Method.PUT,
    `/api/gasManager/policy/${params.policyId}/update-status`,
    params,
  ).then((res) => formatPolicyUndefinedsForNulls(res.policy));
}

export function updateGasPolicy(
  policyId: string,
  params: PolicyUpdateParams,
): Promise<Policy> {
  return callEndpoint<{ policy: Policy }>(
    Method.PUT,
    `/api/gasManager/policy/${policyId}/update`,
    params,
  ).then((res) => formatPolicyUndefinedsForNulls(res.policy));
}

export function deleteGasPolicy(policyId: string): Promise<void> {
  return callEndpoint(
    Method.DELETE,
    `/api/gasManager/policy/${policyId}/delete`,
  );
}

export function getPolicyStats(policyId: string): Promise<PolicyStats> {
  return callEndpoint(Method.GET, `/api/gasManager/policy/${policyId}/stats`);
}

export function getPolicySponsorships(
  policy_id: string,
  limit: number,
  before: string | null,
  after: string | null,
): Promise<GetPolicySponsorshipResponse> {
  const path = `/api/gasManager/policy/${policy_id}/sponsorships?limit=${limit}`;
  if (before) {
    return callEndpoint<GetPolicySponsorshipResponse>(
      Method.GET,
      path + `&before=${before}`,
    );
  } else if (after) {
    return callEndpoint<GetPolicySponsorshipResponse>(
      Method.GET,
      path + `&after=${after}`,
    );
  } else {
    return callEndpoint<GetPolicySponsorshipResponse>(Method.GET, path);
  }
}

export function getTeamSignatureStats(): Promise<GetTeamSignatureStatsResponse> {
  return callEndpoint(Method.GET, "/api/signer_read/signature-stats");
}

export function getAccountsTosInfo(): Promise<GetAccountsTosInfoResponse> {
  return callEndpoint(Method.GET, "/api/signer_read/tos");
}

export function postAccountsTos(): Promise<PostAccountsTosResponse> {
  return callEndpoint(Method.POST, "/api/signer_write/tos", {});
}

export function getAccountsProjectConfigs(): Promise<GetAccountsProjectConfigResponse> {
  return callEndpoint(Method.GET, "/api/signer_read/config");
}

export function postAccountsProjectConfig(
  params: PostAccountsProjectConfigRequest,
): Promise<PostAccountsProjectConfigResponse> {
  return callEndpoint(Method.POST, "/api/signer_write/config", params);
}

export function putAccountsProjectConfig(
  params: PutAccountsProjectConfigRequest,
): Promise<PutAccountsProjectConfigResponse> {
  return callEndpoint(Method.PUT, "/api/signer_write/config", params);
}

export function postImageUrl(
  params: PostImageUrlRequest,
): Promise<PostImageUrlResponse> {
  return callEndpoint(
    Method.POST,
    "/api/signer_write/upload-image-url",
    params,
  );
}

export function setupEmbeddedAccounts(): Promise<SetupEmbeddedAccountsResponse> {
  return callEndpoint(Method.POST, "/api/embeddedAccounts/setup");
}

export function getPermissions(): Promise<GetPermissionsResponse> {
  return callEndpoint(Method.POST, `/api/authManager/permissions`);
}

export function getAccessKeys(
  limit: number,
  after: string | null,
): Promise<GetAccessKeysResponse> {
  return callEndpoint(
    Method.POST,
    `/api/authManager/accessKeys?limit=${limit}` +
      (after != null ? `&after=${after}` : ""),
  );
}

export function createAccessKey(
  params: CreateAccessKeyRequestParams,
): Promise<CreateAccessKeyResponse> {
  return callEndpoint(Method.POST, `/api/authManager/accessKey`, params);
}

export function deleteAccessKey(
  accessKeyId: string,
): Promise<DeleteAccessKeyResponse> {
  return callEndpoint(
    Method.DELETE,
    `/api/authManager/accessKey/${accessKeyId}/delete`,
  );
}

export function getPublicKeys(
  limit: number,
  after: string | null,
): Promise<GetPublicKeysResponse> {
  return callEndpoint(
    Method.POST,
    `/api/authManager/publicKeys?limit=${limit}` +
      (after != null ? `&after=${after}` : ""),
  );
}

export function createPublicKey(
  params: CreatePublicKeyRequestParams,
): Promise<CreatePublicKeyResponse> {
  return callEndpoint(Method.POST, `/api/authManager/publicKey`, params);
}

export function deletePublicKey(
  publicKeyId: string,
): Promise<DeletePublicKeyResponse> {
  return callEndpoint(
    Method.DELETE,
    `/api/authManager/publicKey/${publicKeyId}/delete`,
  );
}

export function debugJwt(
  params: DebugJwtRequestParams,
): Promise<DebugJwtResponse> {
  return callEndpoint(Method.POST, `/api/authManager/debugJwt`, params);
}

export function getPlanCostPreview(
  params: GetPlanCostPreviewRequest,
): Promise<GetPlanCostPreviewResponse> {
  return callEndpoint(Method.POST, `/api/plan-cost-preview`, params);
}

export function getPlanUpdateCostPreview(
  params: GetPlanUpdateCostPreviewRequest,
): Promise<GetPlanUpdateCostPreviewResponse> {
  return callEndpoint(Method.POST, `/api/plan-update-cost-preview`, params);
}

export function checkCreditCard(
  params: CheckCreditCardParams,
): Promise<Record<string, never>> {
  return callEndpoint(Method.POST, `/api/check-credit-card`, params);
}

export function completeSignup(
  params: CompleteSignupRequestParams,
): Promise<void> {
  return callEndpoint(Method.POST, `/api/complete-signup`, params);
}

export function getOnboardingStatus(): Promise<GetOnboardingStatusResponse> {
  return callEndpoint(Method.GET, "/api/onboarding-status");
}

import { Calendar01, Calendar04 } from "@alch/ui/icons/16";
import { PlanSelection } from "@features/signup/Pages/PlanPage";
import {
  PlanTerm,
  SUBSCRIPTION_TIER_LABELS,
  SubscriptionTier,
} from "@util/constants";
import clsx from "clsx";

const getTermLabel = (term: PlanTerm) => {
  switch (term) {
    case PlanTerm.MONTHLY:
      return "Monthly";
    case PlanTerm.ANNUAL:
      return "Yearly";
  }
};

const getTermIcon = (term: PlanTerm) => {
  switch (term) {
    case PlanTerm.MONTHLY:
      return <Calendar01 />;
    case PlanTerm.ANNUAL:
      return <Calendar04 />;
  }
};

interface ScaleTermSelectionTabProps {
  term: PlanTerm;
  onClick?: (planTerm: PlanTerm) => void;
  isSelected: boolean;
  isActive: boolean;
}

const ScaleTermSelectionTab = ({
  term,
  onClick,
  isSelected,
  isActive,
}: ScaleTermSelectionTabProps) => {
  return (
    <div
      className={clsx(
        "flex w-full items-center justify-center bg-grayscale-100 p-2 text-paragraph-100-regular",
        isActive && "bg-white",
        isSelected && "text-paragraph-100-medium text-blue-600",
        term === PlanTerm.MONTHLY && "rounded-tl-[24px]",
        term === PlanTerm.ANNUAL && "rounded-tr-[24px]",
      )}
      onClick={() => {
        onClick?.(term);
      }}
    >
      {getTermIcon(term)}
      <div className="w-1" />
      {getTermLabel(term)}
    </div>
  );
};

const getPriceLabel = (
  tier: SubscriptionTier,
  planTermSelected: PlanTerm,
  monthlyPrice: string,
  yearMonthlyPrice?: string,
) => {
  if (
    tier !== SubscriptionTier.SCALE ||
    (tier === SubscriptionTier.SCALE &&
      planTermSelected === PlanTerm.MONTHLY) ||
    !yearMonthlyPrice
  ) {
    return monthlyPrice;
  }

  if (tier === SubscriptionTier.SCALE && planTermSelected === PlanTerm.ANNUAL) {
    return yearMonthlyPrice;
  }

  return null;
};

interface PlanSelectionCardProps {
  plan: PlanSelection;
  onClick: () => void;
  isSelected: boolean;
  onPlanTermSelected?: (planTerm: PlanTerm) => void;
  planTermSelected: PlanTerm;
}

const PlanSelectionCard = ({
  plan: {
    subtitle,
    tier,
    monthlyPrice,
    valueProps,
    poweringTeamsLogos,
    yearMonthlyPrice,
  },
  onClick,
  onPlanTermSelected,
  planTermSelected,
  isSelected,
}: PlanSelectionCardProps) => {
  return (
    <div
      className={clsx(
        "cursor-pointer rounded-[24px]",
        isSelected
          ? "m-0 border-[3px] border-blue-400"
          : "m-[2px] border border-grayscale-200",
      )}
      onClick={onClick}
    >
      {tier === SubscriptionTier.SCALE && (
        <div className="flex">
          <ScaleTermSelectionTab
            term={PlanTerm.MONTHLY}
            onClick={onPlanTermSelected}
            isActive={planTermSelected === PlanTerm.MONTHLY}
            isSelected={isSelected && planTermSelected === PlanTerm.MONTHLY}
          />
          <div className="border-l-DEFAULT border-grayscale-200"></div>
          <ScaleTermSelectionTab
            term={PlanTerm.ANNUAL}
            onClick={onPlanTermSelected}
            isActive={planTermSelected === PlanTerm.ANNUAL}
            isSelected={isSelected && planTermSelected === PlanTerm.ANNUAL}
          />
        </div>
      )}
      <div
        className={clsx(
          " bg-white px-6 pt-6",
          tier !== SubscriptionTier.SCALE && "rounded-t-[24px]",
          tier === SubscriptionTier.SCALE &&
            "border-t-DEFAULT border-grayscale-200",
        )}
      >
        <div
          className={clsx(
            "text-heading-h2 text-grayscale-950",
            isSelected && "text-blue-600",
          )}
        >
          {SUBSCRIPTION_TIER_LABELS[tier]}
        </div>
        <div className="h-1"></div>
        <div className="text-paragraph-200-regular text-grayscale-500">
          {subtitle}
        </div>
        <div className="h-5" />
        <div className="flex items-end">
          <div
            className={clsx(
              "text-[2.5rem] font-semibold leading-tight text-grayscale-950",
              isSelected && "text-blue-600",
            )}
          >
            {getPriceLabel(
              tier,
              planTermSelected,
              monthlyPrice,
              yearMonthlyPrice,
            )}
          </div>
          {tier != SubscriptionTier.ENTERPRISE && (
            <div className="ml-1 text-heading-h4 text-grayscale-500">/mo</div>
          )}
        </div>
        <div
          className={clsx(
            "grid grid-rows-2 gap-y-1 py-5",
            isSelected && "text-blue-600",
          )}
        >
          {valueProps.map((valueProp) => valueProp)}
        </div>
      </div>
      <div className="rounded-b-[24px] bg-grayscale-50 px-6 pb-6">
        <div className="py-4 text-paragraph-200-regular text-grayscale-500">
          Powering teams like
        </div>
        <div className="flex">
          {poweringTeamsLogos.map((logoUrl, index) => (
            <img key={index} src={logoUrl} style={{ width: 90, height: 28 }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanSelectionCard;

import { combineReducers, Reducer } from "redux";
import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerForIndexedLoadables,
  reducerForLoadable,
} from "../util/loadable";
import { TimeSeriesPoint } from "./appStats";
import { TeamMonthlyPlan } from "./team";

export interface UsageByType {
  jsonrpc: number;
  subscription: number;
  webhook: number;
}

export interface UsageTimeSeriesPoint extends TimeSeriesPoint {
  value_by_type?: UsageByType;
}

export interface UsageState {
  computeUnitTimeSeriesByAppByMonthsAgo: Record<
    number,
    Loadable<Record<number, UsageTimeSeriesPoint[]>>
  >;
  recentComputeUnitTimeSeriesByApp: Loadable<
    Record<number, UsageTimeSeriesPoint[]>
  >;
  computeUnitByAppByMethodByMonthsAgo: Record<
    number,
    Loadable<Record<string, Record<number, EthMethodComputeStat>>>
  >;
  compareType: UsageCompareType;
  selectedAppIds: string[];
  selectedMonthsAgo: number[];
  teamMonthlyPlansByMonthsAgo: Record<number, Loadable<TeamMonthlyPlan>>;
}

export interface EthMethodComputeStat {
  count: number;
  compute_units: number;
}

export interface UsageParams {
  months_ago?: number;
}

export interface UsageDaysParams {
  days_ago?: number;
}

export enum UsageCompareType {
  APPS = "apps",
  MONTHS = "months",
}

const actionCreator = actionCreatorFactory();

const requestTeamMonthlyPlanByMonthsAgo = makeLoadingActionCreators<
  UsageParams,
  TeamMonthlyPlan
>("REQUEST_TEAM_MONTHLY_PLAN_BY_MONTHS_AGO");

const requestComputeUnitTimeSeriesByApp = makeLoadingActionCreators<
  UsageParams,
  Record<number, UsageTimeSeriesPoint[]>
>("REQUEST_COMPUTE_UNIT_TIME_SERIES_BY_APP");

const requestRecentComputeUnitTimeSeriesByApp = makeLoadingActionCreators<
  void,
  Record<number, UsageTimeSeriesPoint[]>
>("REQUEST_RECENT_COMPUTE_UNIT_TIME_SERIES_BY_APP");

const requestComputeUnitByAppByMethod = makeLoadingActionCreators<
  UsageParams,
  Record<string, Record<number, EthMethodComputeStat>>
>("REQUEST_COMPUTE_UNIT_BY_APP_BY_METHOD");

export const fetchTeamMonthlyPlanByMonthsAgo = makeFetchThunkActionCreator<
  UsageParams,
  TeamMonthlyPlan
>({
  actionCreators: requestTeamMonthlyPlanByMonthsAgo,
  getFromState: (state, { months_ago = 0 }) =>
    state.usage.teamMonthlyPlansByMonthsAgo[months_ago] || Loadable.unloaded(),
  fetchResult: (params) => http.getTeamMonthlyPlan(params),
});

export const fetchComputeUnitTimeSeriesByApp = makeFetchThunkActionCreator<
  UsageParams,
  Record<number, UsageTimeSeriesPoint[]>
>({
  actionCreators: requestComputeUnitTimeSeriesByApp,
  getFromState: (state, { months_ago = 0 }) =>
    state.usage.computeUnitTimeSeriesByAppByMonthsAgo[months_ago] ||
    Loadable.unloaded(),
  fetchResult: (params) => http.getComputeUnitTimeSeriesByApp(params),
});

export const fetchRecentComputeUnitTimeSeriesByApp =
  makeFetchThunkActionCreator<void, Record<number, UsageTimeSeriesPoint[]>>({
    actionCreators: requestRecentComputeUnitTimeSeriesByApp,
    getFromState: (state) => state.usage.recentComputeUnitTimeSeriesByApp,
    fetchResult: http.getRecentComputeUnitTimeSeriesByApp,
  });

export const fetchComputeUnitByAppByMethod = makeFetchThunkActionCreator<
  UsageParams,
  Record<string, Record<number, EthMethodComputeStat>>
>({
  actionCreators: requestComputeUnitByAppByMethod,
  getFromState: (state, { months_ago = 0 }) =>
    state.usage.computeUnitByAppByMethodByMonthsAgo[months_ago] ||
    Loadable.unloaded(),
  fetchResult: (params) => http.getComputeUnitsByAppByMethod(params),
});

export const setUsageCompareType = actionCreator<UsageCompareType>(
  "SET_USAGE_COMPARE_TYPE",
);
export const setUsageSelectedAppIds = actionCreator<string[]>(
  "SET_USAGE_SELECTED_APP_IDS",
);
export const setUsageSelectedMonthsAgo = actionCreator<number[]>(
  "SET_USAGE_SELECTED_MONTHS_AGO",
);

export const usageReducer: Reducer<UsageState> = combineReducers({
  teamMonthlyPlansByMonthsAgo: reducerForIndexedLoadables(
    requestTeamMonthlyPlanByMonthsAgo,
    (params) => params.months_ago || 0,
  ),
  computeUnitTimeSeriesByAppByMonthsAgo: reducerForIndexedLoadables(
    requestComputeUnitTimeSeriesByApp,
    (params) => params.months_ago || 0,
  ),
  recentComputeUnitTimeSeriesByApp: reducerForLoadable(
    requestRecentComputeUnitTimeSeriesByApp,
  ),
  computeUnitByAppByMethodByMonthsAgo: reducerForIndexedLoadables(
    requestComputeUnitByAppByMethod,
    (params) => params.months_ago || 0,
  ),
  compareType: reducerWithInitialState(UsageCompareType.APPS)
    .case(setUsageCompareType, (_, compareType) => compareType)
    .build(),
  selectedAppIds: reducerWithInitialState<string[]>([])
    .case(setUsageSelectedAppIds, (_, selectedAppIds) => selectedAppIds)
    .build(),
  selectedMonthsAgo: reducerWithInitialState<number[]>([])
    .case(setUsageSelectedMonthsAgo, (_, selectedMonthsAgo) =>
      sortedNumerically(selectedMonthsAgo),
    )
    .build(),
});

function sortedNumerically(arr: number[]): number[] {
  return arr.slice().sort((a, b) => a - b);
}

import { useEffect } from "react";
import { DEPLOY_ENV } from "../util/deployEnv";
import getCookie from "../util/getCookie";
import {
  AFFILIATE_KEY,
  AFFILIATE_PREFIX,
  PROMO_CODE_KEY,
  REF_KEY,
  REFERRAL_KEY,
  REFERRER_ORIGIN_KEY,
} from "../util/getTrackingValues";

const cookieDomain: string[] = {
  production: [".alchemyapi.io", ".alchemy.com"],
  staging: [".alchemypreview.com"],
  development: ["localhost"],
}[DEPLOY_ENV];
/**
 * Temporary workaround while dashboard and the homepage are on different domains
 * to allow the promo, referral and tracking codes to be passed to dashboard
 * via query string params.
 *
 * Once they are on the same domain, we can just use the shared cookies.
 */
export default function useTrackingQueryParams(): void {
  useEffect(() => {
    // TODO remove this once alchemy has finished domain name transition
    // HACK: sets cookies based on query params for the sign up app
    if (typeof window !== "undefined") {
      const getQueryStringValue = (key: string): string | null => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
      };

      // We use `ref` instead of `referral` since referral causes redirects to the marketing page
      const cookiesToTrack = [
        REFERRER_ORIGIN_KEY,
        PROMO_CODE_KEY,
        REF_KEY,
        AFFILIATE_KEY,
      ];
      for (let key of cookiesToTrack) {
        let value = getQueryStringValue(key);
        // Another hack to ensure that we are doing first touch referral tracking such that the referral cookie
        // keys are consistent with alchemy.com
        if (key === REF_KEY) {
          key = REFERRAL_KEY;
        }
        // Only save if this cookie doesn't already exist
        if (!getCookie(key) && value != null) {
          // Hack to preserve mappings of a -> to affiliate links for pretty urls
          if (key === AFFILIATE_KEY) {
            key = REFERRAL_KEY;
            value = `${AFFILIATE_PREFIX}:${value}`;
          }
          for (const domain of cookieDomain) {
            document.cookie = `${key}=${value};domain=${domain};path=/`;
          }
        }
      }
    }
  }, []);
}

import { ChainId, ProjectType, TeamSize, TeamType } from "@alch/dx-entities";
import {
  SignupFormFieldName,
  SignupSchemaField,
  UnsupportedChain,
} from "@features/signup/constants";
import { PlanTerm, SubscriptionTier } from "@util/constants";
import { fieldValidation } from "@util/fieldValidation";
import { z } from "zod";

const MAX_PROJECT_TYPE_LENGTH = 20;

const emailListValidator = (emails?: string) => {
  if (!emails) return true;

  return emails.split(",").every((email) => {
    const { success } = z.string().email().safeParse(email.trim());

    return success;
  });
};

export const teamFormSchema = z.object({
  [SignupFormFieldName.TeamType]: z.literal(TeamType.Team),
  [SignupFormFieldName.TeamName]: z
    .string()
    .trim()
    .nonempty(fieldValidation.required("Organization name")),
  [SignupFormFieldName.TeamSize]: z.nativeEnum(TeamSize),
  [SignupFormFieldName.TeamEmailsToInvite]: z
    .string()
    .optional()
    .refine(
      emailListValidator,
      "Emails must be valid and separated by commas.",
    ),
});

export const personalFormSchema = z.object({
  [SignupFormFieldName.TeamType]: z.literal(TeamType.Personal),
  [SignupFormFieldName.ProjectName]: z
    .string()
    .trim()
    .nonempty(fieldValidation.required("Project name")),
});

export const teamAndPersonalFormSchema = z.discriminatedUnion(
  SignupFormFieldName.TeamType,
  [teamFormSchema, personalFormSchema],
);

export const projectFormSchema = z.union([
  z.object({
    [SignupFormFieldName.ProjectType]: z.nativeEnum(ProjectType),
  }),
  z.object({
    [SignupFormFieldName.OtherProjectType]: z
      .string()
      .max(
        MAX_PROJECT_TYPE_LENGTH,
        fieldValidation.maxLength("Project type", MAX_PROJECT_TYPE_LENGTH),
      )
      .nonempty(fieldValidation.required("Project")),
  }),
]);

export const chainFormSchema = z.object({
  [SignupFormFieldName.Chains]: z
    .array(z.string().transform((chain) => chain as ChainId))
    .nonempty(),
  [SignupFormFieldName.OtherChains]: z
    .array(z.nativeEnum(UnsupportedChain))
    .optional(),
});

export const planFormSchema = z.object({
  [SignupFormFieldName.PlanType]: z.nativeEnum(SubscriptionTier),
  [SignupFormFieldName.PlanTerm]: z.nativeEnum(PlanTerm),
});

export const enterpriseDetailsFormSchema = z.object({
  [SignupFormFieldName.EnterpriseDetails]: z
    .string()
    .nonempty(fieldValidation.required("Project details")),
});

const paymentFormSchema = z.object({
  [SignupFormFieldName.ChargifyToken]: z.string().nonempty().optional(),
});

export const billingInfoSchema = z.object({
  [SignupFormFieldName.Address]: z
    .string()
    .nonempty(fieldValidation.required("Address")),
  [SignupFormFieldName.City]: z
    .string()
    .nonempty(fieldValidation.required("City")),
  [SignupFormFieldName.Country]: z
    .string()
    .nonempty(fieldValidation.required("Country")),
  [SignupFormFieldName.State]: z
    .string()
    .nonempty(fieldValidation.required("State")),
  [SignupFormFieldName.ZipCode]: z
    .string()
    .nonempty(fieldValidation.required("Zip code")),
});

const referrerFormSchema = z.object({
  [SignupFormFieldName.Referrer]: z.string().optional(),
});

export const schemaFieldToSchemaMap = {
  [SignupSchemaField.Team]: teamAndPersonalFormSchema,
  [SignupSchemaField.Project]: projectFormSchema,
  [SignupSchemaField.Chain]: chainFormSchema,
  [SignupSchemaField.Plan]: planFormSchema,
  [SignupSchemaField.EnterpriseDetails]: enterpriseDetailsFormSchema,
  [SignupSchemaField.Payment]: paymentFormSchema,
  [SignupSchemaField.Billing]: billingInfoSchema,
  [SignupSchemaField.Referrer]: referrerFormSchema,
};

const signupFlowSchema = z.object(schemaFieldToSchemaMap);

export type SignupFormData = z.infer<typeof signupFlowSchema>;

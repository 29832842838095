import { ChainId } from "@alch/dx-entities";
import {
  CreatePipelineFailureResult,
  CreatePipelineRequestParams,
  CreatePipelineSuccessResult,
  Datasource,
  DesiredPipelineStatus,
  GetPipelineMetricsSuccessResult,
  ListWebhookEventsSuccessResult,
  Pipeline,
} from "@features/data-platform/types.ts";
import { getStoredAuthToken } from "@util/auth.ts";
import { DATA_PLATFORM_ORIGIN } from "@util/deployEnv.ts";
import { getViewAsId } from "@util/viewAs";

export const dataPlatformHTTP = {
  buildURL(path: string) {
    return `${DATA_PLATFORM_ORIGIN}/api/v1${path}`;
  },
  async authenticatedFetch(url: string, options: RequestInit = {}) {
    const viewAsParams = getViewAsId();
    if (!!viewAsParams && options.method !== "GET") {
      throw new Response("The view_as feature only works for GET requests", {
        status: 401,
      });
    }

    const fullURL = this.getUrlWithViewAs(this.buildURL(url));

    const sessionToken = getStoredAuthToken();

    if (!sessionToken) {
      throw new Response("Unable to retrieve session token.", { status: 401 });
    }

    const { headers, ...otherOptions } = options;

    return await fetch(fullURL, {
      headers: {
        ...headers,
        Authorization: `Bearer ${sessionToken}`,
      },
      ...otherOptions,
    });
  },
  async json<ResponseData>(
    url: string,
    options: RequestInit = {},
  ): Promise<ResponseData> {
    const result = await this.authenticatedFetch(url, options);
    return (await result.json()) as ResponseData;
  },

  getJSON<ResponseData>(
    url: string,
    options: RequestInit = {},
  ): Promise<ResponseData> {
    return this.json<ResponseData>(url, {
      ...options,
      method: "GET",
    });
  },
  putJSON<ResponseData>(
    url: string,
    data: object,
    options: RequestInit = {},
  ): Promise<ResponseData> {
    return this.json<ResponseData>(url, {
      ...options,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
  },
  postJSON<ResponseData>(
    url: string,
    data: object,
    options: RequestInit = {},
  ): Promise<ResponseData> {
    return this.json<ResponseData>(url, {
      ...options,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
  },

  /**
   * If the user is trying to view_as another user then we will add
   * query parameters to the back-end URL, otherwise do nothing.
   */
  getUrlWithViewAs(url: string): string {
    const viewAsParams = getViewAsId();
    if (!viewAsParams) return url;

    const baseURL = new URL(url);
    if (viewAsParams.view_as) {
      baseURL.searchParams.append("view_as", viewAsParams.view_as);
    }
    if (viewAsParams.view_as_team) {
      baseURL.searchParams.append("view_as_team", viewAsParams.view_as_team);
    }

    return baseURL.toString();
  },
};

export class DataPlatformAPI {
  static listPipelines(teamID: string | number) {
    return dataPlatformHTTP.getJSON<{ pipelines: Pipeline[] }>(
      `/pipeline/list/${teamID}`,
    );
  }

  static async listDatasources() {
    const body = await dataPlatformHTTP.getJSON<{
      datasources: Datasource[];
    }>("/datasource/list");

    return {
      ...body,
      datasources: body.datasources.map((datasource) => ({
        ...datasource,
        // Convert chain to uppercase to match the ChainId enum.
        // Dirty hack to get this working as easy as possible as we are getting rid of this soon
        chain: datasource.chain.toUpperCase() as ChainId,
      })),
    };
  }

  static createPipeline(data: CreatePipelineRequestParams) {
    return dataPlatformHTTP.postJSON<
      CreatePipelineSuccessResult | CreatePipelineFailureResult
    >("/pipeline/create", data);
  }

  static updateDesiredStatus(
    pipelineID: string | number,
    desiredStatus: DesiredPipelineStatus,
  ) {
    return dataPlatformHTTP.postJSON(`/pipeline/${pipelineID}/desired_status`, {
      desiredStatus,
      pipelineId: pipelineID,
    });
  }

  static getMetrics(pipelineID: string | number) {
    return dataPlatformHTTP.getJSON<GetPipelineMetricsSuccessResult>(
      `/pipeline/${pipelineID}/metrics`,
    );
  }

  static getEvents(pipelineID: string | number) {
    return dataPlatformHTTP.getJSON<ListWebhookEventsSuccessResult>(
      `/pipeline/${pipelineID}/events/list`,
    );
  }
}

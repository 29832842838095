import { logException } from "@util/errors";
import { dynamicScriptLoad } from "./utils";

type gTagFunction = (
  type: string,
  eventName: string | Date,
  eventParams?: { [key: string]: string },
) => void;

export function initGoogleAnalytics(debug: boolean) {
  if (!hasGaLoaded()) {
    dynamicScriptLoad(
      `https://www.googletagmanager.com/gtag/js?id=UA-116626517-5`,
    );
    // Initialize the dataLayer and gtag function
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (window as any).dataLayer = (window as any).dataLayer || [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (window as any).gtag = function () {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call,prefer-rest-params, @typescript-eslint/no-explicit-any
      (window as any).dataLayer.push(arguments);
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const gtag = (window as any).gtag as gTagFunction | undefined;
    if (hasGaLoaded() && gtag) {
      gtag("js", new Date());
      gtag("config", "UA-116626517-5");
      gtag("config", "AW-360844917");
    }
    if (debug) {
      console.debug(`[GoogleAnalytics] loaded`);
    }
  }
}

export function captureGoogleAnalyticsConversion(debug?: boolean) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const gtag = (window as any).gtag as gTagFunction | undefined;
  if (hasGaLoaded() && gtag) {
    try {
      gtag("event", "conversion", {
        send_to: "AW-360844917/WcUTCJO6ysQCEPWciKwB",
      });
    } catch (err) {
      logException(err);
    }
    if (debug) {
      console.debug("[GoogleAnalytics] conversion event");
    }
  }
}

function hasGaLoaded(): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const ga = (window as any).gtag as gTagFunction | undefined;
  return ga !== undefined;
}

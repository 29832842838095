import { SubscriptionTier } from "@util/constants";
import { IS_PRODUCTION } from "@util/deployEnv.ts";

type JsonValue = string | number | boolean | JsonValue[] | JsonObject;
type JsonObject = { [key: string]: JsonValue };

declare global {
  interface Window {
    sendinblue?: {
      identify(email: string, properties?: BrevoContact): void;
      page(pageName: string, properties?: PageProperties): void;
      trackLink(link: string, properties?: JsonObject): void;
      track(
        event_name: string,
        contact_properties?: object,
        event_properties?: { id?: string; data?: object },
      ): void;
    };
  }
}

export enum BrevoEventType {
  ACCEPTED_ACCOUNT_TOS = "accepted_embedded_accounts_tos",
  CREATED_EMBEDDED_ACCOUNT = "created_embedded_account_config",
  CREATED_GAS_POLICY = "created_gas_manager_policy",
}

const BREVO_SCRIPT_JS =
  '!function(){window.sib={equeue:[],client_key:"86pvl49jhqnk9xoqpuwwy305"},window.sendinblue={};for(var e=["track","identify","trackLink","page"],n=0;n<e.length;n++)!function(e){window.sendinblue[e]=function(){var n=Array.prototype.slice.call(arguments);(window.sib[e]||function(){var t={};t[e]=n,window.sib.equeue.push(t)})(n[0],n[1],n[2],n[3])}}(e[n]);var t=document.createElement("script"),n=document.getElementsByTagName("script")[0];t.type="text/javascript",t.id="sendinblue-js",t.async=!0,t.src="https://sibautomation.com/sa.js?key="+window.sib.client_key,n.parentNode.insertBefore(t,n)}();';

export function initBrevo(debug: boolean) {
  if (typeof window.sendinblue !== "undefined") return;

  const script = document.createElement("script");
  script.innerHTML = BREVO_SCRIPT_JS;
  document.head.appendChild(script);

  if (debug) {
    console.debug("[Brevo] load");
  }
}

interface BrevoContact {
  first_name: string;
  last_name: string;
  role?: string;
  team_name?: string;
  tier?: SubscriptionTier;
  attribution_channel?: string;
}

interface PageProperties {
  // These are automatically computed if not provided
  ma_url?: string;
  ma_path?: string;
  ma_title?: string;
  ma_referrer?: string;
}

export function identifyBrevoContact(
  email: string,
  contactProperties: BrevoContact,
  debug: boolean,
) {
  if (!IS_PRODUCTION) {
    return;
  }

  if (typeof window.sendinblue?.identify !== "function") return;

  if (debug) {
    console.debug("[Brevo] identify", email, contactProperties);
  }

  window.sendinblue.identify(email, contactProperties);
}

export function trackLinkClick(
  link: string,
  properties?: JsonObject,
  debug?: boolean,
) {
  if (!IS_PRODUCTION) {
    return;
  }

  if (typeof window.sendinblue?.page !== "function") return;

  if (debug) {
    console.debug("[Brevo] click", link, properties);
  }

  window.sendinblue.trackLink(link, properties);
}

export function trackPageVisit(
  pageName: string,
  properties?: PageProperties,
  debug?: boolean,
) {
  if (!IS_PRODUCTION) {
    return;
  }

  if (typeof window.sendinblue?.page !== "function") return;

  if (debug) {
    console.debug("[Brevo] trackPage", pageName, properties);
  }

  window.sendinblue.page(pageName, {
    ...properties,
    ma_title: pageName,
  });
}

export function captureBrevoEvent(
  eventName: string,
  properties?: JsonObject,
  debug?: boolean,
) {
  if (!IS_PRODUCTION) {
    return;
  }

  if (typeof window.sendinblue?.track !== "function") return;

  if (debug) {
    console.debug("[Brevo] track", eventName, properties);
  }

  window.sendinblue.track(eventName, {}, properties);
}

import { useSignupFlowFormData } from "@features/signup/SignupFlowProvider";
import {
  SignupFormFieldName,
  SignupSchemaField,
} from "@features/signup/constants";
import * as RadioGroup from "@radix-ui/react-radio-group";
import OneToOneMap from "@util/OneToOneMap";

import { PlanTerm } from "@util/constants";
import clsx from "clsx";

const itemTextClassName = clsx("px-4 py-[3px] text-paragraph-100-medium");
const selectedClassName = clsx(
  "border border-blue-200 bg-blue-50 text-blue-600",
);

const unselectedClassName = clsx(
  "border border-grayscale-200 bg-grayscale-100 text-grayscale-500 ",
);

const PlanTermToSelectValue = new OneToOneMap<PlanTerm, string>([
  [PlanTerm.MONTHLY, "monthly"],
  [PlanTerm.ANNUAL, "annual"],
]);

const PlanTermRadioGroup = () => {
  const { formData, setFormData } = useSignupFlowFormData();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- formData is guaranteed to exist here
  const { planTerm, planType } = formData.plan!;

  const handleValueChange = (value: string) => {
    if (!value) return; // Reclicked the same value
    setFormData({
      ...formData,
      [SignupSchemaField.Plan]: {
        [SignupFormFieldName.PlanType]: planType,
        [SignupFormFieldName.PlanTerm]:
          PlanTermToSelectValue.convertBack(value),
      },
    });
  };

  return (
    <RadioGroup.Root
      className="flex rounded"
      value={PlanTermToSelectValue.convert(planTerm)}
      onValueChange={handleValueChange}
    >
      <RadioGroup.Item
        value="monthly"
        className={clsx(
          itemTextClassName,
          "rounded-l",
          planTerm === PlanTerm.MONTHLY
            ? selectedClassName
            : unselectedClassName,
        )}
      >
        Monthly
      </RadioGroup.Item>
      <RadioGroup.Item
        value="annual"
        className={clsx(
          itemTextClassName,
          "rounded-r",
          planTerm === PlanTerm.ANNUAL
            ? selectedClassName
            : unselectedClassName,
        )}
      >
        Yearly
      </RadioGroup.Item>
    </RadioGroup.Root>
  );
};

export default PlanTermRadioGroup;

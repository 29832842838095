import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Immediately show error for failed requests instead of retrying the request and keeping the loading state
      retry: false,
      // Set stale time to 1 minute to not refetch the same queries too frequently
      staleTime: 60 * 1000,
    },
  },
});

import { useSignupFlowFormData } from "@features/signup/SignupFlowProvider";
import { SignupSchemaField } from "@features/signup/constants";
import { schemaFieldToSchemaMap } from "@features/signup/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { omitEmptyValues } from "@util/objects";
import { useCallback } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { z } from "zod";

interface UseSignupFormProps {
  schemaField: SignupSchemaField;
  defaultValues?: FieldValues;
  rehydrateValues?: boolean;
  onSubmit?: () => void;
}

export function useSignupForm({
  schemaField,
  defaultValues,
  rehydrateValues = true,
  onSubmit,
}: UseSignupFormProps) {
  const schema = schemaFieldToSchemaMap[schemaField];
  const { setFormData, formData } = useSignupFlowFormData();
  type FormData = z.infer<typeof schema>;

  if (rehydrateValues && defaultValues) {
    const data = formData?.[schemaField];

    for (const key of Object.keys(defaultValues)) {
      const existingFormValue = data?.[key as keyof typeof data];

      if (existingFormValue) {
        defaultValues[key] = existingFormValue;
      }
    }
  }

  const { handleSubmit, ...rest } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onTouched",
    defaultValues,
  });

  const handleSaveFormData = useCallback(async () => {
    if (onSubmit) {
      onSubmit();
    }

    await handleSubmit((formData: FormData) => {
      let updatedFormData = {};
      setFormData((existingFormData) => {
        updatedFormData = {
          ...existingFormData,
          ...{ [schemaField]: omitEmptyValues(formData) },
        };

        return updatedFormData;
      });
    })();
  }, [handleSubmit, onSubmit, schemaField, setFormData]);

  return { handleSubmit: handleSaveFormData, ...rest };
}

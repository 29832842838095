import { PlanTerm, SubscriptionTier, UsageCapType } from "@util/constants";
import { IS_PRODUCTION } from "@util/deployEnv";
import useSearchParam, { SearchParam } from "../../../../hooks/useSearchParam";

const stringToTier: Record<string, SubscriptionTier> = {
  free: SubscriptionTier.FREE,
  growth: SubscriptionTier.GROWTH,
  scale: SubscriptionTier.SCALE,
  enterprise: SubscriptionTier.ENTERPRISE,
  other: SubscriptionTier.OTHER,
};
const stringToTerm: Record<string, PlanTerm> = {
  monthly: PlanTerm.MONTHLY,
  annual: PlanTerm.ANNUAL,
};
const stringToUsageCapType: Record<string, UsageCapType> = {
  capped: UsageCapType.CAPPED,
  auto_scale: UsageCapType.AUTO_SCALE,
};

export interface PlanOverride {
  tier: SubscriptionTier | undefined;
  term: PlanTerm | undefined;
  usageCapType: UsageCapType | undefined;
}

/**
 * Use a query param to test the user having a plan
 */
export default function usePlanOverride(): PlanOverride {
  const testPlan = useSearchParam(SearchParam.TestPlan) || "";
  const testTerm = useSearchParam(SearchParam.TestTerm) || "";
  const testUsageCapType = useSearchParam(SearchParam.TestUsageCapType) || "";

  if (IS_PRODUCTION) {
    return { tier: undefined, term: undefined, usageCapType: undefined };
  }

  return {
    tier: stringToTier[testPlan],
    term: stringToTerm[testTerm],
    usageCapType: stringToUsageCapType[testUsageCapType],
  };
}

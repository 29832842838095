import clsx from "clsx";

interface LabelProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
}

const Label = ({ text, className, ...props }: LabelProps) => {
  return (
    <div
      {...props}
      className={clsx(
        className,
        "flex size-fit items-center justify-center rounded-[4px] px-2 py-1 text-label-100",
      )}
    >
      {text}
    </div>
  );
};

export default Label;

import { AnnouncementBanner } from "@alch/ui";

import { getFullName } from "@util/strings.ts";
import { getViewAsId } from "@util/viewAs.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/root";

const ViewingAsBanner = () => {
  const user = useSelector((state: RootState) => state.users.currentUser.value);
  const team = useSelector((state: RootState) => state.team.team.value);
  const viewAsId = getViewAsId();

  if (!user || !team || !viewAsId) {
    return null;
  }

  return (
    <AnnouncementBanner intent="warning">
      You are viewing this page in read-only mode as{" "}
      <strong>
        {user && getFullName(user, user.email)} ({team && team.name})
      </strong>
      .{" "}
      <AnnouncementBanner.Link href="/">
        Return to your normal view
      </AnnouncementBanner.Link>
      .
    </AnnouncementBanner>
  );
};

export default ViewingAsBanner;

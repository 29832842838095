import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/root";
import { fetchCurrentUser } from "../redux/users";

export default function useCurrentUserState() {
  const userState = useSelector((state: RootState) => state.users.currentUser);
  const dispatch = useDispatch();

  // Fetch user if it hasn't been fetched yet
  useEffect(() => {
    if (!userState.error && !userState.isLoading && !userState.hasValue) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch, userState]);

  return userState;
}

import { parse } from "qs";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { omitNilValues } from "@util/objects";
import { SubscriptionTier } from "../constants";
import { dynamicScriptLoad } from "./utils";

export const ALCHEMY_ANALYTICS_JS_URL =
  "https://static.alchemyapi.io/scripts/anayltics/alchemy-analytics.js";
const HEAP_SCRIPT_JS =
  'window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])}; heap.load("4099325469");';
export const HEAP_SCRIPT_ID = "heap-script";

export interface HeapProperties {
  [key: string]: string | number | boolean | undefined;
}
interface HeapUserProperties extends HeapProperties {
  tier?: SubscriptionTier;
}

export interface HeapInterface {
  load: (heapAppId: string) => void;
  identify: (userId: string) => void;
  addUserProperties: (properties: HeapUserProperties) => void;
  track: (event: string, properties?: HeapProperties) => void;
}

// identify users to heap using 'user_' prefix + userId, eg: 'user_1234'
function heapIdentity(userId: number) {
  return `user_${userId}`;
}

export function initHeap(debug: boolean) {
  if (window.heap !== undefined) return;

  const query = parse(window.location.search, { ignoreQueryPrefix: true });
  const heapOverride = query.heap_override;

  if (heapOverride) {
    const script = document.createElement("script");
    script.innerHTML = HEAP_SCRIPT_JS;
    document.head.appendChild(script);
  } else {
    dynamicScriptLoad(ALCHEMY_ANALYTICS_JS_URL, { id: HEAP_SCRIPT_ID });
  }

  if (debug) {
    console.debug(`[Heap] load - ${heapOverride ? "override" : "alchemy"}`);
  }
}

export function identifyHeapUser(
  userId: number,
  values: {
    firstName: string;
    lastName: string;
    createdAt: number; // Integer timestamp
    email: string;
    teamId?: number;
    teamName?: string;
    isBillingAdmin: boolean;
    referredBy?: string;
    referredByAffiliate?: string;
    tier?: SubscriptionTier;
  },
  debug: boolean,
) {
  const heapObj = window.heap;
  if (
    heapObj === undefined ||
    !heapObj.identify ||
    !heapObj.addUserProperties
  ) {
    return;
  }

  if (debug) {
    console.debug("[Heap] identify", heapIdentity(userId));
  }

  heapObj.identify(heapIdentity(userId));
  if (debug) {
    console.debug("[Heap] addUserProperties", values);
  }
  heapObj.addUserProperties(values);
}

export function addHeapOnboardingProperties(
  props: {
    onboardingResources?: string[];
    onboardingRequest?: {
      defaultMethod: string;
      method: string;
      language: string;
    };
  },
  debug: boolean,
) {
  const heapObj = window.heap;
  if (heapObj === undefined || !heapObj.addUserProperties) {
    return;
  }
  if (debug) {
    console.debug(`[Heap] onboarding properties added for user`, props);
  }
  heapObj.addUserProperties(getFlattenedEventProperties(props));
}

export function addHeapRecommendationProperties(
  props: { homeRecommendedProduct: string },
  debug: boolean,
) {
  const heapObj = window.heap;
  if (heapObj === undefined || !heapObj.addUserProperties) {
    return;
  }
  if (debug) {
    console.debug(`[Heap] recommendation properties added for user`, props);
  }
  heapObj.addUserProperties(props);
}

export function captureHeapEvent(
  eventName: string,
  properties?: HeapProperties,
  debug?: boolean,
) {
  const heapObj = window.heap;
  if (heapObj === undefined || !heapObj.track) {
    return;
  }
  if (debug) {
    console.debug("[Heap] event", eventName, properties);
  }

  heapObj.track(eventName, properties);
}

// Heap.track() only accepts objects with primative values. This function flattens values that are objects or arrays.
export function getFlattenedEventProperties(
  properties: Record<string, unknown>,
  maxDepth = 3,
) {
  type Primitive = string | number | boolean;

  function isObject(item: unknown): item is Record<string, unknown> {
    return item !== null && typeof item === "object" && !Array.isArray(item);
  }

  function flattenObject(
    obj: Record<string, unknown> | unknown[],
    depth = 1,
  ): Record<string, Primitive> {
    const result: Record<string, Primitive> = {};

    for (const [key, value] of Object.entries(obj)) {
      if (depth < maxDepth && (Array.isArray(value) || isObject(value))) {
        const flatObject = flattenObject(value, depth + 1);
        for (const [nestedKey, nestedValue] of Object.entries(flatObject)) {
          if (isObject(value)) {
            // If the value is an object, recursively flatten it
            // e.g. { key: { nestedKey: "hello" } } => { "key.nestedKey": "hello" }
            result[`${key}.${nestedKey}`] = nestedValue;
          } else if (Array.isArray(value)) {
            // If the value is an array, flatten each element in the array
            // e.g. { key: ["hello", 2, true] } => { "key.hello": true, "key.2": true, "key.true": true }
            result[`${key}.${nestedValue?.toString()}`] = true;
          }
        }
      } else {
        // If the value is a primitive or max recusrion depth is reached, add it as is
        // e.g. { key: "hello" } => { key: "hello" }
        result[key] = value as Primitive;
      }
    }

    return result;
  }

  return omitNilValues(flattenObject(properties));
}

export type DeployEnv = "production" | "staging" | "development";

const PROD_DOMAINS = [
  "composer.alchemyapi.io",
  "composer.alchemy.com",
  "sandbox.alchemy.com",
  "dashboard.alchemyapi.io",
  "dashboard.alchemy.com",
  "auth.alchemyapi.io",
  "auth.alchemy.com",
];

const STAGING_DOMAINS = [
  "test-composer.alchemyapi.io",
  "test-composer.alchemy.com",
  "test-dashboard.alchemyapi.io",
  "test-dashboard.alchemy.com",
  "test-auth.alchemyapi.io",
  "test-auth.alchemy.com",
  "composer.alchemypreview.com",
  "sandbox.alchemypreview.com",
  "dashboard.alchemypreview.com",
  "auth.alchemypreview.com",
];

export const STAGING_DASHBOARD_URL = "alchemypreview.com";

function getDeployEnv(hostname: string): DeployEnv {
  if (PROD_DOMAINS.includes(hostname)) {
    return "production";
  }
  if (STAGING_DOMAINS.includes(hostname)) {
    return "staging";
  }

  return "development";
}

export const DEPLOY_ENV = getDeployEnv(
  typeof window !== "undefined" ? window.location.hostname : "",
);
export const IS_PRODUCTION = DEPLOY_ENV === "production";
export const IS_STAGING = DEPLOY_ENV === "staging";
export const IS_DEVELOPMENT = DEPLOY_ENV === "development";

export const AUTH_ORIGIN = {
  production: "https://auth.alchemy.com",
  staging: "https://auth.alchemypreview.com",
  development:
    import.meta.env.VITE_AUTHCHEMY_URL || "https://auth.alchemypreview.com",
}[DEPLOY_ENV];

export const API_ORIGIN = {
  production: "https://app-api.alchemy.com",
  staging: "https://app-api.alchemypreview.com",
  development: "http://localhost:1001",
}[DEPLOY_ENV];

export const RAAS_API_ORIGIN = {
  production: "https://raas-backend.alchemy.com",
  staging: "https://raas-backend.alchemypreview.com",
  development:
    import.meta.env.VITE_RAAS_API_URL ||
    "https://raas-backend.alchemypreview.com",
}[DEPLOY_ENV];

export const DATA_PLATFORM_ORIGIN = {
  production: "https://manage-indexing.g.alchemy.com",
  staging: "https://manage-indexing.g.alchemypreview.com",
  development:
    import.meta.env.VITE_DP_URL ||
    "https://manage-indexing.g.alchemypreview.com",
}[DEPLOY_ENV];

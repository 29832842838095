import { Button } from "@alch/ui";
import classNames from "classnames";
import errorStateSvgUrl from "../icons/error-state.svg";

type ErrorStateProps = {
  className?: string;
  onReload: () => void;
};

function ErrorState({ className, onReload }: ErrorStateProps) {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center gap-2",
        className,
      )}
    >
      <img src={errorStateSvgUrl} className="mb-4" />
      <h2 className="text-heading-h3 text-black">Page could not be loaded</h2>
      <div className="">Sorry about that! Reload the page to try again.</div>
      <Button className="mt-4" onClick={onReload}>
        Reload
      </Button>
    </div>
  );
}

export default ErrorState;

import {
  ChainId,
  Network,
  NetworkId,
  Product,
  ProjectType,
} from "@alch/dx-entities";
import { combineReducers, Reducer } from "redux";
import actionCreatorFactory from "typescript-fsa";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
import { compareBy } from "../util/comparators";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerBuilderForLoadable,
} from "../util/loadable";
import {
  deleteFromOrderedIdMap,
  OrderedIdMap,
  orderedMapFromArray,
  setInOrderedMap,
  updateInOrderedIdMap,
} from "../util/orderedIdMap";

const actionCreator = actionCreatorFactory();

export interface AppsState {
  apps: Loadable<OrderedIdMap<string, App>>;
  creatingApp: Loadable<App>;
  updatingApp: Loadable<App>;
}

export interface WhitelistEntry {
  value: string;
  name: string;
}

export interface App {
  id: string;
  name: string;
  description: string;
  network: never;
  team_id: number;
  is_active: boolean;
  auth_token: string;
  time_created: number;
  created_by_id: number;
  qps_limit: number;
  fcups_limit: number;
  concurrent_request_limit: number | null;
  whitelisted_address_entries: WhitelistEntry[];
  whitelisted_origin_entries: WhitelistEntry[];
  whitelisted_ip_entries: WhitelistEntry[];
  network_allowlist: NetworkId[];
  enable_reinforced_transactions: boolean;
}

export interface CreateAppParams {
  name: string;
  description: string;
  enable_reinforced_transactions?: boolean;
  chain_allow_list: ChainId[];
  products: Product[];
  project_type: ProjectType | string;
}

export interface UpdateAppParams {
  app_id: string;
  name?: string;
  description?: string;
  network?: Network;
  whitelisted_addresses?: WhitelistEntry[];
  whitelisted_origins?: WhitelistEntry[];
  whitelisted_ips?: WhitelistEntry[];
  enable_reinforced_transactions?: boolean;
  is_active?: boolean;
}

export interface AppIdParams {
  app_id: string;
}

const requestApps = makeLoadingActionCreators<void, OrderedIdMap<string, App>>(
  "REQUEST_APPS",
);

const requestCreateApp = makeLoadingActionCreators<CreateAppParams, App>(
  "REQUEST_CREATE_APP",
);

export const resetCreateApp = actionCreator("RESET_CREATE_APP");

export const requestUpdateApp = makeLoadingActionCreators<UpdateAppParams, App>(
  "REQUEST_UPDATE_APP",
);

export const resetUpdateApp = actionCreator("RESET_UPDATE_APP");

export const fetchApps = makeFetchThunkActionCreator<
  void,
  OrderedIdMap<string, App>
>({
  actionCreators: requestApps,
  getFromState: (state) => state.apps.apps,
  fetchResult: () =>
    // Sort the apps alphabetically before adding to state.
    http
      .getTeamApps()
      .then((apps) =>
        orderedMapFromArray(apps.sort(appNameComparator), (app) => app.id),
      ),
});

export const createApp = makeFetchThunkActionCreator<CreateAppParams, App>({
  actionCreators: requestCreateApp,
  getFromState: (state) => state.apps.creatingApp,
  fetchResult: http.createApp,
});

export const updateApp = makeFetchThunkActionCreator<UpdateAppParams, App>({
  actionCreators: requestUpdateApp,
  getFromState: (state) => state.apps.updatingApp,
  fetchResult: http.updateApp,
});

export const updateAppNetworkAllowlist = actionCreator<
  AppIdParams & Pick<App, "network_allowlist">
>("UPDATE_APP_NETWORK_ALLOWLIST");

export const appsReducer: Reducer<AppsState> = combineReducers({
  apps: reducerBuilderForLoadable(requestApps)
    .case(requestCreateApp.done, (state, { result }) =>
      Loadable.map(state, (map) =>
        setInOrderedMap(map, result.value.id, result.value),
      ),
    )
    .case(requestUpdateApp.done, (state, { result }) =>
      Loadable.map(state, (map) =>
        result.value.is_active
          ? setInOrderedMap(map, result.value.id, result.value)
          : deleteFromOrderedIdMap(map, result.value.id),
      ),
    )
    .case(updateAppNetworkAllowlist, (state, { app_id, network_allowlist }) =>
      Loadable.map(state, (map) =>
        updateInOrderedIdMap(map, app_id, (app) => ({
          ...app,
          network_allowlist,
        })),
      ),
    )
    .build(),
  creatingApp: reducerBuilderForLoadable(requestCreateApp)
    .case(resetCreateApp, () => Loadable.unloaded())
    .build(),
  updatingApp: reducerBuilderForLoadable(requestUpdateApp)
    .case(resetUpdateApp, () => Loadable.unloaded())
    .build(),
});

const appNameComparator = compareBy<App>((app) => app.name.toLowerCase());

import { PropsWithChildren } from "react";

import { Loading } from "@alch/ui";
import ReactQueryErrorView from "@components/ui/react-query-error-view/ReactQueryErrorView";
import { useNetworkInfos } from "@queries/useNetwork";
import {
  GasPoliciesContext,
  useGasPoliciesFlags,
} from "./gasPoliciesDataHooks";

// eslint-disable-next-line @typescript-eslint/ban-types -- FIXME
const GasManagerPageWrapper = (props: PropsWithChildren<{}>) => {
  const networkInfosRes = useNetworkInfos();
  const config = useGasPoliciesFlags(networkInfosRes.data || []);

  if (networkInfosRes.isError) {
    return <ReactQueryErrorView results={networkInfosRes} />;
  }

  if (config.loading || networkInfosRes.data === undefined) {
    return (
      <div className="flex items-center justify-center">
        <Loading className="mt-6" />
      </div>
    );
  }

  return (
    <GasPoliciesContext.Provider value={config}>
      {props.children}
    </GasPoliciesContext.Provider>
  );
};

export default GasManagerPageWrapper;

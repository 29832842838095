import clsx from "clsx";

interface ProgressCount {
  currentStep: number;
  totalSteps: number;
}

const currentStepClassName = clsx("text-paragraph-200-medium text-teal-500");
const totalStepsClassName = clsx(
  "text-paragraph-200-medium text-grayscale-300",
);

const ProgressCount = ({ currentStep, totalSteps }: ProgressCount) => {
  return (
    <div className="grid grid-cols-3 gap-x-2">
      <span className={currentStepClassName}>{currentStep}</span>
      <span className={totalStepsClassName}>/</span>
      <span className={totalStepsClassName}>{totalSteps}</span>
    </div>
  );
};

export default ProgressCount;

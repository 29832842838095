import { NetworkInfo, Product } from "@alch/dx-entities";
import { GET_ALL_GAS_MANAGER_POLICIES as GET_ALL_GAS_POLICIES } from "@features/accounts/queries";
import { getGasPolicies } from "@http/endpoints";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { SubscriptionTier } from "@util/constants.ts";
import { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/root";
import { fetchActivePlan } from "../../redux/team";
import { Policy } from "./gasPolicyTypes";

interface GasPoliciesQueryContextData {
  loading: boolean;
  supportedNetworkInfos: NetworkInfo[];
  allNetworkInfos: NetworkInfo[];
  teamTier: SubscriptionTier;
}

type GasPoliciesContextData = Omit<GasPoliciesQueryContextData, "loading">;

export function gasPoliciesFlagsFromRootState(
  state: RootState,
  networkInfos: NetworkInfo[],
): GasPoliciesQueryContextData {
  const activePlan = state.team.activePlan;
  const teamTier = activePlan.value?.tier ?? SubscriptionTier.FREE;

  const supportedNetworkInfos = networkInfos.filter((networkInfo) => {
    if (!networkInfo.supportedProducts.includes(Product.GasManagerApi)) {
      return false;
    }
    if (teamTier === SubscriptionTier.FREE) {
      return networkInfo.isTestNet;
    }
    return true;
  });

  return {
    supportedNetworkInfos,
    allNetworkInfos: networkInfos,
    loading: activePlan.isLoading,
    teamTier,
  };
}

export function useGasPoliciesFlags(networkInfos: NetworkInfo[]) {
  const dispatch = useDispatch();
  const flags = useSelector((state: RootState) =>
    gasPoliciesFlagsFromRootState(state, networkInfos),
  );

  useEffect(() => {
    dispatch(fetchActivePlan());
  }, [dispatch]);
  return flags;
}

export const GasPoliciesContext = createContext<GasPoliciesContextData>({
  supportedNetworkInfos: [],
  allNetworkInfos: [],
  teamTier: SubscriptionTier.FREE,
});

/**
 * In order to use this, make sure your component is wrapped in GasManagerPageWrapper
 */
export function useGasPoliciesContext(): GasPoliciesContextData {
  const data = useContext(GasPoliciesContext);
  if (data === undefined) {
    throw new Error(
      "Gas Policies wasn't initialized, make sure you wrap the component inside <GasManagerPageWrapper />",
    );
  }
  return data;
}

export function useAllGasManagerPolicies(): UseQueryResult<Policy[]> {
  return useQuery({
    queryFn: getAllGasPolicies,
    queryKey: [GET_ALL_GAS_POLICIES],
  });
}

// This is enforced by the backend.
const MAX_GAS_POLICY_PAGE_SIZE = 50;

async function getAllGasPolicies(): Promise<Policy[]> {
  const out: Policy[] = [];
  let after: string | null = null;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const response = await getGasPolicies(
      MAX_GAS_POLICY_PAGE_SIZE,
      null,
      after,
    );
    after = response.after;
    out.push(...response.policies);
    if (
      response.policies.length < MAX_GAS_POLICY_PAGE_SIZE ||
      !after ||
      response.policies.length === 0
    ) {
      return out;
    }
  }
}

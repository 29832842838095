import { SubscriptionTier } from "@util/constants.ts";

export enum PricingTier {
  FREE,
  GROWTH,
  SCALE_MONTHLY,
  SCALE_ANNUAL,
  ENTERPRISE,
  OTHER,
}

export const TIER_NAMES: Record<PricingTier, string> = {
  [PricingTier.FREE]: "Free",
  [PricingTier.GROWTH]: "Growth",
  [PricingTier.SCALE_MONTHLY]: "Scale Monthly",
  [PricingTier.SCALE_ANNUAL]: "Scale Yearly",
  [PricingTier.ENTERPRISE]: "Enterprise",
  [PricingTier.OTHER]: "Other",
};

export const ALL_TIERS: PricingTier[] = [
  PricingTier.FREE,
  PricingTier.GROWTH,
  PricingTier.SCALE_MONTHLY,
  PricingTier.SCALE_ANNUAL,
  PricingTier.ENTERPRISE,
  PricingTier.OTHER,
];

export const SELF_SERVICE_TIERS: PricingTier[] = ALL_TIERS.filter(
  (p) => p !== PricingTier.ENTERPRISE && p !== PricingTier.OTHER,
);

export const PAID_SELF_SERVICE_TIERS: PricingTier[] = SELF_SERVICE_TIERS.filter(
  (p) => p !== PricingTier.FREE,
);

export interface PricingConfig {
  name: string;
  tier: SubscriptionTier;
  baseCost: number;
  includedUnits: number;
  prepaidCPM: number;
  onDemandCPM: number;
  monthly: boolean;
  supportsPrepaid: boolean;
  supportsOnDemand: boolean;
  throughput: number | string;
  numOfApps: number;
  numOfActiveWebhooks: number;
  tagline: string;
  capabilities: {
    autoScaling: boolean;
    debugApi: boolean;
    traceApi: boolean;
    reinforcedTransactions: boolean;
    gasManager: "mainnet" | "testnet";
  };
}

/**
 * Monthly pricing details per tier
 */
export const TIER_PRICING: Record<PricingTier, PricingConfig> = {
  [PricingTier.FREE]: {
    name: "Free",
    tier: SubscriptionTier.FREE,
    baseCost: 0,
    includedUnits: 300_000_000,
    prepaidCPM: 0, // Unsupported, monthly plans can't prepay
    onDemandCPM: 0, // Unsupported, free has a hard usage cap
    monthly: true,
    supportsPrepaid: false,
    supportsOnDemand: false,
    throughput: 330,
    numOfApps: 5,
    numOfActiveWebhooks: 5,
    tagline: "The largest & most powerful free tier in web3.",
    capabilities: {
      autoScaling: false,
      debugApi: false,
      traceApi: false,
      reinforcedTransactions: false,
      gasManager: "testnet",
    },
  },
  [PricingTier.GROWTH]: {
    name: "Growth",
    tier: SubscriptionTier.GROWTH,
    baseCost: 49.0,
    includedUnits: 400_000_000,
    prepaidCPM: 0, // Unsupported, monthly plans can't prepay
    onDemandCPM: 1.2,
    monthly: true,
    supportsPrepaid: false,
    supportsOnDemand: true,
    throughput: 660,
    numOfApps: 15,
    numOfActiveWebhooks: 0,
    tagline: "Grows with your business.",
    capabilities: {
      autoScaling: true,
      debugApi: true,
      traceApi: true,
      reinforcedTransactions: true,
      gasManager: "mainnet",
    },
  },
  [PricingTier.SCALE_MONTHLY]: {
    name: "Scale",
    tier: SubscriptionTier.SCALE,
    baseCost: 289.0,
    includedUnits: 1_500_000_000,
    prepaidCPM: 0, // Unsupported, monthly plans can't prepay
    onDemandCPM: 1.0,
    monthly: true,
    supportsPrepaid: false,
    supportsOnDemand: true,
    throughput: 3000,
    numOfApps: 30,
    numOfActiveWebhooks: 0,
    tagline:
      "The cheapest option in web3; enterprise discounts, no humans required.",
    capabilities: {
      autoScaling: true,
      debugApi: true,
      traceApi: true,
      reinforcedTransactions: true,
      gasManager: "mainnet",
    },
  },
  [PricingTier.SCALE_ANNUAL]: {
    name: "Scale",
    tier: SubscriptionTier.SCALE,
    baseCost: 199.0,
    includedUnits: 1_500_000_000,
    prepaidCPM: 0.7,
    onDemandCPM: 1.0,
    monthly: false,
    supportsPrepaid: true,
    supportsOnDemand: true,
    throughput: "3000+",
    numOfApps: 30,
    numOfActiveWebhooks: 0,
    tagline:
      "The cheapest option in web3; enterprise discounts, no humans required.",
    capabilities: {
      autoScaling: true,
      debugApi: true,
      traceApi: true,
      reinforcedTransactions: true,
      gasManager: "mainnet",
    },
  },
  [PricingTier.ENTERPRISE]: {
    // These don't matter, they are just there to have a complete Record
    name: "Enterprise",
    tier: SubscriptionTier.ENTERPRISE,
    baseCost: 0,
    includedUnits: 0,
    prepaidCPM: 0,
    onDemandCPM: 0,
    monthly: false,
    supportsPrepaid: false,
    supportsOnDemand: false,
    throughput: "5000+",
    numOfApps: 0,
    numOfActiveWebhooks: 0,
    tagline: "Volume pricing, unmatched support, custom SLAs.",
    capabilities: {
      autoScaling: true,
      debugApi: true,
      traceApi: true,
      reinforcedTransactions: true,
      gasManager: "mainnet",
    },
  },
  [PricingTier.OTHER]: {
    // These don't matter, they are just there to have a complete Record
    name: "Other",
    tier: SubscriptionTier.OTHER,
    baseCost: 0,
    includedUnits: 0,
    prepaidCPM: 0,
    onDemandCPM: 0,
    monthly: false,
    supportsPrepaid: false,
    supportsOnDemand: false,
    throughput: "5000+",
    numOfApps: 0,
    numOfActiveWebhooks: 0,
    tagline: "Volume pricing, unmatched support, custom SLAs.",
    capabilities: {
      autoScaling: true,
      debugApi: true,
      traceApi: true,
      reinforcedTransactions: true,
      gasManager: "mainnet",
    },
  },
} as const;

export const TIER_RANK: Record<PricingTier, number> = {
  [PricingTier.FREE]: 0,
  [PricingTier.GROWTH]: 1,
  [PricingTier.SCALE_MONTHLY]: 2,
  [PricingTier.SCALE_ANNUAL]: 3,
  [PricingTier.ENTERPRISE]: 4,
  [PricingTier.OTHER]: 5,
};

import { HTMLMotionProps, motion } from "framer-motion";
import React from "react";

interface CollapsibleProps extends HTMLMotionProps<"div"> {
  children: React.ReactNode;
  isOpen: boolean;
}

const Collapsible = ({ children, isOpen, ...props }: CollapsibleProps) => {
  return (
    <motion.div
      initial={false}
      animate={{
        opacity: isOpen ? 1 : 0,
        height: isOpen ? "auto" : 0,
        visibility: isOpen ? "visible" : undefined,
        overflow: isOpen ? "visible" : undefined,
        transitionEnd: {
          visibility: isOpen ? "visible" : "hidden",
          overflow: isOpen ? "visible" : "hidden",
        },
      }}
      transition={{
        type: "spring",
        duration: 0.3,
        bounce: 0,
      }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default Collapsible;

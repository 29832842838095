import { WaffleFlag } from "@alch/dx-entities";
import { useQuery } from "@tanstack/react-query";
import { find } from "lodash";
import { useMemo } from "react";
import { getWaffleFlags } from "../../http/endpoints";

const WAFFLE_FLAG_KEY = "featureFlags";

function useWaffleFlags<TFlag extends WaffleFlag>(
  featureFlags: TFlag[],
): Record<TFlag, boolean> {
  const { flags } = useWaffleFlagsLoading(featureFlags);
  return flags;
}

function useWaffleFlag(featureFlag: WaffleFlag) {
  const flags = useWaffleFlags([featureFlag]);
  return flags[featureFlag];
}

/**
 * Prefer to use this hook over `useWaffleFlags` so you can check the loading state of the flag to prevent
 * seeing a blip on the page when the flag switches from false to true.
 */
function useWaffleFlagsLoading<TFlag extends WaffleFlag>(
  featureFlags: TFlag[],
): { isLoading: boolean; flags: Record<TFlag, boolean> } {
  const { data: flagObjs, isLoading } = useQuery({
    queryKey: [WAFFLE_FLAG_KEY, featureFlags],
    queryFn: () => getWaffleFlags({ flags: featureFlags }),
    staleTime: 60 * 1000,
  });

  const flags = useMemo(
    () =>
      featureFlags.reduce(
        (acc, flag) => {
          // Flags default to false if they can't be loaded
          acc[flag] = find(flagObjs || [], { name: flag })?.active ?? false;
          return acc;
        },
        {} as Record<TFlag, boolean>,
      ),
    [featureFlags, flagObjs],
  );

  return {
    isLoading,
    flags,
  };
}

function useWaffleFlagLoading(featureFlag: WaffleFlag) {
  const { flags, isLoading } = useWaffleFlagsLoading([featureFlag]);
  return { value: flags[featureFlag], isLoading };
}

export {
  useWaffleFlag,
  useWaffleFlagLoading,
  useWaffleFlags,
  useWaffleFlagsLoading,
};

/**
 * Checks if the provided URL is outside the current page's domain.
 */
export default function isExternalUrl(url: string): boolean {
  return pureIsExternalUrl(url, window.location);
}

// For testability
export function pureIsExternalUrl(url: string, currentLocation: Location) {
  try {
    const parsedUrl = new URL(url, currentLocation.href);

    // Check if the hostname of the URL matches the current page's hostname
    return parsedUrl.host !== currentLocation.host;
  } catch (error) {
    // If there is an error parsing the URL, assume it's not external
    return false;
  }
}

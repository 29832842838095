import { Button, Modal } from "@alch/ui";
import { DataPlatformAPI } from "@features/data-platform/api.ts";
import {
  PipelineModalAction,
  useDataPlatformContext,
} from "@features/data-platform/context.tsx";
import { DesiredPipelineStatus } from "@features/data-platform/types.ts";
import React, { useCallback, useMemo } from "react";

export const StopPipelineModal: React.FC = () => {
  const { modal, closeModal, pipelines, refreshPipelines } =
    useDataPlatformContext();

  const pipeline = useMemo(() => {
    if (modal) {
      return pipelines.find((p) => modal.pipelineID === p.id);
    }
  }, [pipelines, modal]);

  const onClickConfirm = useCallback(async () => {
    if (modal) {
      await DataPlatformAPI.updateDesiredStatus(
        modal.pipelineID,
        DesiredPipelineStatus.Stopped,
      );
      await refreshPipelines();
      closeModal();
    }
  }, [modal, refreshPipelines, closeModal]);

  return (
    <Modal
      isOpen={modal?.action === PipelineModalAction.STOP}
      onClose={closeModal}
      title="Stop this pipeline?"
    >
      <div className="flex flex-col gap-4">
        <div>
          You&apos;re about to stop running{" "}
          <span className="font-bold">{pipeline?.name}</span>. This cannot be
          undone. Are you sure you want to do this?
        </div>
        <div className="mt-12 flex justify-end">
          <Button onClick={closeModal} intent="tertiary">
            Close
          </Button>
          <Button onClick={onClickConfirm} intent="critical">
            Stop
          </Button>
        </div>
      </div>
    </Modal>
  );
};

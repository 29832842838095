import { useEffect, useState } from "react";
import searchDocs, { DocLink } from "./searchDocs";

const DEBOUNCE_MS = 200;

export default function useSearchDocs(query: string): DocLink[] {
  const [links, setLinks] = useState<DocLink[]>([]);

  useEffect(() => {
    const cancelId = setTimeout(() => {
      if (query.length === 0) {
        setLinks([]);
        return;
      }
      searchDocs(query)
        .then((newLinks) => {
          setLinks(newLinks);
        })
        .catch(() => {
          setLinks([]);
        });
    }, DEBOUNCE_MS);

    return () => {
      clearTimeout(cancelId);
    };
  }, [query, setLinks]);

  return links;
}

import React, { useEffect } from "react";
import { getSatsumaJWTRedirect } from "../../http/endpoints.ts";

export const RedirectToSatsuma: React.FC = () => {
  // Builds a JWT and redirects to Satsuma

  useEffect(() => {
    const init = async () => {
      // API returns a JWT
      const response = await getSatsumaJWTRedirect();
      const { redirectTo } = response;
      location.href = redirectTo;
    };

    void init();
  }, []);

  return (
    <div className="flex-center mt-20 flex w-full items-center justify-center">
      Redirecting...
    </div>
  );
};

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/**
 * Represents a "bijective" map, aka a one-to-one mapping.
 *
 * Using a map here instead of an object array for data type support beyond numbers and strings.
 */
export default class OneToOneMap<Left, Right> {
  private fwdMap: Map<Left, Right>;
  private revMap: Map<Right, Left>;

  constructor(tuples: Array<[Left, Right]>) {
    this.fwdMap = new Map<Left, Right>();
    this.revMap = new Map<Right, Left>();
    tuples.forEach(([left, right]) => {
      if (this.fwdMap.has(left)) {
        throw new Error(
          `Duplicate left key "${left}" -> "${right}" in one to one mapping.`,
        );
      }
      this.fwdMap.set(left, right);
      if (this.revMap.has(right)) {
        throw new Error(
          `Duplicate right key for "${left}" -> "${right}" in one to one mapping.`,
        );
      }
      this.revMap.set(right, left);
    });
  }

  public convert(left: Left) {
    const result = this.fwdMap.get(left);
    if (result === undefined) {
      throw new Error(`No map from "${left}".`);
    }
    return result;
  }

  public maybeConvert(left: Left) {
    return this.fwdMap.get(left);
  }

  public convertBack(right: Right) {
    const result = this.revMap.get(right);
    if (result === undefined) {
      throw new Error(`No map from "${right}".`);
    }
    return result;
  }

  public maybeConvertBack(right: Right) {
    return this.revMap.get(right);
  }
}

import { Store, Unsubscribe } from "redux";

// Adapted from https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
export default function observeStore<S, T>(
  store: Store<S>,
  select: (state: S) => T,
  onChange: (value: T) => void,
): Unsubscribe {
  let currentValue: T;

  function handleChange(): void {
    const nextValue = select(store.getState());
    if (nextValue !== currentValue) {
      currentValue = nextValue;
      onChange(currentValue);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}

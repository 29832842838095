import { Checkbox } from "@alch/ui";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import classNames from "classnames";
import { forwardRef } from "react";

const rootClassName = classNames("flex flex-wrap gap-2");

const itemClassName = classNames(
  "group flex px-3 py-2 items-center gap-2 text-left",
  "rounded-lg shadow-border shadow-grayscale-300 transition bg-white",
  "enabled:hover:shadow-border-2 enabled:hover:shadow-blue-400",
  "data-[state=on]:bg-blue-50 data-[state=on]:shadow-border-2 data-[state=on]:shadow-blue-400",
  "outline-none focus-visible:shadow-focus data-[state=on]:focus-visible:shadow-focus",
);

const labelClassName = classNames(
  "flex gap-1 items-center text-paragraph-200-regular text-grayscale-700 transition",
  "group-data-[state=on]:text-grayscale-950 group-hover:group-enabled::text-grayscale-950",
  "group-disabled:opacity-50",
);

const checkboxClassName = classNames(
  "enabled:group-hover:bg-blue-100 enabled:group-hover:border-blue-600",
);

interface SimpleToggleItem {
  label: string;
  value: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}

type SimpleToggleGroupProps = (
  | ToggleGroup.ToggleGroupSingleProps
  | ToggleGroup.ToggleGroupMultipleProps
) & {
  items: SimpleToggleItem[];
  onHighlight?: (value?: string) => void;
};

interface SimpleToggleItemProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  groupType: SimpleToggleGroupProps["type"];
  item: SimpleToggleItem;
  "data-state"?: string;
}

const SimpleToggleItem = forwardRef<HTMLButtonElement, SimpleToggleItemProps>(
  function SimpleToggleItem({ groupType, item, className, ...props }, ref) {
    return (
      <button
        className={classNames(itemClassName, className)}
        ref={ref}
        {...props}
      >
        {groupType === "multiple" && (
          <div onClick={preventDefault} className="pointer-events-none">
            <Checkbox
              as="div"
              className={checkboxClassName}
              checked={props["data-state"] === "on"}
              size="sm"
              disabled={item.disabled}
            />
          </div>
        )}

        <div className={labelClassName}>
          {item.icon}
          {item.label}
        </div>
      </button>
    );
  },
);

const SimpleToggleGroup = ({
  items,
  className,
  onHighlight,
  ...props
}: SimpleToggleGroupProps) => {
  return (
    <ToggleGroup.Root
      className={classNames(rootClassName, className)}
      onMouseLeave={() => onHighlight?.()}
      onBlur={() => onHighlight?.()}
      {...props}
    >
      {items.map((item) => (
        <ToggleGroup.Item
          asChild
          key={item.value}
          value={item.value}
          disabled={item.disabled}
          onFocus={() => onHighlight?.(item.value)}
          onMouseEnter={() => onHighlight?.(item.value)}
        >
          <SimpleToggleItem groupType={props.type} item={item} />
        </ToggleGroup.Item>
      ))}
    </ToggleGroup.Root>
  );
};

const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

export default SimpleToggleGroup;

import { IconButton } from "@alch/ui";
import { Menu01 } from "@alch/ui/icons/24";
import { useCallback } from "react";

import Banners from "../banners";

import HeaderLogo from "./HeaderLogo";

interface HeaderProps {
  setShowNavOverlay: (show: boolean) => void;
  withOverlays: boolean;
}

const Header = ({ setShowNavOverlay, withOverlays }: HeaderProps) => {
  const handleShowNavOverlay = useCallback(() => {
    setShowNavOverlay(true);
  }, [setShowNavOverlay]);

  if (!withOverlays) {
    return <Banners className="mb-6 overflow-hidden rounded-xl empty:mb-0" />;
  }

  return (
    <div className="overflow-hidden">
      <Banners />

      <div className="flex items-center gap-3 p-4 md:gap-4 md:py-3">
        {withOverlays ? (
          <div className="flex flex-1 items-center gap-4">
            <IconButton
              className="text-grayscale-700"
              padding="lg"
              onClick={handleShowNavOverlay}
            >
              <Menu01 />
            </IconButton>

            <HeaderLogo />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;

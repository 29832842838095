import { ChainInfo } from "@alch/dx-entities";
import { Emblem } from "./emblem";
import { networkEmblemUrl } from "./helpers";

interface ChainEmblemProps extends React.HTMLAttributes<HTMLDivElement> {
  chainInfo: Pick<ChainInfo, "name" | "id">;
}

const ChainEmblem = ({ chainInfo, ...props }: ChainEmblemProps) => {
  const { name, id } = chainInfo;
  return (
    <Emblem imageAlt={name} imageUrl={networkEmblemUrl(id, false)} {...props} />
  );
};

export default ChainEmblem;

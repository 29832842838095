import { SVGProps } from "react";

type IconProps = {
  className?: string;
} & SVGProps<SVGSVGElement>;

/**
 * @deprecated Use the icons in @alch/ui
 */
const WarningCircleIcon = (props: IconProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9935 2.35015C12.607 2.34195 13.2665 2.63067 13.6086 3.24705L21.8002 19.0471C21.9001 19.2471 22 19.5471 22 19.8471C22 20.8471 21.2008 21.6471 20.2018 21.6471H3.71876C3.48162 21.6471 3.30702 21.5844 3.09599 21.5088C3.04032 21.4888 2.98213 21.4679 2.91958 21.4471C2.1204 20.9471 1.72081 19.9471 2.2203 19.0471C2.2203 19.0471 10.1804 3.86933 10.5118 3.24705C10.8432 2.62477 11.3799 2.35834 11.9935 2.35015ZM11.1105 8.74705C11.1105 8.34705 11.5105 7.94705 12.0105 7.94705C12.5105 7.94705 12.9105 8.34705 12.9105 8.84705V14.1471C12.9105 14.6471 12.5105 15.0471 12.0105 15.0471C11.5105 15.0471 11.1105 14.6471 11.1105 14.1471V8.74705ZM12 18.95C12.718 18.95 13.3 18.3679 13.3 17.65C13.3 16.932 12.718 16.35 12 16.35C11.282 16.35 10.7 16.932 10.7 17.65C10.7 18.3679 11.282 18.95 12 18.95Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningCircleIcon;

const VALID_HOST_NAME_SUFFIXES = [
  "alchemy.com",
  "alchemyapi.io",
  "alchemypreview.com",
  "localhost",
  "rinkebyfaucet.com",
  "goerlifaucet.com",
  "mumbaifaucet.com",
  "sepoliafaucet.com",
  "basefaucet.com",
  "optimism-faucet.com",
  "arbitrum-faucet.com",
];
/**
 * Only redirects to valid HTTP/HTTPS urls to avoid a JS injection vulnerability.
 */
export default function safeRedirect(urlString: string): boolean {
  const url = new URL(urlString, window.location.origin);
  if (
    ["http:", "https:"].includes(url.protocol) &&
    VALID_HOST_NAME_SUFFIXES.some((suf) => url.hostname.endsWith(suf))
  ) {
    window.location.href = url.toString();
    return true;
  }
  console.error(`Attempted to redirect to an invalid URL: ${urlString}`);
  return false;
}

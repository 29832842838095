import { CompleteSignupRequestParams } from "@features/signup/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { completeSignup } from "../../http/endpoints";
import { ONBOARDING_STATUS_QUERY_KEY } from "../queries/useOnboardingStatus";

function useCompleteSignup() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: CompleteSignupRequestParams) => completeSignup(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ONBOARDING_STATUS_QUERY_KEY,
      });
    },
  });
}

export default useCompleteSignup;

import { useQuery } from "@tanstack/react-query";
import { SubscriptionTier } from "@util/constants";
import { getPlanCostPreview } from "../../http/endpoints";

const planCostPreviewQueryKey = {
  all: ["planCostPreview"] as const,
  byPlanTier: (planTier: SubscriptionTier) => [
    ...planCostPreviewQueryKey.all,
    planTier,
  ],
};

function usePlanCostPreview(planTier: SubscriptionTier) {
  return useQuery({
    queryKey: planCostPreviewQueryKey.byPlanTier(planTier),
    queryFn: () =>
      getPlanCostPreview({
        plan_tier: planTier,
        prorated: true,
      }),
  });
}

export default usePlanCostPreview;

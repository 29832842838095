import { ChainId, ChainInfo } from "@alch/dx-entities";
import { trpc } from "@util/trpc/trpcClient";
import { z } from "zod";

export function useChainInfos() {
  return trpc.config.getChainConfig.useQuery();
}

export function useSuspenseChainInfos() {
  return trpc.config.getChainConfig.useSuspenseQuery();
}

export function createChainValidator(chainInfos: ChainInfo[]) {
  return z.string().refine((chainId): chainId is ChainId => {
    return chainInfos.some((info) => info.id === chainId);
  });
}

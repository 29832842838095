import { ArrowUpRightSm } from "@alch/ui/icons/16";
import * as HoverCard from "@radix-ui/react-hover-card";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useCallback, useRef, useState } from "react";

import { ViewAsPreservingLink } from "../../common/ViewAsPreservingLink";
import {
  InternalNavLink,
  NavLink,
  NestedNavItem,
  isExternalLink,
  isInternalLink,
  isNestedItem,
} from "./useNavItems";

export interface NavButtonProps {
  item: NavLink | NestedNavItem;
  onClick?: (item: NavLink | NestedNavItem) => void;
  activeItem?: InternalNavLink;
}

const buttonClassName = classNames(
  "group my-0.5 flex w-full items-center gap-2 rounded-lg px-2 py-0.5 text-left text-paragraph-200-regular text-invert outline-none transition",
  "hover:cursor-pointer hover:bg-alpha-white-10",
  "aria-current-page:bg-alpha-white-20 aria-current-page:shadow-outer-border",
  "data-[state=open]:bg-alpha-white-10 data-[state=open]:aria-current-page:bg-alpha-white-20",
  "aria-current-page:shadow-white/30 dark:aria-current-page:shadow-blue-950/30",
  "focus-visible:shadow-focus-dark dark:focus-visible:shadow-focus",
);

const labelClassName = classNames(
  "flex-1 min-w-0 overflow-hidden text-ellipsis whitespace-nowrap",
);

const trailingIconClassName = classNames(
  "-mr-1 text-icon-tertiary group-aria-current-page:text-icon-invert transition",
  "opacity-0 group-hover:opacity-100 group-focus-visible:opacity-100",
);

const newBadgeClassName = classNames(
  "rounded bg-blue-400 px-3 py-1.5 text-label-100 text-white",
);

const hoverCardClassName = classNames(
  "rounded-r-xl bg-contrast-darkest p-2 w-48",
);

const NavButton = ({ item, onClick, activeItem }: NavButtonProps) => {
  const [hideTrailingIcon, setHideTrailingIcon] = useState(false);
  const labelRef = useRef<HTMLSpanElement>(null);

  const external = isExternalLink(item);
  const internal = isInternalLink(item);

  const handleClick = useCallback(() => {
    onClick?.(item);
  }, [item, onClick]);

  const isActive =
    isNestedItem(item) && activeItem
      ? item.items.includes(activeItem)
      : activeItem === item;

  const commonProps = {
    className: buttonClassName,
    onClick: handleClick,
    "aria-current": isActive ? ("page" as const) : undefined,
  };

  const commonContent = (
    <>
      <span className={labelClassName} ref={labelRef}>
        {item.label}
      </span>

      {item.highlight && !isActive && (
        <span className={newBadgeClassName}>New!</span>
      )}
    </>
  );

  const handleHideTrailingIcon = useCallback(() => {
    const labelElement = labelRef.current;

    if (!labelElement) return;

    const labelTruncated = labelElement.offsetWidth < labelElement.scrollWidth;

    if (labelTruncated) {
      setHideTrailingIcon(true);
    }
  }, []);

  const handleShowTrailingIcon = useCallback(() => {
    if (hideTrailingIcon) {
      setHideTrailingIcon(false);
    }
  }, [hideTrailingIcon]);

  return (
    <li>
      {external ? (
        <a
          href={item.href}
          target="_blank"
          rel="noreferrer noopener"
          onMouseEnter={handleHideTrailingIcon}
          onMouseLeave={handleShowTrailingIcon}
          onFocus={handleHideTrailingIcon}
          onBlur={handleShowTrailingIcon}
          {...commonProps}
        >
          {commonContent}

          {hideTrailingIcon ? null : (
            <span className={classNames(trailingIconClassName)}>
              <ArrowUpRightSm />
            </span>
          )}
        </a>
      ) : internal ? (
        <ViewAsPreservingLink to={item.to} {...commonProps}>
          {commonContent}
        </ViewAsPreservingLink>
      ) : isNestedItem(item) ? (
        <HoverCard.Root openDelay={100} closeDelay={800}>
          <HoverCard.Trigger asChild>
            <button {...commonProps}>{commonContent}</button>
          </HoverCard.Trigger>

          <HoverCard.Portal>
            <HoverCard.Content
              asChild
              side="right"
              sideOffset={8}
              collisionPadding={{ bottom: 36 }}
              hideWhenDetached
            >
              <motion.ul
                className={hoverCardClassName}
                initial={{ opacity: 0, x: -12 }}
                animate={{ opacity: 1, x: 0 }}
              >
                {item.items.map((subItem, index) => (
                  <NavButton
                    key={index}
                    item={subItem}
                    onClick={onClick}
                    activeItem={activeItem}
                  />
                ))}
              </motion.ul>
            </HoverCard.Content>
          </HoverCard.Portal>
        </HoverCard.Root>
      ) : (
        <button {...commonProps}>{commonContent}</button>
      )}
    </li>
  );
};

export default NavButton;

import { initAnalytics, setAnalyticsUser } from "@util/analytics";
import { useEffect } from "react";
import useCurrentUserState from "./useCurrentUserState";

initAnalytics();

/**
 * During signup we don't have any team details, so only identify the user with what we can
 */
export default function useAnalyticsInSignup() {
  const { value: user } = useCurrentUserState();

  useEffect(() => {
    if (user) {
      setAnalyticsUser({
        extId: user.ext_id,
        internalId: user.id,
        email: user.email,
        isStaff: false,
        createdAt: user.date_joined,
        isBillingAdmin: false,
        firstName: user.first_name,
        lastName: user.last_name,
        role: user.role,
      });
    }
  }, [user]);
}

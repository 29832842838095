import { useQuery } from "@tanstack/react-query";
import {
  getStoredAuthToken,
  logOutFromAuthchemy,
  redirectToAuthchemy,
} from "@util/auth";
import { AuthError } from "@util/errors";
import safeRedirect from "@util/safeRedirect";
import { useEffect, useMemo } from "react";
import useSearchParam, { SearchParam } from "../../hooks/useSearchParam";
import { getOnboardingStatus } from "../../http/endpoints";
import { GetOnboardingStatusResponse } from "../../http/types";

export const ONBOARDING_STATUS_QUERY_KEY = ["onboardingStatus"];

const useOnboardingStatus = () => {
  const onboardedRedirectUrl = useSearchParam(SearchParam.RedirectUrl);
  const redirectAttemptParam = useSearchParam(SearchParam.RedirectAttempt);
  const testSignup = useSearchParam(SearchParam.TestSignup);

  const redirectAttempt = useMemo(
    () => Math.max(+(redirectAttemptParam || "") || 0, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- want to only capture initial value on page load
    [],
  );
  const authToken = getStoredAuthToken();

  const { data, isPending, error } = useQuery<
    GetOnboardingStatusResponse,
    Error
  >({
    queryKey: ONBOARDING_STATUS_QUERY_KEY,
    queryFn: () => getOnboardingStatus(),
    // Only run the query if we have an authToken which useAuthRedirect() will obtain
    enabled: !!authToken,
  });

  useEffect(() => {
    // Redirect faucet users back to their faucet after signup is complete
    if (data?.has_plan && onboardedRedirectUrl && !testSignup) {
      safeRedirect(onboardedRedirectUrl);
    }
  }, [data?.has_plan, onboardedRedirectUrl, testSignup]);

  useEffect(() => {
    if (error instanceof AuthError) {
      if (redirectAttempt < 2) {
        // The auth token was invalid, redirect to get a new one from authchemy
        redirectToAuthchemy(redirectAttempt + 1);
      } else {
        // Authchemy keeps redirecting back to dashboard with invalid authToken. Force user logout.
        logOutFromAuthchemy();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this effect when the error changes
  }, [error]);

  return { isPending, hasPlan: data?.has_plan, error };
};

export default useOnboardingStatus;

import { combineReducers, Reducer } from "redux";
import actionCreatorFactory from "typescript-fsa";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerBuilderForLoadable,
} from "../util/loadable";
import {
  deleteFromOrderedIdMap,
  OrderedIdMap,
  orderedMapFromArray,
  updateInOrderedIdMap,
} from "../util/orderedIdMap";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { requestUpdateRateLimitSettings } from "./team";

const actionCreator = actionCreatorFactory();

export interface UsersState {
  currentUser: Loadable<User>;
  currentUserSettings: Loadable<UserSettings>;
  teamMembers: Loadable<OrderedIdMap<number, User>>;
  creatingTeamMembers: Loadable<void>;
  updatingUser: Loadable<User>;
  updatingUserSettings: Loadable<UserSettings>;
  deletingTeamMember: Loadable<void>;
  makingTeamMemberAdmin: Loadable<void>;
  removingTeamMemberAdmin: Loadable<void>;
}

export interface User {
  id: number;
  ext_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role?: string;
  telegram_username: string | null;
  discord_username: string | null;
  has_joined_discord: boolean;
  is_staff: boolean;
  is_active: boolean;
  accepted_invite: boolean;
  team_id: number;
  date_joined: number;
  is_billing_admin: boolean;
}

export interface UpdateUserParams {
  first_name: string;
  last_name: string;
  role?: string;
  telegram_username: string | null;
  discord_username: string | null;
  has_joined_discord: boolean;
}

export interface UserSettings {
  user_id: number;
  alert_soft_rate_limit: boolean;
  alert_hard_rate_limit: boolean;
  alert_api_error: boolean;
  send_growth_emails: boolean;
  has_dismissed_invite_users_banner: boolean;
  has_dismissed_browse_requests_alert: boolean;
  has_dismissed_api_explorer_alert: boolean;
  has_dismissed_rearranged_tabs_alert: boolean;
  has_dismissed_roadmap_alert: boolean;
  has_dismissed_solana_alert: boolean;
}

export interface UpdateUserSettingsParams {
  alert_soft_rate_limit: boolean;
  alert_hard_rate_limit: boolean;
  alert_api_error: boolean;
  has_dismissed_invite_users_banner: boolean;
  has_dismissed_browse_requests_alert: boolean;
  has_dismissed_api_explorer_alert: boolean;
  has_dismissed_rearranged_tabs_alert: boolean;
  has_dismissed_solana_alert: boolean;
}

export interface CreateAndInviteTeamMembersParams {
  emails: string[];
}

export interface TeamMemberIdParams {
  user_id: number;
}

const requestCurrentUser = makeLoadingActionCreators<void, User>(
  "REQUEST_CURRENT_USER",
);

const requestTeamMembers = makeLoadingActionCreators<
  void,
  OrderedIdMap<number, User>
>("REQUEST_TEAM_MEMBERS");

const requestDeleteTeamMember = makeLoadingActionCreators<
  TeamMemberIdParams,
  void
>("REQUEST_DELETE_TEAM_MEMBER");

const requestMakeTeamMemberAdmin = makeLoadingActionCreators<
  TeamMemberIdParams,
  void
>("REQUEST_MAKE_TEAM_MEMBER_ADMIN");

const requestRemoveTeamMemberAdmin = makeLoadingActionCreators<
  TeamMemberIdParams,
  void
>("REQUEST_REMOVE_TEAM_MEMBER_ADMIN");

const requestCreateTeamMembers = makeLoadingActionCreators<
  CreateAndInviteTeamMembersParams,
  void
>("REQUEST_CREATE_TEAM_MEMBERS");

export const resetCreateTeamMembers = actionCreator(
  "RESET_CREATE_TEAM_MEMBERS",
);

const requestUpdateUser = makeLoadingActionCreators<UpdateUserParams, User>(
  "REQUEST_UPDATE_USER",
);

export const resetUpdateUser = actionCreator("RESET_UPDATE_USER");

const requestCurrentUserSettings = makeLoadingActionCreators<
  void,
  UserSettings
>("REQUEST_CURRENT_USER_SETTINGS");

const requestUpdateUserSettings = makeLoadingActionCreators<
  UpdateUserSettingsParams,
  UserSettings
>("REQUEST_UPDATE_USER_SETTINGS");

export const resetUpdateUserSettings = actionCreator(
  "RESET_UPDATE_USER_SETTINGS",
);

export const fetchCurrentUser = makeFetchThunkActionCreator<void, User>({
  actionCreators: requestCurrentUser,
  getFromState: (state) => state.users.currentUser,
  fetchResult: http.getUser,
});

export const fetchCurrentUserSettings = makeFetchThunkActionCreator<
  void,
  UserSettings
>({
  actionCreators: requestCurrentUserSettings,
  getFromState: (state) => state.users.currentUserSettings,
  fetchResult: http.getUserSettings,
});

export const fetchTeamMembers = makeFetchThunkActionCreator<
  void,
  OrderedIdMap<number, User>
>({
  actionCreators: requestTeamMembers,
  getFromState: (state) => state.users.teamMembers,
  fetchResult: () =>
    http
      .getTeamMembers()
      .then((users) => orderedMapFromArray(users, (user) => user.id)),
});

export const createTeamMembers = makeFetchThunkActionCreator<
  CreateAndInviteTeamMembersParams,
  void
>({
  actionCreators: requestCreateTeamMembers,
  getFromState: (state) => state.users.creatingTeamMembers,
  fetchResult: (params) => http.createAndInviteTeamMembers(params),
});

// Should it be team members because I want to update that whole thing?!?
export const deleteTeamMember = makeFetchThunkActionCreator<
  TeamMemberIdParams,
  void
>({
  actionCreators: requestDeleteTeamMember,
  getFromState: (state) => state.users.deletingTeamMember,
  fetchResult: (params) => http.deleteUser(params),
});

export const makeTeamMemberAdmin = makeFetchThunkActionCreator<
  TeamMemberIdParams,
  void
>({
  actionCreators: requestMakeTeamMemberAdmin,
  getFromState: (state) => state.users.makingTeamMemberAdmin,
  fetchResult: (params) => http.makeUserAdmin(params),
});

export const removeTeamMemberAdmin = makeFetchThunkActionCreator<
  TeamMemberIdParams,
  void
>({
  actionCreators: requestRemoveTeamMemberAdmin,
  getFromState: (state) => state.users.removingTeamMemberAdmin,
  fetchResult: (params) => http.removeUserAdmin(params),
});

export const updateUser = makeFetchThunkActionCreator<UpdateUserParams, User>({
  actionCreators: requestUpdateUser,
  getFromState: (state) => state.users.updatingUser,
  fetchResult: (params) => http.updateUser(params),
});

export const updateUserSettings = makeFetchThunkActionCreator<
  UpdateUserSettingsParams,
  UserSettings
>({
  actionCreators: requestUpdateUserSettings,
  getFromState: (state) => state.users.updatingUserSettings,
  fetchResult: (params) => http.updateUserSettings(params),
});

export const usersReducer: Reducer<UsersState> = combineReducers({
  currentUser: reducerBuilderForLoadable(requestCurrentUser)
    .case(requestUpdateUser.done, (state, { result }) =>
      Loadable.setDone(state, result),
    )
    .case(requestRemoveTeamMemberAdmin.done, (state, { params }) => {
      return Loadable.map(state, (user) =>
        user.id === params.params.user_id
          ? { ...user, is_billing_admin: false }
          : user,
      );
    })
    .case(requestDeleteTeamMember.done, (state, { params }) => {
      return Loadable.map(state, (user) =>
        user.id === params.params.user_id
          ? { ...user, is_active: false }
          : user,
      );
    })
    .build(),
  currentUserSettings: reducerBuilderForLoadable(requestCurrentUserSettings)
    .case(requestUpdateUserSettings.done, (state, { result }) =>
      Loadable.setDone(state, result),
    )
    .case(requestUpdateRateLimitSettings.done, (_, { result }) =>
      Loadable.of(result.value.user_settings),
    )
    .build(),
  teamMembers: reducerBuilderForLoadable(requestTeamMembers)
    .case(requestDeleteTeamMember.done, (state, { params }) =>
      Loadable.map(state, (map) =>
        deleteFromOrderedIdMap(map, params.params.user_id),
      ),
    )
    .case(requestMakeTeamMemberAdmin.done, (state, { params }) =>
      Loadable.map(state, (map) =>
        updateInOrderedIdMap(map, params.params.user_id, (user) => {
          return { ...user, is_billing_admin: true };
        }),
      ),
    )
    .case(requestRemoveTeamMemberAdmin.done, (state, { params }) =>
      Loadable.map(state, (map) =>
        updateInOrderedIdMap(map, params.params.user_id, (user) => {
          return { ...user, is_billing_admin: false };
        }),
      ),
    )
    .build(),
  creatingTeamMembers: reducerBuilderForLoadable(requestCreateTeamMembers)
    .case(resetCreateTeamMembers, () => Loadable.unloaded())
    .build(),
  updatingUser: reducerBuilderForLoadable(requestUpdateUser)
    .case(resetUpdateUser, () => Loadable.unloaded())
    .build(),
  updatingUserSettings: reducerBuilderForLoadable(requestUpdateUserSettings)
    .case(resetUpdateUserSettings, () => Loadable.unloaded())
    .build(),
  deletingTeamMember: reducerBuilderForLoadable(requestDeleteTeamMember)
    .case(requestDeleteTeamMember.done, () => Loadable.unloaded())
    .build(),
  makingTeamMemberAdmin: reducerBuilderForLoadable(requestMakeTeamMemberAdmin)
    .case(requestMakeTeamMemberAdmin.done, () => Loadable.unloaded())
    .build(),
  removingTeamMemberAdmin: reducerBuilderForLoadable(
    requestRemoveTeamMemberAdmin,
  )
    .case(requestRemoveTeamMemberAdmin.done, () => Loadable.unloaded())
    .build(),
});

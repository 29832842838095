type ViewAsParams = { view_as?: string; view_as_team?: string };

const VIEW_AS_USER_KEY = "view_as";
const VIEW_AS_TEAM_KEY = "view_as_team";

/**
 * Returns the view_as and view_as_team values in the current URL, if they
 * exist.
 */
export function getViewAsId(): ViewAsParams | undefined {
  const viewAsString = new URLSearchParams(window.location.search).get(
    VIEW_AS_USER_KEY,
  );
  if (viewAsString) {
    return { view_as: viewAsString };
  }
  const viewAsTeamString = new URLSearchParams(window.location.search).get(
    VIEW_AS_TEAM_KEY,
  );
  if (viewAsTeamString) {
    return { view_as_team: viewAsTeamString };
  }
  return undefined;
}

/**
 * [DEPRECATED] Returns the view_as and view_as_team parameters as a query string without
 * the question mark.
 * For example "view_as=123".
 * @deprecated Use `preserveViewAsInPath` instead.
 */
export function getViewParam(params: ViewAsParams): string {
  const searchParams = new URLSearchParams();
  if (params.view_as) {
    searchParams.set(VIEW_AS_USER_KEY, params.view_as);
  }
  if (params.view_as_team) {
    searchParams.set(VIEW_AS_TEAM_KEY, params.view_as_team);
  }
  return searchParams.toString();
}

/**
 * Copies over the view_as and view_as_team parameters from the current URL
 * to the given URL path, if they exist.
 * @param currentUrlOrPathStr location.href
 */
export function preserveViewAsInPath(
  currentUrlOrPathStr: string,
  targetPathStr: string,
) {
  const url = new URL(targetPathStr, "http://thisisignored.com");
  const windowUrl = new URL(currentUrlOrPathStr, "http://thisisignored.com");

  const viewAs = windowUrl.searchParams.get(VIEW_AS_USER_KEY);
  if (viewAs) {
    url.searchParams.set(VIEW_AS_USER_KEY, viewAs);
  }

  const viewAsTeam = windowUrl.searchParams.get(VIEW_AS_TEAM_KEY);
  if (viewAsTeam) {
    url.searchParams.set(VIEW_AS_TEAM_KEY, viewAsTeam);
  }

  return `${url.pathname}${url.search}${url.hash}`;
}

import styled from "@emotion/styled";
import WarningCircleIcon from "./WarningCircleIcon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: "#414348";
  padding: 30px 0;
`;

const Title = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const Icon = styled(WarningCircleIcon)`
  height: 30px;
  margin-right: 20px;
  color: "#414348";
`;

const Errors = styled.div``;

const ErrorSection = styled.div`
  margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
  text-align: center;
`;

/**
 * @deprecated Use ErrorView from @alch/ui
 */
const ErrorPage = ({ message }: { message?: string }) => {
  return (
    <Container>
      <Title>
        <Icon /> Oops, something went wrong.
      </Title>
      <Errors>
        <ErrorSection>
          <ErrorMessage>{message}</ErrorMessage>
        </ErrorSection>
      </Errors>
    </Container>
  );
};

export default ErrorPage;

import { AnnouncementBanner } from "@alch/ui";
import { SubscriptionTier } from "@util/constants.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/root";

const MS_TO_DAYS_MULT = 1000 * 60 * 60 * 24;
const CONTACT_US_URL =
  "mailto:vip@alchemyapi.io?subject=Sign%20Up%20for%20an%20Alchemy%20Contract&body=Hey%2C%20we'd%20like%20to%20sign%20up%20for%20a%20contract%20to%20use%20Alchemy%20as%20our%20node%20infrastructure%20platform.%0D%0A%0D%0AThanks!";

const TrialAnnouncementBanner = () => {
  const team = useSelector((state: RootState) => state.team.team.value);
  const planPreference = useSelector(
    (state: RootState) => state.team.planPreference,
  );

  if (
    !team ||
    !team.free_trial_duration_days ||
    !team.free_trial_start_time ||
    !planPreference.hasValue ||
    planPreference.value.tier !== SubscriptionTier.OTHER
  ) {
    return null;
  }

  const freeTrialStartTime = team.free_trial_start_time;
  const freeTrialDurationDays = team.free_trial_duration_days;

  const endTime = freeTrialStartTime + freeTrialDurationDays * MS_TO_DAYS_MULT;
  const timeRemaining = Math.max(0, endTime - Date.now());
  const daysLeft = Math.ceil(timeRemaining / MS_TO_DAYS_MULT);
  if (daysLeft) {
    const formattedDay = daysLeft === 1 ? " day" : " days";
    return (
      <AnnouncementBanner>
        {`${daysLeft}${formattedDay} left in your VIP trial! 🎉`}
      </AnnouncementBanner>
    );
  }
  return (
    <AnnouncementBanner intent="warning">
      Your trial has expired!{" "}
      <AnnouncementBanner.Link
        href={CONTACT_US_URL}
        target="_blank"
        rel="noreferrer"
      >
        Contact us.
      </AnnouncementBanner.Link>
    </AnnouncementBanner>
  );
};

export default TrialAnnouncementBanner;

import { useBreakpointValue } from "@alch/ui";
import classNames from "classnames";
import { HTMLMotionProps, motion } from "framer-motion";
import { useCallback, useState } from "react";

import { analyticsEvent, EventPrefix } from "@util/analytics";
import NavButton from "./NavButton";
import NavButtonGroup from "./NavButtonGroup";
import NavFooter from "./NavFooter";
import NavHeader from "./NavHeader";
import NavScrollArea from "./NavScrollArea";
import NavSearch from "./NavSearch";
import {
  InternalNavLink,
  isGroupItem,
  isNestedItem,
  NavItem,
  NavLink,
  NestedNavItem,
} from "./useNavItems";

interface NavPanelProps extends HTMLMotionProps<"div"> {
  width: number | string;
  items: NavItem[];
  onHideOverlay?: () => void;
  activeItem: InternalNavLink | undefined;
  transitionDuration: number;
}

const NavPanel = ({
  className,
  width,
  items,
  onHideOverlay,
  activeItem,
  transitionDuration,
  ...props
}: NavPanelProps) => {
  const [hoverItem, setHoverItem] = useState<NestedNavItem>();
  const responsiveHoverItem = useBreakpointValue({
    base: hoverItem,
    md: undefined,
  });

  const asOverlay = !!onHideOverlay;

  const handleItemClick = useCallback(
    (item: NavLink | NestedNavItem) => {
      if (isNestedItem(item)) {
        setHoverItem(item);
      } else {
        analyticsEvent(`${EventPrefix.Navbar}: Clicked ${item.label}`);
        item.onClick?.();
        onHideOverlay?.();
      }
    },
    [onHideOverlay],
  );

  const resetHoverItem = useCallback(() => {
    setHoverItem(undefined);
  }, []);

  return (
    <motion.div
      {...props}
      className={classNames(
        "z-10 flex flex-col transition-[border-radius] duration-200 md:rounded-2xl bg-contrast-darkest",
        className,
      )}
      initial={asOverlay ? { opacity: 0, y: 100 } : false}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      style={{ width }}
      transition={{ duration: asOverlay ? 0.15 : transitionDuration }}
    >
      <NavHeader
        onLogoClick={onHideOverlay}
        onHideOverlay={
          !asOverlay ? undefined : hoverItem ? resetHoverItem : undefined
        }
      />

      {responsiveHoverItem ? null : (
        <NavSearch onHideOverlay={asOverlay ? onHideOverlay : undefined} />
      )}

      <NavScrollArea
        className="min-h-0 flex-1"
        shadowClassName="from-contrast-darkest"
      >
        <ul className="flex flex-col">
          {(responsiveHoverItem?.items ?? items).map((item) =>
            isGroupItem(item) ? (
              <NavButtonGroup key={item.title} group={item}>
                {item.items.map((item) => (
                  <NavButton
                    key={item.label}
                    item={item}
                    activeItem={activeItem}
                    onClick={handleItemClick}
                  />
                ))}
              </NavButtonGroup>
            ) : (
              <NavButton
                key={item.label}
                item={item}
                activeItem={activeItem}
                onClick={handleItemClick}
              />
            ),
          )}
        </ul>
      </NavScrollArea>

      <NavFooter onHideOverlay={asOverlay ? onHideOverlay : undefined} />
    </motion.div>
  );
};

export default NavPanel;

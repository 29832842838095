import isEqual from "lodash/isEqual";

import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

/**
 * Returns a ref whose `.current` value always contains the most recently
 * updated version of `value`. This is useful for passing to callbacks to ensure
 * that when the callback is called, the most recent value is used.
 */
export function useLatestRef<T>(value: T): MutableRefObject<T> {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useAutoincrement(): () => number {
  const nextIdRef = useRef(0);
  return () => nextIdRef.current++;
}

/**
 * Returns a function which behaves like `window.setTimeout()` except that its
 * tasks are automatically cancelled when the component unmounts. Unlike
 * `window.setTimeout()` it returns a cancellation function rather than a
 * timeout ID.
 */
export function useManagedSetTimeout(): (
  f: () => void,
  timeout: number,
) => () => void {
  const timeoutIds = useRef(new Set<number>()).current;
  useEffect(
    () => () => {
      timeoutIds.forEach((timeoutId) => window.clearTimeout(timeoutId));
      timeoutIds.clear();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME
    [],
  );
  return useCallback((f, timeout) => {
    const timeoutId = window.setTimeout(() => {
      f();
      timeoutIds.delete(timeoutId);
    }, timeout);
    timeoutIds.add(timeoutId);
    return () => {
      window.clearTimeout(timeoutId);
      timeoutIds.delete(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME
  }, []);
}

export function useDelay(): (timeout: number) => Promise<void> {
  const managedSetTimeout = useManagedSetTimeout();
  return (timeout) =>
    new Promise((resolve) => managedSetTimeout(resolve, timeout));
}

export function usePageVisibility(): { isVisible: boolean } {
  const [isVisible, setIsVisible] = useState(!document.hidden);
  useEffect(() => {
    function handleVisibilityChange(): void {
      setIsVisible(!document.hidden);
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);
  return { isVisible };
}

// Thanks to
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/ for
// inspiration.
export function useInterval(f: () => void, timeout: number): void {
  const latestCallback = useLatestRef(f);
  useEffect(() => {
    const intervalId = window.setInterval(() => {
      if (latestCallback.current) {
        latestCallback.current();
      }
    }, timeout);
    return () => window.clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME
  }, [timeout]);
}

export function usePageTitle(title: string): void {
  const oldTitle = useRef(document.title).current;
  useEffect(() => {
    document.title = title;
    return () => {
      document.title = oldTitle;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME
  }, [title]);
}

// useEffect but it skips the initial render: functions like componentDidUpdate.
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
export function useEffectOnUpdate(fn: any, inputs: any) {
  const didMountRef = useRef(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- FIXME
    if (didMountRef.current) fn();
    else didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-unsafe-argument -- FIXME
  }, inputs);
}

/*
 * Loads a script, returning a value produced by that script or an error if
 * loading that script is done.
 *
 * This is superior to various `useScript` implementations found online because
 * none of the ones I found would correctly handle the case where the requested
 * script was already added but had not finished loading. Such implementations
 * report the script as already loaded rather than correctly waiting for it to
 * complete and then reporting it loaded.
 *
 * For an example of a broken `useScript` implementation, see:
 * https://usehooks.com/useScript/
 *
 * Example usage:
 * ```ts
 * const [$, error] = useScript<JQueryStatic>(
 *   "https://code.jquery.com/jquery-3.5.1.min.js",
 *   () => (window as any).$,
 * );
 *
 * useEffect(() => {
 *   if (error) {
 *     console.error("Failed to load jQuery:", error);
 *     return;
 *   }
 *   if (!$) {
 *     return;
 *   }
 *   // Do stuff with $
 * }, [$, error])
 * ```
 *
 * */
export function useScript<T>(
  src: string,
  getLoadedValue: () => T,
): [T | undefined, Error | undefined] {
  interface State {
    value?: T;
    error?: Error;
  }

  const [state, setState] = useState<State>({});
  const getLoadedValueRef = useLatestRef(getLoadedValue);

  useEffect(() => {
    const alreadyLoadedValue = getLoadedValueRef.current();
    if (alreadyLoadedValue) {
      setState({ value: alreadyLoadedValue, error: undefined });
      return;
    }
    const script = getOrCreateScript(src);

    function handleLoad(): void {
      const value = getLoadedValueRef.current();
      if (value === undefined) {
        setState({
          error: new Error(
            `Script from ${src} completed but no loaded value present`,
          ),
        });
        return;
      }
      setState({ value });
    }

    function handleError(event: ErrorEvent): void {
      script.parentElement?.removeChild(script);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
      setState({ error: event.error });
    }

    script.addEventListener("load", handleLoad);
    script.addEventListener("error", handleError);
    return () => {
      script.removeEventListener("load", handleLoad);
      script.removeEventListener("error", handleError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME
  }, [src]);

  return [state.value, state.error];
}

function getOrCreateScript(src: string): HTMLScriptElement {
  const existingScript = document.querySelector(`script[src="${src}"]`);
  if (existingScript) {
    return existingScript as HTMLScriptElement;
  }
  const script = document.createElement("script");
  script.src = src;
  document.body.appendChild(script);
  return script;
}

// Like useEffect but does a deep compare of dependencies instead of comparing by reference
// https://marcoghiani.com/blog/how-to-use-the-react-hook-usedeepeffect
export function useDeepEffect(effectFunc: () => void, deps: unknown[]) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isSame = prevDeps.current.every((obj, index) =>
      isEqual(obj, deps[index]),
    );

    if (isFirst.current || !isSame) {
      effectFunc();
    }

    isFirst.current = false;
    prevDeps.current = deps;
  }, [deps, effectFunc]);
}

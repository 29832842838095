import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { getStoredAuthToken } from "@util/auth";
import { getUser } from "../../http/endpoints";

export const USER_KEY = ["user"];

function buildQueryOptions() {
  return queryOptions({
    queryKey: USER_KEY,
    queryFn: () => getUser(),
    refetchOnWindowFocus: false,
  });
}

const useCurrentUser = () => {
  return useQuery({
    ...buildQueryOptions(),
    refetchOnWindowFocus: false,
    // Disable query on public pages
    enabled: !!getStoredAuthToken(),
  });
};

export function useSuspenseCurrentUser() {
  return useSuspenseQuery(buildQueryOptions());
}

export default useCurrentUser;

export type Comparator<T> = (a: T, b: T) => number;
export type KeyExtractor<T> =
  | ((item: T) => number | null | undefined)
  | ((item: T) => string | null | undefined)
  | ((item: T) => boolean | null | undefined)
  | ComparatorExtractor<T>;

export interface ComparatorExtractor<T> {
  comparator: Comparator<T>;
}

/**
 * This is a hacky function for quickly assembling comparators to pass to
 * sorting or min/max functions. It's similar to Lodash's sortBy, but more
 * flexible in that it supports easily specifying that nulls should be placed
 * first or last, and it produces a comparator function that can be passed
 * around rather than immediately sorting an array.
 *
 * There's probably a better API for this sort of thing that already exists, but
 * I couldn't find one and this is good enough for now.
 *
 * Examples:
 *
 * * `compareBy(x => x.lastUpdated, x => x.id)`
 * * `compareBy(nullsFirst(x => x.completionTime), withReversed(x => x.count))`
 */
export function compareBy<T>(
  ...extractors: Array<KeyExtractor<T>>
): Comparator<T> {
  return (a, b) => {
    for (const extractor of extractors) {
      if (typeof extractor === "object") {
        const value = extractor.comparator(a, b);
        if (value !== 0) {
          return value;
        }
      } else {
        const keyA = extractor(a);
        const keyB = extractor(b);
        if (keyA == null) {
          return keyB == null ? 0 : -1;
        }
        if (keyB == null) {
          return 1;
        }
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
      }
    }
    return 0;
  };
}

export function withComparator<T>(comparator: Comparator<T>): KeyExtractor<T> {
  return { comparator };
}

export function nullsFirst<T>(extractor: KeyExtractor<T>): KeyExtractor<T> {
  return withComparator(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- FIXME
    compareBy((x) => (x == null ? 0 : 1), extractor as any),
  );
}

export function nullsLast<T>(extractor: KeyExtractor<T>): KeyExtractor<T> {
  return withComparator(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- FIXME
    compareBy((x) => (x == null ? 1 : 0), extractor as any),
  );
}

export function withReversed<T>(extractor: KeyExtractor<T>): KeyExtractor<T> {
  return withComparator((a, b) => -compareBy(extractor)(a, b));
}

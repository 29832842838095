export enum ScriptStatus {
  LOADED = "loaded",
  ERROR = "error",
}

export const SCRIPT_STATUS_ATTRIBUTE = "script-status";

export function dynamicScriptLoad(
  url: string,
  attributes: Record<string, string> = {},
) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;

  Object.entries(attributes).forEach(([key, value]) => {
    script.setAttribute(key, value);
  });

  script.onload = () => {
    script.setAttribute(SCRIPT_STATUS_ATTRIBUTE, ScriptStatus.LOADED);
  };
  script.onerror = () => {
    script.setAttribute(SCRIPT_STATUS_ATTRIBUTE, ScriptStatus.ERROR);
  };

  document.head.appendChild(script);
}

export function isScriptLoaded(id: string): boolean {
  const script = document.getElementById(id);

  return (
    script !== null &&
    script.getAttribute(SCRIPT_STATUS_ATTRIBUTE) === ScriptStatus.LOADED
  );
}

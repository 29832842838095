import { AppRequest } from "@features/query-search/appRequest";
import { combineReducers, Reducer } from "redux";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerForIndexedLoadables,
} from "../util/loadable";
import { AppIdParams } from "./apps";

export interface AppRequestsState {
  requests: { [appId: string]: Loadable<AppRequest[]> };
  errorRequests: { [appId: string]: Loadable<AppRequest[]> };
  rateLimitedRequests: { [appId: string]: Loadable<AppRequest[]> };
}

const requestAppRequests = makeLoadingActionCreators<AppIdParams, AppRequest[]>(
  "REQUEST_APP_REQUESTS",
);

export const fetchAppRequests = makeFetchThunkActionCreator<
  AppIdParams,
  AppRequest[]
>({
  actionCreators: requestAppRequests,
  getFromState: (state, params) =>
    state.appRequests.requests[params.app_id] || Loadable.unloaded(),
  fetchResult: http.getAppRequests,
});

const requestAppErrorRequests = makeLoadingActionCreators<
  AppIdParams,
  AppRequest[]
>("REQUEST_APP_ERROR_REQUESTS");

export const fetchAppErrorRequests = makeFetchThunkActionCreator<
  AppIdParams,
  AppRequest[]
>({
  actionCreators: requestAppErrorRequests,
  getFromState: (state, params) =>
    state.appRequests.errorRequests[params.app_id] || Loadable.unloaded(),
  fetchResult: http.getAppErrorRequests,
});

const requestAppRateLimitedRequests = makeLoadingActionCreators<
  AppIdParams,
  AppRequest[]
>("REQUEST_APP_RATE_LIMITED_REQUESTS");

export const fetchAppRateLimitedRequests = makeFetchThunkActionCreator<
  AppIdParams,
  AppRequest[]
>({
  actionCreators: requestAppRateLimitedRequests,
  getFromState: (state, params) =>
    state.appRequests.rateLimitedRequests[params.app_id] || Loadable.unloaded(),
  fetchResult: http.getAppRateLimitedRequests,
});

export const appRequestsReducer: Reducer<AppRequestsState> = combineReducers({
  requests: reducerForIndexedLoadables(
    requestAppRequests,
    (params) => params.app_id,
  ),
  errorRequests: reducerForIndexedLoadables(
    requestAppErrorRequests,
    (params) => params.app_id,
  ),
  rateLimitedRequests: reducerForIndexedLoadables(
    requestAppRateLimitedRequests,
    (params) => params.app_id,
  ),
});

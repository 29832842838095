import promoBanner from "@features/signup/Pages/PaymentPage/promo-banner.svg";

const PaymentPromoBanner = () => {
  return (
    <div
      className="w-full rounded-lg"
      style={{ backgroundImage: `url(${promoBanner})` }}
    >
      <p className="relative z-20 mx-4 my-2 text-left text-paragraph-100-medium text-white">
        🔥 Add card today, receive 1 million extra free compute units for the
        first month.
      </p>
    </div>
  );
};

export default PaymentPromoBanner;

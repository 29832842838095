import { NetworkInfo } from "@alch/dx-entities";
import React from "react";
import { Emblem } from "./emblem";
import { networkEmblemUrl } from "./helpers";

interface NetworkEmblemProps extends React.HTMLAttributes<HTMLDivElement> {
  networkInfo: Pick<NetworkInfo, "name" | "chainId" | "isTestNet">;
}

const BaseEmblem = (
  { networkInfo, ...props }: NetworkEmblemProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) => {
  const { name, chainId, isTestNet } = networkInfo;
  return (
    <Emblem
      imageAlt={name}
      imageUrl={networkEmblemUrl(chainId, isTestNet)}
      ref={ref}
      {...props}
    />
  );
};

export default React.forwardRef(BaseEmblem);

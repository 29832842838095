// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerForLoadable,
} from "../util/loadable";

export interface GlobalSettings {
  banner_message: string;
  banner_link_text: string;
  banner_link_url: string;
}

export type GlobalSettingsState = Loadable<GlobalSettings>;

const requestGlobalSettings = makeLoadingActionCreators<void, GlobalSettings>(
  "REQUEST_GLOBAL_SETTINGS",
);

export const fetchGlobalSettings = makeFetchThunkActionCreator<
  void,
  GlobalSettings
>({
  actionCreators: requestGlobalSettings,
  getFromState: (state) => state.globalSettings,
  fetchResult: http.getGlobalSettings,
});

export const globalSettingsReducer = reducerForLoadable(requestGlobalSettings);

import { SignupStep, SignupStepPathMap } from "@features/signup/constants";
import ActiveDotOff from "@static/signup/progress-bar/progress-bar-active-off.svg?react";
import ActiveDotOn from "@static/signup/progress-bar/progress-bar-active-on.svg?react";
import CompleteDot from "@static/signup/progress-bar/progress-bar-complete.svg?react";
import InactiveDot from "@static/signup/progress-bar/progress-bar-inactive.svg?react";
import Line from "@static/signup/progress-bar/progress-bar-line.svg?react";
import { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";

interface ProgressBarProps {
  steps: string[];
  currentStep: string;
}

const ProgressBar = ({ steps, currentStep }: ProgressBarProps) => {
  const currentStepIndex = steps.indexOf(currentStep);

  const progressItems = useMemo(
    () =>
      steps.map((step, i) => {
        // Don't display enterprise details progress step
        if (step === SignupStep.EnterpriseDetails) {
          return null;
        }

        let Dot = <InactiveDot />;
        let lineColor = "#E2E8F0";

        if (i < currentStepIndex) {
          Dot = <CompleteDot />;
          lineColor = "#B8E6FF";
        }
        if (i === currentStepIndex) {
          Dot = (
            <div className="relative">
              <ActiveDotOff />
              <ActiveDotOn className="absolute right-0 top-0 animate-[fade-in-out_1800ms_infinite_ease-in-out]" />
            </div>
          );
        }

        return (
          <Fragment key={`progress-item-${i}`}>
            {i < currentStepIndex ? (
              <Link to={SignupStepPathMap[step as SignupStep]}>{Dot}</Link>
            ) : (
              Dot
            )}
            {i + 1 < steps.length && <Line stroke={lineColor} />}
          </Fragment>
        );
      }),
    [currentStepIndex, steps],
  );

  return <div className="flex flex-row items-center">{progressItems}</div>;
};

export default ProgressBar;

import { ChainId, NetworkId } from "@alch/dx-entities";
import {
  ChainDataType,
  PipelineDestinationType,
} from "@features/data-platform/constants.ts";

export enum PipelineStatus {
  Created = "created",
  Running = "running",
  Unknown = "unknown",
  Stopped = "stopped",
  Finished = "finished",
  Failed = "failed",
}

export interface Pipeline {
  id: number | string;
  teamId: number | string;
  name: string;
  datasource: {
    name: string;
    network: NetworkId;
  };
  sink: {
    name: string;
    host: string;
    port: string;
    database: string;
  };
  table: {
    schema: string;
    name: string;
  };
  status: {
    desired: DesiredPipelineStatus;
    display: DisplayPipelineStatus;
    backfill: ActualPipelineStatus;
    frontfill: ActualPipelineStatus;
  };
  editability: {
    status: boolean;
    reason?: string;
  };
  filters: FiltersParams;
  createdAt: number;
}

export enum DatasourceFilterType {
  ContractAddresses = "contract_addresses",
  StartBlock = "start_block",
  FromAddress = "from_address",
  FromAddresses = "from_addresses",
  ToAddress = "to_address",
  ToAddresses = "to_addresses",
  ABI = "abi",
}

export enum DestinationFieldType {
  DestinationName = "destination_name",
  Database = "database",
  Endpoint = "endpoint",
  Port = "port",
  Username = "username",
  Password = "password",
  Schema = "schema",
  TableName = "table_name",
}

export const DESTINATION_TYPE_FIELDS: Record<
  PipelineDestinationType,
  DestinationFieldType[]
> = {
  [PipelineDestinationType.POSTGRES]: [
    DestinationFieldType.DestinationName,
    DestinationFieldType.Database,
    DestinationFieldType.Endpoint,
    DestinationFieldType.Port,
    DestinationFieldType.Username,
    DestinationFieldType.Password,
    DestinationFieldType.Schema,
    DestinationFieldType.TableName,
  ],
  [PipelineDestinationType.KAFKA]: [],
  [PipelineDestinationType.MONGODB]: [],
  [PipelineDestinationType.AWSS3]: [],
  [PipelineDestinationType.MYSQL]: [],
};

export const DESTINATION_DOCS: Partial<
  Record<PipelineDestinationType, string>
> = {
  [PipelineDestinationType.POSTGRES]:
    "https://alchemotion.notion.site/Alchemy-Pipelines-Docs-v0-external-4f6f3d4d0dbd45e2b6fe0897c5d6102c",
};

export interface Datasource {
  name: string;
  network: NetworkId;
  chain: ChainId;
  dataType: ChainDataType;
  schema: Array<{
    name: string;
    type: string;
    isNullable: false;
  }>;
  filterTypes: DatasourceFilterType[];
}

export enum DesiredPipelineStatus {
  Unknown = "unknown",
  Running = "running",
  Stopped = "stopped",
}

export enum ActualPipelineStatus {
  Created = "created",
  Running = "running",
  Stopped = "stopped",
}

export enum DisplayPipelineStatus {
  Created = "created",
  Starting = "starting",
  Running = "running",
  Stopped = "stopped",
  Unknown = "unknown",
}

export interface SinkCredentials {
  username: string;
  password: string;
}

export interface FiltersParams {
  contract_addresses?: string[];
  start_block?: number;
  from_address?: string;
  to_address?: string;
  abi?: string;
}

export interface PipelineSinkCopy {
  copyFromID: number;
}

export interface PipelineSinkFull {
  name: string;
  host: string;
  port: string;
  database: string;
  credentials: SinkCredentials;
}

export type PipelineSink = PipelineSinkCopy | PipelineSinkFull;

export interface CreatePipelineRequestParams {
  name: string;
  datasource: {
    name: string;
    network: NetworkId;
  };
  sink: PipelineSink;
  table: {
    schema: string;
    name: string;
  };
  filters?: FiltersParams;
}

export interface CreatePipelineSuccessResult {
  pipelineId: number;
}

export enum SinkValidationType {
  Connection = "connection",
  SchemaExists = "schema_exists",
  CreateTable = "create_table",
  DropTable = "drop_table",
  InsertRecord = "insert_record",
  DeleteRecord = "delete_record",
}

export enum CreatePipelineFormTarget {
  Connection = "connection",
  Credentials = "credentials",
  Database = "database",
  Host = "host",
  Table = "table",
  FilterContractAddress = "filter_contract_address",
  FilterFromAddress = "filter_from_address",
  FilterToAddress = "filter_to_address",
  FilterStartBlock = "filter_start_block",
  FilterABI = "filter_abi",
}

export interface CreatePipelineFailureResult {
  validationType?: SinkValidationType | "unknown";
  target?: CreatePipelineFormTarget;
  message: string;
}

export interface CreatePipelineSuccessResult {
  pipelineId: number;
}

export interface EventMetrics {
  pipelineId: number;
  numEvents: number;
  backfill: {
    numExpectedRows: number | null;
    numProcessedRows: number | null;
    numProcessedBytes: number | null;
    latestBlockNum: number | null;
    latestState: {
      state: string;
      enteredAt: number;
    };
    lastUpdatedAt: number;
  };
  frontfill: {
    numProcessedRows: number | null;
    numProcessedBytes: number | null;
    latestBlockNum: number | null;
    latestState: {
      state: string;
      enteredAt: number;
    };
    lastUpdatedAt: number;
  };
}

export interface GetPipelineMetricsSuccessResult {
  rawMetrics: EventMetrics;
  summary: {
    backfillProgress: number;
    pipelineLatestBlockNum: number | null;
    alchemyLatestBlockNum: number | null;
    blockLag: number | null;
  };
}

export enum WebhookEventJobType {
  Backfill = "backfill",
  Frontfill = "frontfill",
  Unknown = "unknown",
}

export enum WebhookEventState {
  Created = "created",
  Failed = "failed",
  Canceled = "canceled",
  Finished = "finished",
  Unknown = "unknown",
}

export interface GetWebhookEventSuccessResult {
  state: WebhookEventState;
  jobType: WebhookEventJobType;
  happenedAt: number;
  exception: string | null;
}

export interface ListWebhookEventsSuccessResult {
  events: GetWebhookEventSuccessResult[];
}

import {
  TextField as BaseTextField,
  TextFieldProps as BaseTextFieldProps,
} from "@alch/ui";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

type TextFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = BaseTextFieldProps & {
  control: Control<TFieldValues>;
  name: TName;
  formatValue?: (value: TFieldValues[TName] | string) => string;
};

const TextField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  formatValue,
  ...props
}: TextFieldProps<TFieldValues, TName>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  return (
    <BaseTextField
      {...props}
      {...field}
      value={formatValue ? formatValue(field.value) : field.value}
      errorMessage={error?.message}
    />
  );
};

export default TextField;

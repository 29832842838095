import { z } from "zod";

export const fieldValidation = {
  required: (name: string) => `${name} is required.`,
  maxLength: (name: string, maxLength: number) =>
    `${name} can be at most ${maxLength} character${
      maxLength === 1 ? "" : "s"
    } long.`,
  minLength: (name: string, minLength: number) =>
    `${name} must be at least ${minLength} character${
      minLength === 1 ? "" : "s"
    } long.`,
};

export function stringFieldSchema(opts: {
  name: string;
  min?: number;
  max?: number;
}) {
  const { name, min, max } = opts;

  let schema = z.string().trim();

  if (min !== undefined) {
    schema = schema.min(min, fieldValidation.minLength(name, min));
  }

  if (max !== undefined) {
    schema = schema.max(max, fieldValidation.maxLength(name, max));
  }

  return schema;
}

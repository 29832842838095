import { DEPLOY_ENV } from "./deployEnv";

export const CHARGIFY_SITE_URL = {
  production: "https://alchemy.chargify.com",
  staging: "https://alchemyapi-io-staging.chargify.com",
  development: "https://alchemyapi-io-staging.chargify.com",
}[DEPLOY_ENV];

export const CHARGIFY_JS_PUBLIC_KEY = {
  production: "chjs_jpv8rmsy3kwdx7bjfb583fd2",
  staging: "chjs_fzy7bw4sjg8xqk4xrr3824vp",
  development: "chjs_fzy7bw4sjg8xqk4xrr3824vp",
}[DEPLOY_ENV];

export const DJANGO_API_ORIGIN = {
  production: "https://dashboard.alchemy.com",
  staging: "https://dashboard.alchemypreview.com",
  development: "http://localhost:8000",
}[DEPLOY_ENV];

export const CHAT_WEB_3_HTTP_ORIGIN = {
  production: "https://chat-web3-api.alchemy.com",
  staging: "https://chat-web3-api.alchemypreview.com",
  development: "http://localhost:9999",
}[DEPLOY_ENV];

export const CHAT_WEB_3_WS_ORIGIN = {
  production: "wss://chat-web3-api.alchemy.com",
  staging: "wss://chat-web3-api.alchemypreview.com",
  development: "ws://localhost:9999",
}[DEPLOY_ENV];

export const SENTRY_DSN = {
  production: "https://fdd86e3a79fa4a90bf2c81bda63b1655@sentry.io/1385137",
  staging:
    "https://e85491d25d364c49850d7f6377af7f37@o22138.ingest.sentry.io/1415901",
  development:
    "https://e85491d25d364c49850d7f6377af7f37@o22138.ingest.sentry.io/1415901",
}[DEPLOY_ENV];

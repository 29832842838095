import { TRPCRouter } from "@alch/dx-webapp-api";
import {
  createTRPCReact,
  httpBatchLink,
  httpLink,
  splitLink,
} from "@trpc/react-query";
import {
  createHttpLinksOpt,
  TrpcProviderProps,
} from "@util/trpc/trpcClientUtils.ts";
import { API_ORIGIN } from "../deployEnv";

export const trpc = createTRPCReact<TRPCRouter>({});

const httpLinkOpts = createHttpLinksOpt(API_ORIGIN);

export const trpcClient = trpc.createClient({
  links: [
    splitLink({
      condition(op) {
        // Conditionally enable HTTP batching
        return op.context.enableBatch === true;
      },
      false: httpLink(httpLinkOpts),
      true: httpBatchLink(httpLinkOpts),
    }),
  ],
});

export const WebappTrpc: TrpcProviderProps<TRPCRouter> = {
  trpc,
  trpcClient,
};

import { EventPrefix, analyticsEvent } from "@util/analytics";
import {
  getStoredAuthToken,
  redirectToAuthchemy,
  setStoredAuthToken,
} from "@util/auth";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSearchParam, { SearchParam } from "./useSearchParam";

const useAuthRedirect = () => {
  const { search } = useLocation();
  const history = useHistory();
  const inboundAuthToken = useSearchParam(SearchParam.AuthToken);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    // If authchemy passed us an authToken then store the token, and reload the same URL without the authToken query string parameter
    if (inboundAuthToken) {
      setStoredAuthToken(inboundAuthToken);
      searchParams.delete(SearchParam.AuthToken);
      searchParams.delete(SearchParam.RedirectAttempt);
      history.replace({ ...history.location, search: searchParams.toString() });

      analyticsEvent(`${EventPrefix.Auth}: Signed in to dashboard`);
    }

    const authToken = getStoredAuthToken();
    // If no stored authToken, skip the status check and redirect the user to Authchemy to login
    if (!authToken) {
      redirectToAuthchemy();
    }
  }, [history, inboundAuthToken, search]);
};

export default useAuthRedirect;

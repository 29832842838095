import { isEmpty, isNil, pickBy } from "lodash";

export const omitEmptyValues = <T extends object>(obj: T) => {
  return pickBy(
    obj,
    (val) =>
      // isEmpty works only for collections and strings
      typeof val === "boolean" ||
      (typeof val === "number" && !Number.isNaN(val)) ||
      !isEmpty(val),
  );
};

export const omitNilValues = <T extends object>(obj: T) => {
  return pickBy(obj, (val) => !isNil(val));
};

export const hasProperty = <T extends object>(
  obj: T,
  prop: PropertyKey,
): prop is keyof T => {
  return Object.prototype.hasOwnProperty.call(obj, prop);
};

import {
  SelectField as BaseSelectField,
  SelectFieldProps as BaseSelectFieldProps,
} from "@alch/ui";
import { useCallback, useMemo } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

type SelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Value,
  MultiSelect extends boolean,
  MultiValue extends MultiSelect extends true ? Value[] : Value,
> = Omit<BaseSelectFieldProps<Value, MultiSelect>, "value" | "inputRef"> & {
  control: Control<TFieldValues>;
  name: TName;
  transformSelect?: (value: MultiValue) => MultiValue;
  transformValue?: (value: MultiValue | undefined) => MultiValue;
};

const SelectField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Value,
  MultiSelect extends boolean = false,
  MultiValue extends MultiSelect extends true
    ? Value[]
    : Value = MultiSelect extends true ? Value[] : Value,
>({
  control,
  name,
  transformSelect,
  transformValue,
  onSelect,
  ...props
}: SelectFieldProps<TFieldValues, TName, Value, MultiSelect, MultiValue>) => {
  const {
    field: { value, onChange, onBlur, ...fieldProps },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) onBlur();
    },
    [onBlur],
  );

  const handleSelect = useCallback(
    (value: MultiSelect extends true ? Value[] : Value) => {
      onChange(transformSelect ? transformSelect(value as MultiValue) : value);
      onSelect?.(value);
    },
    [onChange, transformSelect, onSelect],
  );

  const transformedValue = useMemo(() => {
    return transformValue ? transformValue(value) : value;
  }, [value, transformValue]);

  return (
    <BaseSelectField
      {...props}
      {...fieldProps}
      value={transformedValue}
      onSelect={handleSelect}
      errorMessage={error?.message}
      onOpenChange={handleOpenChange}
    />
  );
};

export default SelectField;

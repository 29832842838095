import { NetworkId } from "@alch/dx-entities";
import { combineReducers, Reducer } from "redux";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerForLoadable,
} from "../util/loadable";
import { TransactionStatus } from "../util/protobufs";

export interface MempoolState {
  transaction: Loadable<MempoolTransaction>;
  filteredTransactions: Loadable<MempoolTransactionsBlob>;
  numFilteredTransactions: Loadable<number>;
}

export interface MempoolTransactionsBlob {
  transactions: MempoolTransaction[];
}
export interface MempoolTransaction {
  id: number;
  app_id: string | null;
  network_id: NetworkId;
  tx_data: string | null;
  tx_hash: string;
  eth_node: string | null;
  from_address: string | null;
  to_address: string | null;
  nonce_int: number | null;
  gas: string | null;
  block_number: string | null;
  txn_status: TransactionStatus;
  request_timestamp: number | null;
  status_timestamp: number | null;
  max_fee_per_gas: string | null;
  max_priority_fee_per_gas: string | null;
  type: string | null;
  value: string | null;
  app_name?: string;
}

export interface TransactionParams {
  hash: string;
}

export interface FilteredTransactionParams {
  filters: TransactionFilters;
}

export interface TransactionFilters {
  app_ids?: string[];
  tx_hash?: string;
  from_address?: string;
  to_address?: string;
  time_min?: number;
  time_max?: number;
  status?: number;
  search_input?: string;
  network_id?: NetworkId;
}

const requestMempoolTransaction = makeLoadingActionCreators<
  TransactionParams,
  MempoolTransaction
>("REQUEST_TRANSACTION");

const requestNumFilteredTransactions = makeLoadingActionCreators<
  FilteredTransactionParams,
  number
>("REQUEST_NUM_FILTERED_TRANSACTIONS");

const requestFilteredTransactions = makeLoadingActionCreators<
  FilteredTransactionParams,
  MempoolTransactionsBlob
>("REQUEST_FILTERED_TRANSACTIONS");

export const fetchMempoolTransaction = makeFetchThunkActionCreator<
  TransactionParams,
  MempoolTransaction
>({
  actionCreators: requestMempoolTransaction,
  getFromState: (state) => state.mempool.transaction,
  fetchResult: http.getMempoolTransaction,
});

export const fetchFilteredTransactions = makeFetchThunkActionCreator<
  FilteredTransactionParams,
  MempoolTransactionsBlob
>({
  actionCreators: requestFilteredTransactions,
  getFromState: (state) => state.mempool.filteredTransactions,
  fetchResult: http.getFilteredTransactions,
});

export const fetchNumFilteredTransactions = makeFetchThunkActionCreator<
  FilteredTransactionParams,
  number
>({
  actionCreators: requestNumFilteredTransactions,
  getFromState: (state) => state.mempool.numFilteredTransactions,
  fetchResult: http.getNumFilteredTransactions,
});

export const mempoolReducer: Reducer<MempoolState> = combineReducers({
  transaction: reducerForLoadable(requestMempoolTransaction),
  filteredTransactions: reducerForLoadable(requestFilteredTransactions),
  numFilteredTransactions: reducerForLoadable(requestNumFilteredTransactions),
});

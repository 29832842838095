import pureAssign from "pure-assign";
import { Omit } from "react-redux";
import { Reducer } from "redux";
import { createSelector } from "reselect";
import actionCreatorFactory, { AsyncActionCreators } from "typescript-fsa";
import {
  ReducerBuilder,
  reducerWithInitialState,
} from "typescript-fsa-reducers";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { AppDispatch, AppThunkAction, RootState } from "../redux/root";
import { IS_DEVELOPMENT } from "./deployEnv";

const actionCreator = actionCreatorFactory();

// If you're reading this trying to figure out the interface of `Loadable`, see
// `Loadable.Base` below. This definition is just here to make discriminated
// unions work nicely on the field `hasValue`.
export type Loadable<T> = Loadable.WithValue<T> | Loadable.WithoutValue<T>;

// tslint:disable-next-line: no-namespace
// eslint-disable-next-line @typescript-eslint/no-namespace -- FIXME
declare namespace Loadable {
  interface Base<T> {
    isLoading: boolean;
    hasValue: boolean;
    value?: T;
    error?: LoadableError;
    updateTime?: number;
    _loadingKey?: number;
  }

  interface WithValue<T> extends Base<T> {
    hasValue: true;
    value: T;
    updateTime: number;
  }

  interface WithoutValue<T> extends Base<T> {
    hasValue: false;
  }
}

export const Loadable = {
  unloaded,
  of,
  map,
  combine,
  setStarted,
  setDone,
  setFailed,
};

export interface LoadingParams<P> {
  params: P;
  loadingKey: number;
}

export interface LoadableError {
  message: string;
}

interface Loaded<T> {
  value: T;
  updateTime: number;
}

let nextLoadingKey = 1;
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
const loadingPromisesByKey: { [id: number]: ReplaceablePromise<any> } = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
const UNLOADED: Loadable.WithoutValue<any> = {
  isLoading: false,
  hasValue: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
function unloaded<T = any>(): Loadable.WithoutValue<T> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME
  return UNLOADED;
}

function of<T>(value: T, updateTime = Date.now()): Loadable.WithValue<T> {
  return {
    value,
    updateTime,
    isLoading: false,
    hasValue: true,
  };
}

function map<T, U>(loadable: Loadable<T>, f: (value: T) => U): Loadable<U> {
  return removeLoadingKey(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME
    loadable.hasValue
      ? {
          ...loadable,
          value: f(loadable.value),
        }
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
        { ...(loadable as any) },
  );
}

function combine<T, U, V>(
  loadable1: Loadable<T>,
  loadable2: Loadable<U>,
  f: (value1: T, value2: U) => V,
): Loadable<V> {
  const isLoading = loadable1.isLoading || loadable2.isLoading;
  const error = loadable1.error || loadable2.error;
  if (loadable1.hasValue && loadable2.hasValue) {
    const value = f(loadable1.value, loadable2.value);
    const updateTime = Math.min(loadable1.updateTime, loadable2.updateTime);
    return {
      isLoading,
      value,
      updateTime,
      error,
      hasValue: true,
    };
  }
  return { isLoading, error, hasValue: false };
}

function setStarted<T>(
  loadable: Loadable<T>,
  loadingKey?: number,
): Loadable<T> {
  return { ...loadable, isLoading: true, _loadingKey: loadingKey };
}

function setDone<T>(loadable: Loadable<T>, loaded: Loaded<T>): Loadable<T> {
  return removeLoadingKey({
    ...loadable,
    ...loaded,
    hasValue: true,
    isLoading: false,
    error: undefined,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
function setFailed<T>(loadable: Loadable<T>, error: any): Loadable<T> {
  const newLoadable: Loadable<T> = {
    ...loadable,
    error: toLoadableError(error),
    isLoading: false,
  };
  return removeLoadingKey(newLoadable);
}

export type LoadingActionCreators<P, T> = AsyncActionCreators<
  LoadingParams<P>,
  Loaded<T>
>;

export function makeLoadingActionCreators<P, T>(
  type: string,
): LoadingActionCreators<P, T> {
  return actionCreator.async<LoadingParams<P>, Loaded<T>>(type);
}

/**
 * Conditional type for creating thunk actions in order to be assignable to `()
 * => void` rather than `(x: void) => void` in the case where params are `void.
 */
type ThunkActionCreator<P, T> = P extends void
  ? () => AppThunkAction<Promise<T>>
  : (params: P) => AppThunkAction<Promise<T>>;

export function makeFetchThunkActionCreator<P, T>({
  actionCreators,
  restartOnAdditionalCalls = false,
  getFromState,
  fetchResult,
}: {
  actionCreators: LoadingActionCreators<P, T>;
  restartOnAdditionalCalls?: boolean;
  getFromState(state: RootState, params: P): Loadable<T>;
  fetchResult(params: P, getState: () => RootState): Promise<T>;
}): ThunkActionCreator<P, T> {
  // Unfortunately we need to do type coercion in this function to handle the
  // conditional return type, but it should be safe.
  const result: (params: P) => AppThunkAction<void> =
    (params) => async (dispatch: AppDispatch, getState: () => RootState) => {
      const { _loadingKey } = getFromState(getState(), params);
      const existingPromise =
        _loadingKey != null && loadingPromisesByKey[_loadingKey];

      if (existingPromise) {
        if (restartOnAdditionalCalls) {
          existingPromise.replace(fetchResult(params, getState));
        }
        return existingPromise.promise;
      }
      const loadingKey = nextLoadingKey++;
      const replaceablePromise = makeReplaceablePromise<T>(
        fetchResult(params, getState),
      );
      loadingPromisesByKey[loadingKey] = replaceablePromise;
      const loadingParams = { params, loadingKey };
      dispatch(actionCreators.started(loadingParams));
      try {
        const value = await replaceablePromise.promise;
        dispatch(
          actionCreators.done({
            params: loadingParams,
            result: { value, updateTime: Date.now() },
          }),
        );
        return value;
      } catch (error) {
        if (IS_DEVELOPMENT) {
          console.error(error);
        }

        dispatch(
          actionCreators.failed({
            params: loadingParams,
            error: toLoadableError(error),
          }),
        );
      } finally {
        delete loadingPromisesByKey[loadingKey];
      }
    };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any -- FIXME
  return result as any;
}

interface ReplaceablePromise<T> {
  promise: Promise<T>;
  replace(promise: Promise<T>): void;
}

/**
 * Returns a promise that wraps a backing promise. The backing promise can be
 * swapped out with a new promise, and the wrapping promise will not complete
 * unless the latest backing promise completes.
 */
function makeReplaceablePromise<T>(
  initialPromise: Promise<T>,
): ReplaceablePromise<T> {
  let currentPromise: Promise<T>;
  let resolve: (result: T) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
  let reject: (error: any) => void;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  const replace = async (replacementPromise: Promise<T>) => {
    currentPromise = replacementPromise;
    try {
      const result = await replacementPromise;
      if (currentPromise === replacementPromise) {
        resolve(result);
      }
    } catch (error) {
      if (currentPromise === replacementPromise) {
        reject(error);
      }
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME
  replace(initialPromise);
  // eslint-disable-next-line @typescript-eslint/no-misused-promises -- FIXME
  return { promise, replace };
}

export interface CustomLoadableReducerConfig<P, T, S> {
  actionCreators: LoadingActionCreators<P, T>;
  initialState: S;
  getLoadable: (state: S, params: P) => Loadable<T>;
  setLoadable: (state: S, params: P, loadable: Loadable<T>) => S;
}

export function reducerForLoadable<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
  actionCreators: AsyncActionCreators<any, Loaded<T>>,
): Reducer<Loadable<T>> {
  return reducerBuilderForLoadable(actionCreators).build();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
export function reducerForIndexedLoadables<P, T, K extends keyof any>(
  actionCreators: LoadingActionCreators<P, T>,
  getKeyFromParams: (params: P) => K,
): Reducer<Record<K, Loadable<T>>> {
  return reducerBuilderForIndexedLoadables(
    actionCreators,
    getKeyFromParams,
  ).build();
}

export function reducerForCustomLoadable<P, T, S>(
  config: CustomLoadableReducerConfig<P, T, S>,
): Reducer<S> {
  return reducerBuilderForCustomLoadable(config).build();
}

export function reducerBuilderForLoadable<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
  actionCreators: AsyncActionCreators<any, Loaded<T>>,
): ReducerBuilder<Loadable<T>> {
  return reducerWithInitialState<Loadable<T>>(unloaded<T>()).withHandling(
    handleLoadInReducer(actionCreators),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
export function reducerBuilderForIndexedLoadables<P, T, K extends keyof any>(
  actionCreators: LoadingActionCreators<P, T>,
  getKeyFromParams: (params: P) => K,
): ReducerBuilder<Record<K, Loadable<T>>> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME
  return reducerWithInitialState({}).withHandling(
    handleIndexedLoadInReducer(actionCreators, getKeyFromParams),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
  ) as any;
}

export function reducerBuilderForCustomLoadable<P, T, S>({
  actionCreators,
  initialState,
  getLoadable,
  setLoadable,
}: CustomLoadableReducerConfig<P, T, S>): ReducerBuilder<S> {
  return reducerWithInitialState(initialState).withHandling(
    handleCustomLoadInReducer({
      actionCreators,
      getLoadable,
      setLoadable,
    }),
  );
}

function handleLoadInReducer<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
  actionCreators: AsyncActionCreators<any, Loaded<T>>,
): (builder: ReducerBuilder<Loadable<T>>) => ReducerBuilder<Loadable<T>> {
  return handleCustomLoadInReducer({
    actionCreators,
    getLoadable: (state) => state,
    setLoadable: (_, __, loadable) => loadable,
  });
}

function handleIndexedLoadInReducer<P, T>(
  actionCreators: LoadingActionCreators<P, T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
  getKeyFromParams: (params: P) => keyof any,
): (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
  builder: ReducerBuilder<Record<keyof any, Loadable<T>>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
) => ReducerBuilder<Record<keyof any, Loadable<T>>> {
  return handleCustomLoadInReducer({
    actionCreators,
    getLoadable: (state, params) =>
      state[getKeyFromParams(params) as string | number] || unloaded(),
    setLoadable: (state, params, loadable) =>
      pureAssign(state, { [getKeyFromParams(params)]: loadable } as Record<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
        keyof any,
        Loadable<T>
      >),
  });
}

function handleCustomLoadInReducer<P, T, S>({
  actionCreators,
  getLoadable,
  setLoadable,
}: Omit<CustomLoadableReducerConfig<P, T, S>, "initialState">): (
  builder: ReducerBuilder<S>,
) => ReducerBuilder<S> {
  return (builder) =>
    builder
      .case(actionCreators.started, (state, { params, loadingKey }) =>
        setLoadable(
          state,
          params,
          setStarted(getLoadable(state, params), loadingKey),
        ),
      )
      .case(actionCreators.done, (state, { params: { params }, result }) =>
        setLoadable(state, params, setDone(getLoadable(state, params), result)),
      )
      .case(actionCreators.failed, (state, { params: { params }, error }) =>
        setLoadable(
          state,
          params,
          setFailed(getLoadable(state, params), error),
        ),
      );
}

export function makeMappedLoadableSelector<P, T, U>(
  getLoadable: (props: P) => Loadable<T>,
  f: (value: T) => U,
): (props: P) => Loadable<U> {
  return createSelector(getLoadable, (loadable) => Loadable.map(loadable, f));
}

export function makeCombinedLoadableSelector<P, T, U, V>(
  getLoadable1: (props: P) => Loadable<T>,
  getLoadable2: (props: P) => Loadable<U>,
  f: (value1: T, value2: U) => V,
): (props: P) => Loadable<V> {
  return createSelector(getLoadable1, getLoadable2, (value1, value2) =>
    combine(value1, value2, f),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
function toLoadableError(error: any): LoadableError {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
  const message: string =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME
    error && error.message && typeof error.message === "string"
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME
        error.message
      : "";
  return { message };
}

function removeLoadingKey<T>(loadable: Loadable<T>): Loadable<T> {
  delete loadable._loadingKey;
  return loadable;
}

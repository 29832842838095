import { combineReducers, Reducer } from "redux";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerBuilderForLoadable,
  reducerForLoadable,
} from "../util/loadable";

export interface PaymentsState {
  creditCard: Loadable<CreditCardInfo | undefined>;
  invoices: Loadable<Invoice[]>;
  openInvoices: Loadable<OpenInvoice[]>;
  billingDetails: Loadable<BillingDetails | undefined>;
  updatingBillingDetails: Loadable<void>;
}

export interface CreditCardInfo {
  first_name: string;
  last_name: string;
  masked_card_number?: string;
  card_type: string;
  expiration_month: number;
  expiration_year: number;
  billing_address: string;
  billing_address_2: string;
  billing_city: string;
  billing_state: string;
  billing_zip: string;
  billing_country: string;
}

export interface BillingDetails {
  organization: string;
  vat_number: string | null;
  cc_emails: string[];
}

export interface UpdateBillingDetailsParams {
  vat_number: string | null;
  cc_emails: string[];
}

export interface Invoice {
  timestamp: number;
  description: string;
  status: string;
  card_brand: string | undefined;
  card_last_four: string | undefined;
  public_url: string;
}

export interface OpenInvoice {
  id: string;
  issue_date_timestamp: number;
  days_until_cutoff: number;
}

const requestPaymentProfile = makeLoadingActionCreators<
  void,
  CreditCardInfo | undefined
>("REQUEST_PAYMENT_PROFILE");

const requestInvoices = makeLoadingActionCreators<void, Invoice[]>(
  "REQUEST_INVOICES",
);

const requestOpenInvoices = makeLoadingActionCreators<void, OpenInvoice[]>(
  "REQUEST_OPEN_INVOICES",
);

const requestBillingDetails = makeLoadingActionCreators<
  void,
  BillingDetails | undefined
>("REQUEST_BILLING_DETAILS");

const requestUpdateBillingDetails = makeLoadingActionCreators<
  UpdateBillingDetailsParams,
  void
>("REQUEST_UPDATE_BILLING_DETAILS");

export const fetchPaymentProfile = makeFetchThunkActionCreator<
  void,
  CreditCardInfo | undefined
>({
  actionCreators: requestPaymentProfile,
  getFromState: (state) => state.payments.creditCard,
  fetchResult: http.getPaymentProfile,
});

export const fetchBillingDetails = makeFetchThunkActionCreator<
  void,
  BillingDetails | undefined
>({
  actionCreators: requestBillingDetails,
  getFromState: (state) => state.payments.billingDetails,
  fetchResult: http.getBillingDetails,
});

export const updateBillingDetails = makeFetchThunkActionCreator<
  UpdateBillingDetailsParams,
  void
>({
  actionCreators: requestUpdateBillingDetails,
  getFromState: (state) => state.payments.updatingBillingDetails,
  fetchResult: (params) => http.updateBillingDetails(params),
});

export const fetchInvoices = makeFetchThunkActionCreator<void, Invoice[]>({
  actionCreators: requestInvoices,
  getFromState: (state) => state.payments.invoices,
  fetchResult: http.getInvoices,
});

export const fetchOpenInvoices = makeFetchThunkActionCreator<
  void,
  OpenInvoice[]
>({
  actionCreators: requestOpenInvoices,
  getFromState: (state) => state.payments.openInvoices,
  fetchResult: http.getOpenInvoices,
});

export const paymentsReducer: Reducer<PaymentsState> = combineReducers({
  creditCard: reducerForLoadable(requestPaymentProfile),
  invoices: reducerForLoadable(requestInvoices),
  openInvoices: reducerForLoadable(requestOpenInvoices),
  billingDetails: reducerForLoadable(requestBillingDetails),
  updatingBillingDetails: reducerBuilderForLoadable(requestUpdateBillingDetails)
    .case(requestUpdateBillingDetails.done, () => Loadable.unloaded())
    .build(),
});

import clsx from "clsx";
import { forwardRef } from "react";
import unknownChainEmblem from "./unknown-chain-emblem.svg";
interface EmblemProps extends React.HTMLAttributes<HTMLDivElement> {
  imageUrl: string;
  imageAlt: string;
}

const BaseEmblem = (
  { imageUrl, imageAlt, className, ...props }: EmblemProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) => {
  return (
    <div ref={ref} {...props} className={clsx(className, "flex shrink-0")}>
      <img
        src={imageUrl}
        alt={imageAlt}
        className="flex-1"
        // If the image fails to load, this placeholder image will show as the icon
        onError={(e) => (e.currentTarget.src = unknownChainEmblem)}
      />
    </div>
  );
};

export const Emblem = forwardRef(BaseEmblem);

export const createEmblemComponent = (imageUrl: string, imageAlt: string) => {
  const EmblemComponent = (
    props: React.HTMLAttributes<HTMLDivElement>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => <Emblem ref={ref} imageAlt={imageAlt} imageUrl={imageUrl} {...props} />;

  EmblemComponent.displayName = `${imageAlt.split(" ").join("")}Emblem`;

  return forwardRef(EmblemComponent);
};

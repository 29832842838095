import PlacesAutoCompleteDropdown, {
  PlacesAddress,
} from "@features/signup/Pages/PaymentPage/PlacesAutoCompleteDropdown";
import { SignupFormFieldName } from "@features/signup/constants";
import { sortBy } from "lodash";
import { UseFormReturn } from "react-hook-form";
import {
  SelectField as ControlSelectField,
  TextField as ControlTextField,
} from "../../../../components/ui/index";
import countries from "./countries.json";

const COUNTRY_ITEMS = sortBy(
  Object.values(countries).map((value) => ({
    label: value,
    value,
  })),
  "label",
);

interface RenderPrefilledBillingInfoInput {
  address: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
}

// Pass prefilled billing info to Chargify (https://developers.chargify.com/docs/developer-docs/91a92b3772f9f-overview#passing-pre-filled-customer-information)
const renderPrefilledBillingInfo = (
  formValues: RenderPrefilledBillingInfoInput,
) => (
  <>
    {Object.entries({ ...formValues, address2: "" }).map(
      ([key, value]: [string, string]) => {
        return (
          <div className="host-field" key={key}>
            <input
              type="hidden"
              className="host-input"
              data-chargify={key}
              value={value}
            />
          </div>
        );
      },
    )}
  </>
);

interface BillingInfoFormContentProps {
  form: Omit<UseFormReturn, "handleSubmit">;
}

const BillingInfoFormContent = ({ form }: BillingInfoFormContentProps) => {
  const {
    control,
    setValue: setFieldValue,
    clearErrors,
    trigger,
    watch,
  } = form;

  const [address, city, state, country, zipCode] = watch([
    SignupFormFieldName.Address,
    SignupFormFieldName.City,
    SignupFormFieldName.State,
    SignupFormFieldName.Country,
    SignupFormFieldName.ZipCode,
  ]) as string[];

  const handleSelectAddress = async (address: PlacesAddress) => {
    clearErrors();
    setFieldValue(SignupFormFieldName.Address, address.name, {
      shouldDirty: true,
    });
    setFieldValue(SignupFormFieldName.City, address.locality, {
      shouldDirty: true,
    });
    setFieldValue(SignupFormFieldName.State, address.administrativeAreaLevel1, {
      shouldDirty: true,
    });
    setFieldValue(SignupFormFieldName.Country, address.country, {
      shouldDirty: true,
    });
    setFieldValue(SignupFormFieldName.ZipCode, address.postalCode, {
      shouldDirty: true,
    });
    // We need to explicitly trigger validation after selecting the autocomplete field
    await trigger();
  };

  const handleAddressChange = (addressString: string) => {
    setFieldValue(SignupFormFieldName.Address, addressString, {
      shouldDirty: true,
    });
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-y-6">
        <PlacesAutoCompleteDropdown
          onSelectAddress={handleSelectAddress}
          onAddressChange={handleAddressChange}
        />
        <div className="grid grid-cols-2 gap-x-3 gap-y-6">
          <ControlTextField
            name={SignupFormFieldName.City}
            control={control}
            label="City"
            autoComplete="address-level2"
          />
          <ControlTextField
            name={SignupFormFieldName.State}
            control={control}
            label="State/Province"
            autoComplete="address-level1"
          />
          <ControlSelectField
            control={control}
            name={SignupFormFieldName.Country}
            items={COUNTRY_ITEMS}
            label="Country"
          />
          <ControlTextField
            name={SignupFormFieldName.ZipCode}
            control={control}
            label="Zip"
            autoComplete="postal-code"
          />
        </div>
      </div>
      {renderPrefilledBillingInfo({ address, city, state, country, zipCode })}
    </>
  );
};

export default BillingInfoFormContent;

import { SubscriptionTier } from "@util/constants";
import { Url } from "@util/urls";

import usePlanPreference from "@features/profile/PlanPage/hooks/usePlanPreference";
import useTeam from "./useTeam";

const useTeamSupportUrl = () => {
  const team = useTeam();
  const planPreference = usePlanPreference();

  if (team.isError || planPreference.isError) {
    return { isError: true, error: team.error || planPreference.error };
  }

  if (team.isPending || planPreference.isPending) {
    return { isLoading: true };
  }

  const isEnterprise = [
    SubscriptionTier.ENTERPRISE,
    SubscriptionTier.OTHER,
  ].includes(planPreference.data.tier);

  const isTelegramUrl = team.data.support_channel_url?.includes("t.me");

  return {
    data: {
      isEnterprise,
      isVip: isTelegramUrl,
      url: isTelegramUrl ? team.data.support_channel_url : Url.DiscordSupport,
    },
  };
};

export default useTeamSupportUrl;

import React from "react";

interface IconTextProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
}

const IconText = ({ text, Icon }: IconTextProps) => {
  return (
    <div className="flex items-center">
      <Icon />
      <div className="w-2" />
      <div className="text-paragraph-200-regular text-grayscale-700">
        {text}
      </div>
    </div>
  );
};

export default IconText;

import {
  AwsDynamoDbLogo,
  KafkaLogo,
  MongoDBLogo,
  MySqlLogo,
  PostgresLogo,
} from "@alch/ui/assets/logos";
import { Grid04, Layers03 } from "@alch/ui/icons/16";
import {
  AlignLeft,
  ArrowSwitchHorizontal,
  CoinUnbroken,
  MessageText02,
  Scale01,
} from "@alch/ui/icons/20";
import { createEmblemComponent } from "@components/ui/icons/emblem";

export enum ChainDataType {
  BLOCKS = "BLOCKS",
  RAW_LOGS = "RAW_LOGS",
  DECODED_LOGS = "DECODED_LOGS",
  TRANSACTIONS = "TRANSACTIONS",
  TOKEN_TRANSFERS = "TOKEN_TRANSFERS",
  TOKEN_BALANCES = "TOKEN_BALANCES",
  TRACES = "TRACES",
}

export const CHAIN_DATA_TYPE_INFO: Record<
  ChainDataType,
  {
    dataType: ChainDataType;
    logoColored: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    name: string;
    order: number;
  }
> = {
  [ChainDataType.BLOCKS]: {
    order: 0,
    dataType: ChainDataType.BLOCKS,
    name: "Blocks",
    logoColored: Grid04,
  },
  [ChainDataType.RAW_LOGS]: {
    order: 1,
    dataType: ChainDataType.RAW_LOGS,
    name: "Raw Logs",
    logoColored: AlignLeft,
  },
  [ChainDataType.DECODED_LOGS]: {
    order: 2,
    dataType: ChainDataType.DECODED_LOGS,
    name: "Decoded Logs",
    logoColored: MessageText02,
  },
  [ChainDataType.TRANSACTIONS]: {
    order: 3,
    dataType: ChainDataType.TRANSACTIONS,
    name: "Transactions",
    logoColored: Layers03,
  },
  [ChainDataType.TRACES]: {
    order: 3,
    dataType: ChainDataType.TRACES,
    name: "Traces",
    logoColored: Scale01,
  },
  [ChainDataType.TOKEN_BALANCES]: {
    order: 3,
    dataType: ChainDataType.TOKEN_BALANCES,
    name: "Token Balances",
    logoColored: CoinUnbroken,
  },
  [ChainDataType.TOKEN_TRANSFERS]: {
    order: 3,
    dataType: ChainDataType.TOKEN_TRANSFERS,
    name: "Token Transfers",
    logoColored: ArrowSwitchHorizontal,
  },
};

export enum PipelineDestinationType {
  POSTGRES = "POSTGRES",
  KAFKA = "KAFKA",
  MONGODB = "MONGODB",
  AWSS3 = "AWSS3",
  MYSQL = "MYSQL",
}

export const PIPELINE_DESTINATION_TYPE_INFO: Record<
  PipelineDestinationType,
  {
    type: PipelineDestinationType;
    label: string;
    icon: React.FunctionComponent<{ className?: string }>;
    order: number;
  }
> = {
  [PipelineDestinationType.POSTGRES]: {
    type: PipelineDestinationType.POSTGRES,
    label: "PostgresSQL",
    icon: createEmblemComponent(PostgresLogo, "PostgresSQL"),
    order: 0,
  },
  [PipelineDestinationType.KAFKA]: {
    type: PipelineDestinationType.KAFKA,
    label: "Kafka",
    icon: createEmblemComponent(KafkaLogo, "Kafka"),
    order: 1,
  },
  [PipelineDestinationType.MONGODB]: {
    type: PipelineDestinationType.MONGODB,
    label: "MongoDB",
    icon: createEmblemComponent(MongoDBLogo, "MongoDB"),
    order: 2,
  },
  [PipelineDestinationType.AWSS3]: {
    type: PipelineDestinationType.AWSS3,
    label: "AWS S3",
    icon: createEmblemComponent(AwsDynamoDbLogo, "AWS S3"),
    order: 3,
  },
  [PipelineDestinationType.MYSQL]: {
    type: PipelineDestinationType.MYSQL,
    label: "MySQL",
    icon: createEmblemComponent(MySqlLogo, "MySQL"),
    order: 4,
  },
};

import { SignupFormData } from "@features/signup/schemas";
import React, { createContext, useContext, useState } from "react";

interface SignupFlowContextType {
  formData: Partial<SignupFormData>;
  setFormData: React.Dispatch<React.SetStateAction<Partial<SignupFormData>>>;
}

export const SignupFlowContext = createContext<
  SignupFlowContextType | undefined
>(undefined);

export const useSignupFlowFormData = () => {
  const context = useContext(SignupFlowContext);
  if (!context) {
    throw new Error(
      "useSignupFlowFormData must be used within the SignupFlowProvider",
    );
  }
  return context;
};

interface SignupFlowProviderProps {
  children: React.ReactNode;
}

const SignupFlowProvider = ({ children }: SignupFlowProviderProps) => {
  const [formData, setFormData] = useState<Partial<SignupFormData>>({});

  return (
    <SignupFlowContext.Provider value={{ formData, setFormData }}>
      {children}
    </SignupFlowContext.Provider>
  );
};

export default SignupFlowProvider;

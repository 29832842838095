import { combineReducers, Reducer } from "redux";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerForLoadable,
} from "../util/loadable";

// Input parameter of desired waffle switches to fetch
export interface WaffleFlagNames {
  flags?: string[];
}

// Input parameter of desired waffle flags to fetch
export interface WaffleSwitchNames {
  switches?: string[];
}

export interface WaffleState {
  flags: Loadable<WaffleFlagObj[]>;
  switches: Loadable<WaffleSwitch[]>;
}

export interface WaffleFlagObj {
  name: string;
  active: boolean;
  // Note: Do not use this for flags that depend on `authenticated`.
}

export interface WaffleSwitch {
  name: string;
  active: boolean;
}

const requestWaffleFlags = makeLoadingActionCreators<
  WaffleFlagNames,
  WaffleFlagObj[]
>("REQUEST_WAFFLE_FLAGS");
const requestWaffleSwitches = makeLoadingActionCreators<
  WaffleSwitchNames,
  WaffleSwitch[]
>("REQUEST_WAFFLE_SWITCHES");

export const waffleReducer: Reducer<WaffleState> = combineReducers({
  flags: reducerForLoadable(requestWaffleFlags),
  switches: reducerForLoadable(requestWaffleSwitches),
});

export const fetchWaffleFlags = makeFetchThunkActionCreator<
  WaffleFlagNames,
  WaffleFlagObj[]
>({
  actionCreators: requestWaffleFlags,
  getFromState: (state) => state.waffle.flags,
  fetchResult: http.getWaffleFlags,
});

export const fetchWaffleSwitches = makeFetchThunkActionCreator<
  WaffleSwitchNames,
  WaffleSwitch[]
>({
  actionCreators: requestWaffleSwitches,
  getFromState: (state) => state.waffle.switches,
  fetchResult: http.getWaffleSwitches,
});

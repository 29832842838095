import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

interface AppLayoutContextValue {
  isFullScreenPage: boolean;
  setIsFullScreenPage: Dispatch<SetStateAction<boolean>>;
}

const AppLayoutContext = createContext<AppLayoutContextValue | null>(null);

export const AppLayoutContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isFullScreenPage, setIsFullScreenPage] = useState(false);

  return (
    <AppLayoutContext.Provider
      value={{ isFullScreenPage, setIsFullScreenPage }}
    >
      {children}
    </AppLayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  const context = useContext(AppLayoutContext);

  if (!context) {
    throw new Error(
      "useLayoutContext must be used within a LayoutContextProvider",
    );
  }

  return context;
};

export const useFullScreenPageLayout = () => {
  const { setIsFullScreenPage } = useLayoutContext();

  useEffect(() => {
    setIsFullScreenPage(true);

    return () => {
      setIsFullScreenPage(false);
    };
  }, [setIsFullScreenPage]);
};

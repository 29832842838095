import classNames from "classnames";

import { NavItemGroup } from "./useNavItems";

interface NavButtonGroupProps {
  group: NavItemGroup;
  children: React.ReactNode;
}

const NavButtonGroup = ({ group, children }: NavButtonGroupProps) => {
  return (
    <li>
      <div
        className={classNames(
          "group flex w-full items-center rounded text-tertiary outline-none transition",
          "focus-visible:shadow-focus-dark",
          "mb-0.5 mt-3 px-2 py-0.5 text-paragraph-100-regular",
        )}
      >
        {group.title}
      </div>

      <ul className="-mx-1 overflow-hidden px-1">{children}</ul>
    </li>
  );
};

export default NavButtonGroup;

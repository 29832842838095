import { SetupStage } from "@alch/dx-entities";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { SubscriptionTier } from "../constants";
import { IS_PRODUCTION, IS_STAGING } from "../deployEnv";
import { identifyBrevoContact, initBrevo } from "./brevo";
import {
  captureGoogleAnalyticsConversion,
  initGoogleAnalytics,
} from "./googleAnalytics";
import {
  HeapProperties,
  addHeapOnboardingProperties,
  addHeapRecommendationProperties,
  captureHeapEvent,
  identifyHeapUser,
  initHeap,
} from "./heap";
import { identifyMavaUser } from "./mava";

export enum EventPrefix {
  SignupFlowRedesign = "SFR",
  SpendingCap = "SpendingCap",
  Sandbox = "Sandbox",
  Alerts = "Alerts",
  Onboarding = "Onboarding",
  Auth = "Auth",
  PredepositGasCredits = "PredepositGasCredits",
  HomeRecBanner = "HomeRecBanner",
  UpgradeOnboarding = "UpgradeOnboarding",
  SearchBar = "SearchBar",
  Products = "Products",
  Navbar = "Navbar",
  EmbeddedAccounts = "EmbeddedAccounts",
  Webhooks = "Webhooks",
  AppDetails = "AppDetails",
  ProductLanding = "ProductLanding",
  CreateApp = "CreateApp",
  AppSetup = "AppSetup",
  Rollups = "Rollups",
  ScheduleCallPopover = "ScheduleCallPopover",
  Chains = "Chains",
}

export const DEBUG_ANALYTICS =
  typeof window !== "undefined" &&
  !!window.localStorage.getItem("DEBUG_ANALYTICS");
let initialized = false;

// By default analytics are disabled in local dev. To enable run:
//   localStorage.setItem("ENABLE_ANALYTICS", "true")
const isEnabled = () =>
  !!window.localStorage.getItem("FORCE_ANALYTICS") ||
  ((IS_PRODUCTION ||
    IS_STAGING ||
    !!window.localStorage.getItem("ENABLE_ANALYTICS")) &&
    !currentlyViewingAs(window.location.search));

/**
 * Call this once, preferably at the root scope (not in a react render method)
 * An exception is for gatsby apps with SSR, since we don't want to run it server side
 */
export function initAnalytics() {
  if (!isEnabled() || initialized) return;

  initHeap(DEBUG_ANALYTICS);
  initGoogleAnalytics(DEBUG_ANALYTICS);
  initBrevo(DEBUG_ANALYTICS);

  initialized = true;
}

/**
 * Note: If called a second time with a different ID, this will switch to reporting events as
 * the new user.  It will not merge/alias the two users (which is good).
 *
 * Also, when using view_as, this should only be called with the original staff
 * user.  Do not identify as the user you are viewing the page as.
 */
export function setAnalyticsUser(user: {
  extId: string;
  internalId: number;
  teamId?: number; // for users who have created an account but not done onboarding
  email: string;
  isStaff: boolean;
  tier?: SubscriptionTier;
  teamName?: string;
  firstName: string;
  lastName: string;
  createdAt: number;
  isBillingAdmin: boolean;
  referredBy?: string;
  referredByAffiliate?: string;
  setupStage?: SetupStage;
  role?: string;
}): void {
  if (!isEnabled() || !initialized) return;

  identifyHeapUser(
    user.internalId,
    {
      firstName: user.firstName,
      lastName: user.lastName,
      createdAt: user.createdAt, // Integer timestamp
      email: user.email,
      teamId: user.teamId,
      teamName: user.teamName,
      isBillingAdmin: user.isBillingAdmin,
      referredBy: user.referredBy,
      referredByAffiliate: user.referredByAffiliate,
      tier: user.tier,
    },
    DEBUG_ANALYTICS,
  );
  identifyBrevoContact(
    user.email,
    {
      first_name: user.firstName,
      last_name: user.lastName,
      role: user.role,
      team_name: user.teamName,
      tier: user.tier,
      attribution_channel: user.referredBy,
    },
    DEBUG_ANALYTICS,
  );
  identifyMavaUser(
    user.internalId,
    {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      teamId: user.teamId,
      teamName: user.teamName,
      tier: user.tier,
    },
    DEBUG_ANALYTICS,
  );
}

export function logoutAnalyticsUser() {
  if (!isEnabled() || !initialized) return;
}

/**
 * Under the hood, calls heap.track(eventName, properties).
 * https://developers.heap.io/reference/track
 *
 * @param eventName name of the custom event (this defines how events are grouped), e.g. "ChatWeb3: Clicked Copy Button". Parametrize instances of the same event with properties, e.g. { "messageId": "123" }. Do not use eventName to distinguish instances of the same event -- this will break grouping. See https://alchemyinsights.slack.com/archives/C04258L1UJG/p1701113998680779?thread_ts=1701113604.711059&cid=C04258L1UJG for bad things that happen when you do this.
 * @param properties object used to enrich the event instance with additional parameters.
 */
export function analyticsEvent(eventName: string, properties?: HeapProperties) {
  if (!isEnabled() || !initialized) return;
  captureHeapEvent(eventName, properties, DEBUG_ANALYTICS);
}

export function analyticsConversionEvent() {
  if (!isEnabled() || !initialized) return;
  captureGoogleAnalyticsConversion(DEBUG_ANALYTICS);
}

export function addOnboardingProperties(props: {
  onboardingResources?: string[];
  onboardingRequest?: {
    defaultMethod: string;
    method: string;
    language: string;
  };
}) {
  if (!isEnabled() || !initialized) return;
  addHeapOnboardingProperties(props, DEBUG_ANALYTICS);
}

export function addRecommendationProperties(props: {
  homeRecommendedProduct: string;
}) {
  if (!isEnabled() || !initialized) return;
  addHeapRecommendationProperties(props, DEBUG_ANALYTICS);
}

export function currentlyViewingAs(search: string) {
  const params = new URLSearchParams(search);
  return !!(params.get("view_as") || params.get("view_as_team"));
}

import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { IS_DEVELOPMENT } from "./deployEnv";

// HTTP 400 Bad Request
export class ValidationError extends Error {
  public static displayName = "ValidationError";
  constructor(message: string | undefined) {
    super(message);
    this.name = ValidationError.displayName;
  }
}

// HTTP 401 Unauthorized
export class AuthError extends Error {
  public static displayName = "AuthError";
  constructor(message: string | undefined) {
    super(message);
    this.name = AuthError.displayName;
  }
}

// HTTP 403 Forbidden
export class PermissionError extends Error {
  public static displayName = "PermissionError";
  constructor(message: string | undefined) {
    super(message);
    this.name = PermissionError.displayName;
  }
}

const UUID_REGEX =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

// "Unparameterizes" URLs by stripping UUIDs and query params. Useful for improving Sentry issue grouping.
export function getUnparameterizedUrl(urlStr: string): string {
  const url = new URL(urlStr);
  url.search = "";
  return url.toString().replace(UUID_REGEX, "*");
}

export function logException(err: unknown) {
  if (IS_DEVELOPMENT) {
    console.error(err);
  } else {
    if (err instanceof AxiosError) {
      // Rename error before logging to Sentry to improve grouping (otherwise all AxiosErrors are grouped together)
      err.name = `${err.name}${
        err.config.url ? `: ${getUnparameterizedUrl(err.config.url)}` : ""
      }`; // e.g. AxiosError: https://api.example.com/api/v1/endpoint
    }

    Sentry.captureException(err);
  }
}

import { ChargifyInputField } from "@features/signup/constants";
import { logException } from "@util/errors";
import { useScript } from "@util/hooks";
import { CHARGIFY_JS_PUBLIC_KEY, CHARGIFY_SITE_URL } from "@util/settings";
import { useCallback, useEffect, useRef, useState } from "react";

const CHARGIFY_CONFIG = {
  publicKey: CHARGIFY_JS_PUBLIC_KEY,
  serverHost: CHARGIFY_SITE_URL,
  type: "card",
  hideCardImage: true,
  fields: {
    firstName: {
      selector: `#${ChargifyInputField.FirstName}`,
      placeholder: " ",
      required: true,
      next_focus: "lastName",
    },
    lastName: {
      selector: `#${ChargifyInputField.LastName}`,
      placeholder: " ",
      required: true,
      next_focus: "number",
    },
    number: {
      selector: `#${ChargifyInputField.CardNumber}`,
      placeholder: " ",
      required: true,
      next_focus: "month",
    },
    month: {
      selector: `#${ChargifyInputField.ExpirationMonth}`,
      required: true,
      next_focus: "year",
    },
    year: {
      selector: `#${ChargifyInputField.ExpirationYear}`,
      required: true,
    },
    cvv: {
      selector: `#${ChargifyInputField.CVV}`,
      required: true,
    },
  },
  style: {
    label: {
      marginTop: "0px",
    },
    field: {},
    input: {
      padding: "6px",
      borderRadius: "8px",
      fontSize: "1rem",
    },
    message: {
      fontSize: "0.875rem",
    },
  },
};

interface ChargifyConfig {
  publicKey: string;
  serverHost: string;
  type: string;
  hideCardImage: boolean;
  fields: Record<string, unknown>;
  style: Record<string, unknown>;
}

interface ChargifyCallbacks {
  onCardTypeDetected?: (type?: string) => void;
}

// https://developers.maxio.com/docs/developer-docs/91a92b3772f9f-overview#handling-errors
interface ChargifyError {
  status: number;
  errors: string;
}

export interface ChargifyObject {
  load: (config: ChargifyConfig, callbacks?: ChargifyCallbacks) => void;
  token: (
    form: HTMLFormElement,
    onSuccess: (token: string) => void,
    onFailure: (error: ChargifyError) => void,
  ) => void;
  unload: () => void;
}

export interface ChargifyProps {
  onNewChargifyToken(token: string): Promise<void>;
}

const useChargify = ({ onNewChargifyToken }: ChargifyProps) => {
  const chargifyRef = useRef<ChargifyObject | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [isCardFilled, setIsCardFilled] = useState(false);
  const [Chargify, chargifyScriptError] = useScript(
    "https://js.chargify.com/latest/chargify.js",
    () => window.Chargify,
  );

  useEffect(() => {
    if (chargifyScriptError) {
      logException("Failed to load Chargify.js script");
      return;
    }

    if (Chargify && chargifyRef.current === null) {
      chargifyRef.current = new Chargify();
      chargifyRef.current.load(CHARGIFY_CONFIG, {
        // This is the closest thing that can be used to determine if the form is dirty for the lack of a better API
        onCardTypeDetected: (type) => {
          setIsCardFilled(!!type);
        },
      });
    }
  }, [Chargify, chargifyRef, chargifyScriptError]);

  const generateToken = useCallback(() => {
    if (!chargifyRef.current || !formRef.current || isLoadingToken) {
      return;
    }

    setIsLoadingToken(true);

    chargifyRef.current.token(
      formRef.current,
      (token: string) => {
        setError(null);
        setIsLoadingToken(false);
        void onNewChargifyToken(token);
      },
      (error) => {
        setError(
          error.errors ?? "Sorry, we weren't able to process that card.",
        );
        setIsLoadingToken(false);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onNewChargifyToken]);

  return {
    error,
    formRef,
    generateToken,
    isGeneratingToken: isLoadingToken,
    isScriptLoaded: !!Chargify,
    isCardFilled,
  };
};

export default useChargify;

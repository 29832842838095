import type { AppRouter } from "@alch/cs-backend-trpc-types/server/trpc/trpcRoute";
import { httpBatchLink, httpLink, splitLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import {
  createHttpLinksOpt,
  TrpcProviderProps,
} from "@util/trpc/trpcClientUtils.ts";
import { createContext } from "react";
import { RAAS_API_ORIGIN } from "../deployEnv";

export const raasTrpc = createTRPCReact<AppRouter>({
  context: createContext(null),
});

const httpLinkOpts = createHttpLinksOpt(RAAS_API_ORIGIN);

export const raasTrpcClient = raasTrpc.createClient({
  links: [
    splitLink({
      condition(op) {
        // Conditionally enable HTTP batching
        return op.context.enableBatch === true;
      },
      false: httpLink(httpLinkOpts),
      true: httpBatchLink(httpLinkOpts),
    }),
  ],
});

export const RaasTrpc: TrpcProviderProps<AppRouter> = {
  trpc: raasTrpc,
  trpcClient: raasTrpcClient,
};

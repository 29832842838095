import { analyticsEvent } from "@util/analytics";

interface WithMessage {
  message: string;
}

export function emitDuplicatePaymentProfileErrorIfNeeded(
  err: WithMessage,
): boolean {
  if (
    err.message.includes("This card is on file for a different Alchemy account")
  ) {
    analyticsEvent("Duplicate Credit Card Entered");
    return true;
  }

  return false;
}

/**
 * The upgrade endpoint could fail for many reasons.  If the reason is specifically related
 * to their existing card that is on file, we want to direct them to the payment page.
 *
 * This is a best guess since most Chargify CC errors look like "Your card as been declined."
 */
export function isExistingCreditCardError(message: string): boolean {
  return message.includes("Your card");
}

import { Disclosure } from "@alch/ui";
import Label from "@features/signup/components/Label";
import clsx from "clsx";

interface TitleProps {
  title: string;
  showInvalidLabel?: boolean;
}

const Title = ({ title, showInvalidLabel = false }: TitleProps) => {
  return (
    <div className="grid grid-cols-2 items-center gap-x-1">
      <p className="text-heading-h4 text-grayscale-950">{title}</p>
      {showInvalidLabel && (
        <Label text="Invalid info" className="bg-red-50 text-red-700 " />
      )}
    </div>
  );
};

interface ExpandableForm {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isInvalid?: boolean;
}

const ExpandableForm = ({
  title,
  children,
  isOpen,
  setIsOpen,
  isInvalid = false,
}: ExpandableForm) => {
  return (
    <Disclosure
      title={<Title title={title} showInvalidLabel={isInvalid} />}
      open={isOpen}
      setOpen={setIsOpen}
      className={clsx(
        "border border-grayscale-200 bg-white p-4",
        isOpen ? "rounded-2xl" : "rounded-lg",
      )}
      titleContainerClassName="p-0"
      innerContainerClassName="p-0"
    >
      {children}
    </Disclosure>
  );
};

export default ExpandableForm;

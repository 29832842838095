import { ScrollArea } from "@alch/ui";
import classNames from "classnames";

const scrollShadowClassName = classNames(
  "absolute inset-x-0 z-10 h-2 first:top-0 last:bottom-0",
  "first:bg-gradient-to-b last:bg-gradient-to-t",
  "pointer-events-none",
);

interface NavScrollAreaProps {
  className?: string;
  shadowClassName: string;
  children: React.ReactNode;
}

const NavScrollArea = ({
  className,
  shadowClassName,
  children,
}: NavScrollAreaProps) => {
  return (
    <ScrollArea
      className={className}
      scrollBarClassName="my-2"
      theme="light"
      size="sm"
    >
      <div
        aria-hidden="true"
        className={classNames(scrollShadowClassName, shadowClassName)}
      />

      <div className="grid grid-cols-1 px-2.5 py-2">{children}</div>

      <div
        aria-hidden="true"
        className={classNames(scrollShadowClassName, shadowClassName)}
      />
    </ScrollArea>
  );
};

export default NavScrollArea;

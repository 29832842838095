import { Loading } from "@alch/ui";
import { ChargifyInputField } from "@features/signup/constants";

interface CreditCardFormContentProps {
  isLoading: boolean;
}

// Chargify automatically injects iframes into the DOM for each of the fields based on CHARGIFY_CONFIG field selector
const CreditCardFormContent = ({ isLoading }: CreditCardFormContentProps) => {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col pr-1 [&_iframe]:w-full [&_iframe]:max-w-full">
      <div className="mt-4 grid grid-cols-2 gap-x-5">
        <div id={ChargifyInputField.FirstName} />
        <div id={ChargifyInputField.LastName} />
      </div>
      <div id={ChargifyInputField.CardNumber} />
      <div className="grid grid-cols-5 gap-x-3">
        <div id={ChargifyInputField.ExpirationMonth} className="col-span-2" />
        <div id={ChargifyInputField.ExpirationYear} className="col-span-2" />
        <div id={ChargifyInputField.CVV} className="col-span-1" />
      </div>
    </div>
  );
};

export default CreditCardFormContent;

import { Loading } from "@alch/ui";
import ErrorBoundary from "@components/app/ErrorBoundary";
import React from "react";

const LoadingPage = () => (
  <div className="my-20 flex w-full items-center justify-center">
    <Loading />
  </div>
);

const LazyPage = (props: { children: React.ReactNode }) => {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<LoadingPage />}>
        {props.children}
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default LazyPage;

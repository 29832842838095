function getDefaultExportFromCjs(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x["default"] : x;
}
function getAugmentedNamespace(n) {
  if (n.__esModule)
    return n;
  var f = n.default;
  if (typeof f == "function") {
    var a = function a2() {
      if (this instanceof a2) {
        return Reflect.construct(f, arguments, this.constructor);
      }
      return f.apply(this, arguments);
    };
    a.prototype = f.prototype;
  } else
    a = {};
  Object.defineProperty(a, "__esModule", { value: true });
  Object.keys(n).forEach(function(k) {
    var d = Object.getOwnPropertyDescriptor(n, k);
    Object.defineProperty(a, k, d.get ? d : {
      enumerable: true,
      get: function() {
        return n[k];
      }
    });
  });
  return a;
}
const textColor = {
  primary: "var(--color-text-primary)",
  secondary: "var(--color-text-secondary)",
  tertiary: "var(--color-text-tertiary)",
  invert: "var(--color-text-invert)",
  disabled: "var(--color-text-disabled)",
  brand: "var(--color-text-brand)",
  informative: "var(--color-text-informative)",
  success: "var(--color-text-success)",
  warning: "var(--color-text-warning)",
  critical: "var(--color-text-critical)",
  accent: {
    pink: {
      DEFAULT: "var(--color-text-accent-pink)",
      contrast: "var(--color-text-accent-pink-contrast)"
    },
    blue: {
      DEFAULT: "var(--color-text-accent-blue)",
      contrast: "var(--color-text-accent-blue-contrast)"
    },
    green: {
      DEFAULT: "var(--color-text-accent-green)",
      contrast: "var(--color-text-accent-green-contrast)"
    },
    orange: {
      DEFAULT: "var(--color-text-accent-orange)",
      contrast: "var(--color-text-accent-orange-contrast)"
    },
    purple: {
      DEFAULT: "var(--color-text-accent-purple)",
      contrast: "var(--color-text-accent-purple-contrast)"
    }
  }
};
const screens = {
  "sm": "480px",
  "md": "680px",
  "lg": "880px",
  "xl": "1024px",
  "2xl": "1600px",
  "3xl": "1920px",
  "signupXs": "480px",
  "signupSm": "640px",
  "signupMd": "768px",
  "signupLg": "1024px",
  "signupXl": "1280px",
  "signup2Xl": "1536px"
};
export {
  getAugmentedNamespace as a,
  getDefaultExportFromCjs as g,
  screens as s,
  textColor as t
};

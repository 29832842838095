import { WaffleFlag } from "@alch/dx-entities";
import { useWaffleFlag } from "@queries/useWaffleFlags";
import BillingFailedBanner from "./BillingFailedBanner";
import CapacityLimitBanner from "./CapacityLimitBanner";
import TrialAnnouncementBanner from "./TrialAnnouncementBanner";
import ViewingAsBanner from "./ViewingAsBanner";

const Banners = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const usageLimitBannerEnabled = useWaffleFlag(WaffleFlag.UsageLimitBanner);

  return (
    <div {...props}>
      <ViewingAsBanner />
      <BillingFailedBanner />
      {!usageLimitBannerEnabled && <CapacityLimitBanner />}
      <TrialAnnouncementBanner />
    </div>
  );
};

export default Banners;

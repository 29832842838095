/**
 * Example: $1,234
 */
export const formatWholeDollars = (amount: number) =>
  Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);

/**
 * Example: $1.23
 */
export const formatFractionalDollars = (amount: number) =>
  Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
  }).format(amount);

/**
 * Example: 1.5B
 */

export const formatCompactNumber = (amount: number) =>
  Intl.NumberFormat(undefined, {
    notation: "compact",
  }).format(amount);

/**
 * Example: 1.5 billion
 */

export const formatCompactNumberLong = (amount: number) =>
  Intl.NumberFormat(undefined, {
    notation: "compact",
    compactDisplay: "long",
  }).format(amount);

// Display dollar amount with cents if there are cents, otherwise just display the whole dollar amount
export const formatWholeAndFractionalDollars = (amount: number) =>
  amount % 1 === 0
    ? formatWholeDollars(amount)
    : formatFractionalDollars(amount);

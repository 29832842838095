import * as Sentry from "@sentry/react";
import { createBrowserHistory, History } from "history";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import ReactModal from "react-modal";
import { applyMiddleware, createStore, Middleware, Store } from "redux";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import ErrorBoundary from "./components/app/ErrorBoundary";
import Root from "./components/app/Root";
import {
  configureAxios,
  VIEWING_AS_WRITE_ERROR_MESSAGE,
} from "./http/axiosConfig";
import { rootReducer, RootState } from "./redux/root";
import { User } from "./redux/users";
import observeStore from "./util/observeStore";
import { getViewAsId } from "./util/viewAs";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import Providers from "./components/app/Providers";
import "./index.css";
import "./index.scss";
import { DEPLOY_ENV, IS_DEVELOPMENT, IS_PRODUCTION } from "./util/deployEnv";
import { SENTRY_DSN } from "./util/settings";

const tagManagerArgs = {
  gtmId: "GTM-58BWQJ6",
};

function main(): void {
  configureAxios();
  const store = createReduxStore();
  const history = createBrowserHistory();
  configureSentry(store, history);
  scrollToTopOnNavigation(history);
  logOnNavigation(history);
  TagManager.initialize(tagManagerArgs);
  ReactModal.setAppElement("#root");
  const container = document.getElementById("root");
  if (!container) {
    throw new Error("Root element not found");
  }
  const root = createRoot(container);
  root.render(
    <Providers store={store} history={history}>
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    </Providers>,
  );
}

function createReduxStore(): Store<RootState> {
  const middlewares: Middleware[] = [thunkMiddleware];
  if (IS_DEVELOPMENT && !!localStorage.getItem("DEBUG_REDUX")) {
    middlewares.push(createLogger({ collapsed: true }) as Middleware);
  }
  return createStore(rootReducer, applyMiddleware(...middlewares));
}

function configureSentry(store: Store<RootState>, history: History): void {
  if (!IS_DEVELOPMENT) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: DEPLOY_ENV,
      ignoreErrors: [
        VIEWING_AS_WRITE_ERROR_MESSAGE,
        "AuthError", // This happens when the user is not logged in
        // Recharts is throwing this error and it is not catchable, so has to be ignored at the Sentry level.
        // https://alchemyinsights.slack.com/archives/C03JX8DJP40/p1699625279247339?thread_ts=1699563474.839529
        "ResizeObserver loop completed with undelivered notifications.",
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /chrome:\/\//i,
        /chrome-extension:\/\//i,
        // Our analytics scripts throw not useful errors if the user is blocking them
        /scripts\/anayltics\//i, // anayltics is not a typo
        /translate\.googleapis\.com\//i,
        // google ads
        /pagead2\.googlesyndication\.com/i,
      ],

      // https://docs.sentry.io/platforms/javascript/guides/react/performance/
      // tracesSampleRate, tracesPropagationTargets, and integrations to set up performance tracking
      tracesSampleRate: 0.1,
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/dashboard\.alchemy\.com/,
        /^https:\/\/dashboard\.alchemypreview\.com/,
      ],
      replaysSessionSampleRate: IS_PRODUCTION ? 0.1 : 0,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({
          history,
        }),
        Sentry.extraErrorDataIntegration(),
        Sentry.replayIntegration({
          maskAllInputs: true,
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
    });
  }

  function setSentryUser(user: User | undefined) {
    if (!user || getViewAsId() != null) {
      Sentry.getCurrentScope().clear();
    } else {
      Sentry.getCurrentScope().setUser({
        id: user.id,
        email: user.email,
      });
    }
  }

  observeStore(store, (state) => state.users.currentUser.value, setSentryUser);
}

function scrollToTopOnNavigation(history: History): void {
  history.listen((_, action) => {
    if (action === "PUSH") {
      window.scrollTo(0, 0);
    }
  });
}

function logOnNavigation(history: History): void {
  let lastPathname = history.location.pathname;
  history.listen((location) => {
    const { pathname } = location;
    if (lastPathname !== pathname) {
      lastPathname = pathname;
      logNavigationToGoogleAnalytics(pathname);
    }
  });
}

function logNavigationToGoogleAnalytics(pathname: string): void {
  interface GoogleAnalyticsWindow extends Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
    ga?(...args: any[]): void;
  }

  const gaWindow = window as GoogleAnalyticsWindow;
  if (gaWindow.ga) {
    gaWindow.ga("set", "page", pathname);
    gaWindow.ga("send", "pageview");
  }
}

main();
